import {API_CALL, SHORT_MONTHS_NAMES} from "../../types";
import {
    isError,
    isLoading, isSuccess,
    showErrorsInToast,
    translateError,
    zeroToEmptyStringAsCurrencyWithSpaces
} from "../../common";
import React, {useEffect, useState} from "react";
import IOReportTableDetails from "./IOReportTableDetails";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderMinus, faFolderPlus} from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-router-dom";
import {API} from "../../api";

interface Props {
    year: number;
    data: any;
}

const EditableTableCell = ({value, onChange}: {value: number, onChange: (value: number) => void}) => {
    const [val, setVal] = useState<string>(value.toString());
    return (
            <input
                type={"text"} value={val} className={"form-control form-control-sm text-end"}
                onChange={(e) => {
                    setVal(e.target.value);
                }}
                onBlur={(e) => {
                    let newVal = parseFloat(e.target.value);
                    if(isNaN(newVal)) {
                        newVal = 0;
                    }
                    onChange(newVal);
                }}
                onKeyDown={(e) => {
                    if(e.key === 'Enter') {
                        onChange(parseFloat(val));
                    }}
                }
            />
    );
}

const TableRow = ({data, title, editable, year, onChange}:
                      {
                          year:number
                          data: number[],
                          title: string,
                          editable?: boolean,
                          onChange?: (data: number[]) => void
                      }) => {
    const [values, setValues] = useState<number[]>(data);
    const total = data.reduce((acc: number, value: number) => acc + value, 0);
    const [loading, setLoading] = useState<boolean>(false);

    const saveChanges = (month: number, amount: number) => {
        API.setIOInvestement(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);
                if (isSuccess(apiCall)) {
                    if(onChange) {
                        onChange(values);
                    }
                }
            },
            year, month, amount
        );
    }

    useEffect(() => {
        setValues(data);
    }, [data]);

    useEffect(() => {
        for(let i = 0; i < values.length; i++) {
            if(values[i] !== data[i]) {
                saveChanges(i, values[i]);
            }
        }
    }, [values]);

    return (
        <tr>
            <td>{title}</td>
            {
                values.map((value: number, idx    ) => {
                    return (
                        <td key={`$title}-${idx}`}
                            className={"text-end"}>
                            {
                                editable ?
                                    <EditableTableCell value={value} onChange={
                                        (v) => {
                                            const newValues = [...values];
                                            newValues[idx] = v;
                                            setValues(newValues);
                                        }
                                    } />
                                    :
                                    zeroToEmptyStringAsCurrencyWithSpaces(value.toString())
                            }
                        </td>
                    )
                })
            }
            <td className={"text-end fw-bold bg-dark text-light"}>{zeroToEmptyStringAsCurrencyWithSpaces(total.toString())}</td>
        </tr>
    );
}

const IOReportInvestmentTable: React.FC<Props> = ({data, year}) => {
    const [workData, setWorkData] = useState<any>(data);

    useEffect(() => {
        setWorkData(data);
    }, [data]);

    if (!data || !workData) return null;

    let fs = "16px";
    return (
        <table className="table table-bordered table-hover text-nowrap" style={{ fontSize: fs }}>
            <thead>
            <tr>
                <th>Перо</th>
                {
                    SHORT_MONTHS_NAMES.map((month: string) => {
                        return (
                            <th key={month} className={"text-center"}>{month}</th>
                        )
                    })
                }
                <th className={"text-end bg-dark text-light"}>ОБЩО</th>
            </tr>
            </thead>
            <tbody>
                <TableRow data={workData.income} title={"Приход"} editable={true} year={year} onChange={
                    (newData) => {
                        setWorkData({...workData, income: newData});
                    }
                } />
                <TableRow data={data.outcome} title={"Разход"} year={year} />
            </tbody>
        </table>
    )
}

export default IOReportInvestmentTable;
