import React, {useEffect, useState} from "react";
import {API_CALL} from "../../types";
import {API} from "../../api";
import {
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    translateError
} from "../../common";
import {Tab, Table, Tabs} from "react-bootstrap";


interface ClientsRatingByOborotPageProps {
    periodFrom: string,
    periodTo: string,
}

export function ClientsRatingByOborotPage({periodFrom, periodTo}: ClientsRatingByOborotPageProps) {

    const [working, setWorking] = useState(false);
    const [activeTab, setActiveTab] = useState("tab1");
    const [data, setData] = useState<any>(null);
    const [report, setReport] = useState<any>(null);

    useEffect(() => {
        loadData();
    }, [periodTo, periodFrom]);

    useEffect(() => {
        updateReport();
    }, [data, activeTab]);


    const loadData = function () {
        API.getClientRatingByOborot(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    setData(apiCall.data.data.list);
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Възникна грешка!', translateError);
                }
            }, {
                periodFrom: periodFrom,
                periodTo: periodTo
            }
        )
    }

    const updateReport = function () {
        if (data) {
            const report = [...data];
            report.sort((a: any, b: any): any => {
                let _a = 0, _b = 0;
                switch (activeTab) {
                    case "tab1" :
                        _a = a.total * 1;
                        _b = b.total * 1;
                        break;
                    case "tab2" :
                        _a = a.BANKA * 1;
                        _b = b.BANKA * 1;
                        break;
                    case "tab3" :
                        _a = a.CASH * 1;
                        _b = b.CASH * 1;
                        break;
                }

                return (_a > _b ? -1 : (_a < _b ? 1 : 0));
            })

            setReport(report);
        }
    }


    return (
        <>
            <div className="row">
                <div className="col">
                    {
                        working ?
                            <div className={"m-3"}>Зареждам данните ...</div>
                            :
                            <>
                                <Tabs
                                    defaultActiveKey={activeTab}
                                    onSelect={(key) => {
                                        setActiveTab(key || "tab1");
                                    }}>
                                    <Tab title={"Класация по всичко"} eventKey={"tab1"}/>
                                    <Tab title={"Класация само по плащане с фактура"} eventKey={"tab2"}/>
                                    <Tab title={"Класация само по плащане с касов бон"} eventKey={"tab3"}/>
                                </Tabs>
                                <Table bordered size={"sm"} className={"small"}>
                                    <thead>
                                    <tr>
                                        <th className={"text-end fw-bold w-50px"}>Позиция</th>
                                        <th>Клиент</th>
                                        <th className={"text-end fw-bold w-140px"}>Фактура (лв)</th>
                                        <th className={"text-end fw-bold w-140px"}>Касов бон (лв)</th>
                                        <th className={"text-end fw-bold w-140px"}>Оборот (лв)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        report?.map(
                                            (i: any, idx: number) =>
                                                <tr key={idx} className={idx < 10 ? "bg-primary-light" : ""}>
                                                    <td className={"text-end fw-bold"}>{(idx + 1)}</td>
                                                    <td>{i.name}</td>
                                                    <td className={"text-end"}>{i.BANKA}</td>
                                                    <td className={"text-end"}>{i.CASH}</td>
                                                    <td className={"text-end"}>{i.total}</td>
                                                </tr>
                                        )
                                    }
                                    </tbody>
                                </Table>
                            </>
                    }
                </div>
            </div>
        </>
    );
}
