import {
    API_CALL,
    City,
    Client,
    Driver,
    Logistic,
    PAY_TYPE,
    PropertyValue,
    Sale,
    SALE_TYPE,
    SaleItem,
    SalePayment
} from "../../types";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import moment from "moment";
import {Col, Dropdown, DropdownButton, Form, InputGroup, Row, Table} from "react-bootstrap";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {API} from "../../api";
import {
    isError,
    isLoading,
    isSuccess,
    mapToObject,
    showErrorsInToast,
    translateError,
    zeroToEmptyStringAsCurrency
} from "../../common";
import {ReadOnlyDatePickerComponent} from "../common/ReadOnlyDatePickerComponent";
import {PlanningPage, WeekDataItem} from "../../pages/PlanningPage";
import {ConfirmModal} from "../common/ConfirmModal";
import {SelectClient} from "../clients/SelectClient";
import {SelectLogistic} from "../logistics/SelectLogistic";
import DropdownItem from "react-bootstrap/DropdownItem";
import SelectAmountType from "../../common/SelectAmountType";


interface SaleDetailsProps {
    sale: Sale,
    onAfterSave: any,
    onCancel: any,
    brick_types: PropertyValue[],
    pelet_types: PropertyValue[],
    bag_types: PropertyValue[],
    zIndex?: number;
}

interface SaleDetailsState {
    selectedClient: Client | null;
    client_filter: Map<string, any>;
    logistic_filter: Map<string, any>;
    formValues: Map<string, any>;
    showClientList: boolean;
    showLogisticList: boolean;
    working: boolean;
    hasBalance: boolean;
    planning: WeekDataItem | null;
    activePriceList: string;
}


export class NewSaleDetailsModalComponent extends React.Component<SaleDetailsProps, SaleDetailsState> {
    QtyInPalet: PropertyValue[] = [
        {id: 72, value: '72', description: ''},
        {id: 150, value: '150', description: ''},
        {id: 180, value: '180', description: ''},
        {id: 330, value: '330', description: ''},
        {id: 456, value: '456', description: ''},
    ];

    constructor(props: SaleDetailsProps) {
        super(props);

        this.state = {
            selectedClient: null,
            client_filter: new Map<string, any>(),
            logistic_filter: new Map<string, any>(),
            formValues: new Map<string, any>(),
            showClientList: false,
            showLogisticList: false,
            working: false,
            hasBalance: false,
            planning: null,
            activePriceList: this.props.sale.activePriceList || ((this.props.sale.sale_type || SALE_TYPE.bricks_w_env).indexOf('фактура') > 0 ? 'Ф' : 'КБ'),
        }

        this.state.formValues.set('priceType', this.props.sale.priceType || null);
        this.state.formValues.set('invPaletsPriceType', this.props.sale.invPaletsPriceType || null);
        this.state.formValues.set('sale_type', this.props.sale.sale_type || SALE_TYPE.bricks_w_env);
        this.state.formValues.set('sales_date', moment().format('DD.MM.Y'));
        this.state.formValues.set('returned', 0);
        this.state.formValues.set('total_pelet', 0);
        this.state.formValues.set('store_manager', 'СВИЛЕН ДАМЯНОВ');
        this.state.formValues.set('pay_type', PAY_TYPE.BANKA);
        this.state.formValues.set('loader_name', 'АЛИ АЛИЕВ');
        this.state.formValues.set('provider_name', 'СВИЛЕН ДАМЯНОВ');
        this.state.formValues.set('payments', [
            {
                date: new Date(), amount: 0,
                type: this.state.formValues.get('sale_type') == SALE_TYPE.bricks_wo_env ||
                this.state.formValues.get('sale_type') == SALE_TYPE.peleti_wo_env ? PAY_TYPE.CASH : PAY_TYPE.BANKA,
            } as SalePayment
        ]);

        if (this.props.sale?.id) {
            if (this.props.sale.client_id && this.props.sale.client_id > 0) {
                this.loadClientDetails(this.props.sale.client_id);
            }

            this.state.formValues.set('sales_number', this.props.sale.sales_number);

            this.state.client_filter.set('name', this.props.sale.client);
            this.state.client_filter.set('cities', this.props.sale.client_city);

            this.state.logistic_filter.set('name', this.props.sale.currier);
            this.state.logistic_filter.set('driver', this.props.sale.driver);

            if (props.sale?.request_data) {
                const requestValues = Object.entries(JSON.parse(props.sale.request_data.replaceAll('"null"', '""')));
                console.log(requestValues);

                for (let [key, value] of requestValues) {
                    if (key === "sale_type") {
                        value = value || Object.values(SALE_TYPE)[1];
                        this.state.formValues.set(key, Object.values(SALE_TYPE)[Object.keys(SALE_TYPE).indexOf(value as string)]);
                    } else {
                        if (value === "true") {
                            this.state.formValues.set(key, true);
                        } else if (value === "false") {
                            this.state.formValues.set(key, false);
                        } else {
                            this.state.formValues.set(key, value || '');
                        }
                    }
                }
            } else {
                for (const [key, value] of Object.entries(this.props.sale)) {
                    let v = value;
                    if (key === "sale_type") {
                        v = Object.values(SALE_TYPE)[Object.keys(SALE_TYPE).indexOf(value as string)] || SALE_TYPE.bricks_w_env;
                    }
                    this.state.formValues.set(key, v ? v : '');
                }
                let idx = 0;
                this.props.sale.items?.forEach(
                    (i: SaleItem) => {
                        i.total = i.palet_qty * i.qty_in_palet;
                        if (isNaN(i.total)) i.total = 0;
                        for (const [key, value] of Object.entries(i)) {
                            this.state.formValues.set(key + "_" + idx, value || '');
                        }
                        idx++;
                    }
                )
            }

            this.state.formValues.set('payed_by_avans', this.props.sale.payed_by_avans == 1);
            this.state.formValues.set('payed_by_credit', this.props.sale.payed_by_credit == 1);

            const payments = this.props.sale.payments || [];
            payments.forEach((p: any) => {
                let amount = parseFloat(p.amount || 0);
                if (isNaN(amount)) amount = 0;
                p.amountInput = amount.toFixed(2)
            });
            this.state.formValues.set('payments', payments);
            this.state.formValues.set('total_payments', this.props.sale.total_payments || '0.00');

            const logistic_is_avans = ((this.props.sale.inv_logistic_is_avans || 0).toString() == "1");
            const palets_is_avans = ((this.props.sale.inv_palets_is_avans || 0).toString() == "1");
            this.state.formValues.set('inv_palets_is_avans', palets_is_avans as boolean);
            this.state.formValues.set('inv_logistic_is_avans', logistic_is_avans as boolean);

            this.calculatePayments(payments);
            this.calculatePelet(this.state.formValues);
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.calculateBricks = this.calculateBricks.bind(this);
        this.getBrickPrice = this.getBrickPrice.bind(this);
        this.updateBrickPrice = this.updateBrickPrice.bind(this);
        this.refreshPrices = this.refreshPrices.bind(this);
        this.onSelectClient = this.onSelectClient.bind(this);
        this.onSelectLogistic = this.onSelectLogistic.bind(this);
        this.renderBrickTypeSelect = this.renderBrickTypeSelect.bind(this);
        this.renderPeletTypeSelect = this.renderPeletTypeSelect.bind(this);
        this.renderBagTypeSelect = this.renderBagTypeSelect.bind(this);
        this.renderQtyInPaletSelect = this.renderQtyInPaletSelect.bind(this);
        this.renderPayments = this.renderPayments.bind(this);
        this.updatePayments = this.updatePayments.bind(this);
        this.calculatePayments = this.calculatePayments.bind(this);
        this.removePayment = this.removePayment.bind(this);
        this.cancel = this.cancel.bind(this);
        this.calcOthersTotal = this.calcOthersTotal.bind(this);
        this.calcPriceTotal = this.calcPriceTotal.bind(this);
        this.checkForPlanning = this.checkForPlanning.bind(this);
        this.scheduleCalculating = this.scheduleCalculating.bind(this);
        this.fixPaymentsList = this.fixPaymentsList.bind(this);
    }

    loadClientDetails(client_id: number) {
        API.getClient(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    const fv = new Map<string, any>(this.state.formValues);
                    const client = apiCall.data.data.client;
                    fv.set('balance',
                        {
                            avans_bank: client.balance_avans_bank,
                            avans_cash: client.balance_avans_cash,
                            credit: client.balance_credit,
                            bank: client.balance_bank,
                            cash: client.balance_cash,
                            palet: client.balance_palet,
                            logistics: client.balance_logistics,
                        });
                    this.setState({formValues: fv, hasBalance: true, selectedClient: client});
                }
            }, client_id
        )
    }

    calcPriceTotal() {
        const fv = this.state.formValues;
        let total = 0;
        let otherTotal = 0;
        for (let i = 0; i < 5; i++) {
            const price = parseFloat(this.state.formValues.get("item_price_" + i));
            if (price > 0) {
                fv.set("item_price_" + i, price.toFixed(2));
                total += price;
            }
        }
        // fv.set('inv_price', total.toFixed(2));
        this.setState({formValues: fv});
    }

    calcOthersTotal(idx: number) {
        let qty = this.state.formValues.get('others_qty_' + idx) * 1;
        if (isNaN(qty)) qty = 0;

        let price = this.state.formValues.get('others_price_' + idx) * 1;
        if (isNaN(price)) price = 0;

        const total = qty * price;
        this.state.formValues.set('others_total_' + idx, total);

        return total;
    }

    getBrickPrice(id: number, idx: number) {
        if (this.state.selectedClient) {
            const fv = this.state.formValues;
            this.state.selectedClient.pricelist?.forEach(
                p => {
                    if (p.brick_id == id) {
                        if (this.state.activePriceList === 'Ф') fv.set('item_ed_price_' + idx, p.price_inv);
                        if (this.state.activePriceList === 'КБ') fv.set('item_ed_price_' + idx, p.price_cash);
                        if (this.state.activePriceList === 'АБ') fv.set('item_ed_price_' + idx, p.price_avans_banka);
                        if (this.state.activePriceList === 'АК') fv.set('item_ed_price_' + idx, p.price_avans_cash);
                    }
                }
            )
            this.calculateBricks(fv, 'property_id_' + idx);
            this.setState((prev) => {
                return {...prev, formValues: fv}
            });
        }
    }

    updateBrickPrice(idx: number) {
        const fv = this.state.formValues;
        const fn = 'property_id_' + idx;
        if (fv.has(fn)) {
            this.getBrickPrice(fv.get(fn), idx);
        }
    }

    refreshPrices() {
        const fv = this.state.formValues;

        for (let idx = 0; idx < 5; idx++) {
            if (fv.has('property_id_' + idx)) {
                const id = fv.get('property_id_' + idx);
                this.getBrickPrice(id, idx);
            }
        }

        this.setState({formValues: new Map<string, any>(fv)})
    }

    calculateBricks(fv: Map<string, any>, name: string) {
        let total_price = 0;
        for (let idx = 0; idx < 5; idx++) {
            if (
                (name.localeCompare('property_id_' + idx) === 0) ||
                (name.localeCompare('sale_type' + idx) === 0) ||
                (name.localeCompare('palet_qty_' + idx) === 0) ||
                (name.localeCompare('qty_in_palet_' + idx) === 0) ||
                (name.localeCompare('item_ed_price_' + idx) === 0) ||
                (name.localeCompare('item_to_' + idx) === 0)
            ) {
                const v1 = fv.get('palet_qty_' + idx) * 1;
                let v2 = fv.get('qty_in_palet_' + idx) * 1;
                if (v2 === -1) v2 = 0;
                let v3 = fv.get('item_ed_price_' + idx) * 1;
                let v4 = fv.get('item_to_' + idx) * 1;

                if (v1 === 0) {
                    v2 = 0;
                    fv.set('palet_qty_' + idx, '');
                    fv.set('qty_in_palet_' + idx, '');
                }

                let total = v1 * v2;
                if (isNaN(total)) total = 0;
                fv.set('total_' + idx, total);

                if (v3 > 0) {
                    let itemPrice = total * v3;
                    if (v4 > 0) {
                        itemPrice -= itemPrice * (v4 / 100);
                    }

                    fv.set('item_price_' + idx, itemPrice.toFixed(2));
                    total_price += itemPrice;
                }
            } else {
                const itemPrice = fv.get('item_price_' + idx) * 1;
                if (itemPrice > 0) {
                    total_price += itemPrice;
                }
            }
        }

        fv.set("total_price", total_price.toFixed(2));
    }

    handleFormChange(event: any) {
        console.log(event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const fv = this.state.formValues;
        fv.set(name, value);


        if (target.name.startsWith('property_id_')) {
            const rIdx = target.name.replace('property_id_', '');
            this.getBrickPrice(value, rIdx);
        }
        this.calculateBricks(fv, name);
        this.calculatePelet(fv);

        if (name === "payed_by_avans" && fv.get('payed_by_avans') == 1) fv.set('payed_by_credit', 0);
        if (name === "payed_by_credit" && fv.get('payed_by_credit') == 1) fv.set('payed_by_avans', 0);

        console.log(fv);

        this.setState({formValues: fv});
        this.scheduleCalculating();
    }

    calculatePelet(fv: Map<string, any>) {
        let total = 0;
        for (let idx = 0; idx < 5; idx++) {
            const bagQty = fv.get('bag_qty_' + idx) * 1;
            const bagTypeIdx = fv.get('bag_type_' + idx) * 1;
            const bagType = (bagTypeIdx > -1) ? this.props.bag_types[bagTypeIdx] : null;
            const peletTypeIdx = fv.get('pelet_type_' + idx) * 1;
            const peletType = (peletTypeIdx > -1) ? this.props.pelet_types[peletTypeIdx] : null;

            let total_pelet = 0;
            if (bagType && peletType && !isNaN(bagQty)) {
                total_pelet = bagQty * peletType.description[bagType.description.peletPropertyName];
            }

            if (isNaN(total_pelet)) total_pelet = 0;
            fv.set('total_pelet_' + idx, total_pelet);
            total += total_pelet;
        }

        fv.set('total_pelet', total);
    }

    checkForPlanning() {
        API.checkForPlanning(
            (apiCall: API_CALL) => {

                this.setState({working: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    if (apiCall.data.data.has) {
                        this.setState({planning: apiCall.data.data.plan});
                    } else {
                        this.props.onAfterSave(apiCall);
                    }
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Данните не бяха запазени поради следните грешки:", translateError);
                }
            }, this.props.sale.id || -1, this.state.formValues.get('sales_date')
        );
    }

    cancelPlanningUpdate() {
        this.setState({planning: null});
        this.props.onAfterSave();
    }

    processPlanningUpdate(btn: string) {
        if (btn == "Маркирай") {
            const pp = new PlanningPage({});
            if (this.state.planning) {
                pp.setStatus(this.state.planning.id, 'COMPLETED');
            }
        }
        this.cancelPlanningUpdate();
    }

    save() {
        this.calculatePayments(this.state.formValues.get('payments'));
        this.calculatePelet(this.state.formValues);
        window.setTimeout(() => {
        API.saveExpeditionNote(
            (apiCall: API_CALL) => {

                this.setState({working: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    this.checkForPlanning();
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Данните не бяха запазени поради следните грешки:", translateError);
                }
            }, {
                ...mapToObject(this.state.formValues),
                sale_type: Object.keys(SALE_TYPE)[Object.values(SALE_TYPE).indexOf(this.state.formValues.get('sale_type') as SALE_TYPE)],
                saleId: this.props.sale.id || -1,
                activePriceList: this.state.activePriceList,
                payed_by_avans: this.state.formValues.get('payed_by_avans') ? 1 : 0,
                payed_by_credit: this.state.formValues.get('payed_by_credit') ? 1 : 0,
                inv_logistic_is_avans: this.state.formValues.get('inv_logistic_is_avans') ? 1 : 0,
                inv_palets_is_avans: this.state.formValues.get('inv_palets_is_avans') ? 1 : 0,
                payments: JSON.stringify(this.state.formValues.get('payments'),
                ),
                recordVersion: "v2",
            }
        )}, 100);
    }

    delete() {
        API.saveExpeditionNote(
            (apiCall: API_CALL) => {

                this.setState({working: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    this.props.onAfterSave(apiCall);
                }

                if (isError(apiCall)) {
                    // this.setState({errors: })
                    console.log(apiCall);
                }
            }, {...mapToObject(this.state.formValues), saleId: this.props.sale.id || -1}
        );
    }

    cancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    renderSelect(key: string, values: PropertyValue[], isNumber = false) {
        return (
            <select name={key} value={this.state.formValues.get(key)} onChange={this.handleFormChange}
                    className={"form-control form-control-sm p-0 m-0"} dir={(isNumber ? 'rtl' : '')}>
                <option value={-1}></option>
                {
                    values.map(
                        (i: PropertyValue, idx: number) => <option key={idx} value={i.id}>{i.value}</option>
                    )
                }
            </select>
        )
    }

    renderBrickTypeSelect(key: string) {
        return this.renderSelect(key, this.props.brick_types);
    }

    renderBagTypeSelect(key: string) {
        return this.renderSelect(key, this.props.bag_types);
    }

    renderPeletTypeSelect(key: string) {
        return this.renderSelect(key, this.props.pelet_types);
    }

    renderQtyInPaletSelect(key: string) {
        return this.renderSelect(key, this.QtyInPalet, true);
    }

    onSelectClient(client: Client, city: City) {
        const filter = new Map<string, any>();
        filter.set('name', client.name);
        filter.set('cities', city.city);

        const fv = this.state.formValues;
        fv.set('client_id', client.id);
        fv.set('client', client.name);
        fv.set('client_city', city.city);
        fv.set('client_phone', city.phone);
        fv.set('balance',
            {
                avans_bank: client.balance_avans_bank,
                avans_cash: client.balance_avans_cash,
                bank: client.balance_bank,
                cash: client.balance_cash,
                credit: client.balance_credit,
                logistics: client.balance_logistics,
                palet: client.balance_palet
            });

        this.setState({
            formValues: fv, client_filter: filter, showClientList: !this.state.showClientList,
            selectedClient: client, hasBalance: true
        });
    }

    onSelectLogistic(logistic: Logistic, driver: Driver) {
        const filter = new Map<string, any>();
        filter.set('name', logistic.name);
        filter.set('driver', driver.name);

        const fv = this.state.formValues;
        fv.set('currier', logistic.name);
        fv.set('driver', driver.name);
        fv.set('driver_phone', driver.phone);
        fv.set('license_plate', driver.reg_nomer);

        this.setState({formValues: fv, logistic_filter: filter, showLogisticList: !this.state.showLogisticList});
    }

    calculatePayments(payments: SalePayment[]) {
        const fv = this.state.formValues;
        let pay_type: string | undefined = undefined;
        let total = 0;
        let total_banka = 0;
        let total_cash = 0;
        let total_cash_inv = 0;
        let total_credit = 0;
        let inv_price = 0;
        let inv_payed = 0;
        let totalStockPrice = 0;
        for (let i = 0; i < 5; i++) {
            const price = parseFloat(this.state.formValues.get("item_price_" + i));
            if (price > 0) {
                totalStockPrice += price;
            }
        }

        payments.forEach((p: SalePayment) => {
            p.amount = parseFloat(p.amountInput);
            if (isNaN(p.amount)) p.amount = 0;
            total += p.amount;
            if (p.type === PAY_TYPE.BANKA) {
                total_banka += p.amount;
                inv_payed += p.amount;
                inv_price += p.amount == 0 ? totalStockPrice : p.amount;
            }
            if (p.type === PAY_TYPE.CASH) total_cash += p.amount;
            if (p.type === PAY_TYPE.CASH_INV) {
                total_cash_inv += p.amount;
                inv_payed += p.amount;
                inv_price += p.amount == 0 ? totalStockPrice : p.amount;
            }
            if (p.type === PAY_TYPE.CREDIT) total_credit += p.amount;
            totalStockPrice -= p.amount;

            if(!pay_type) {
                if(p.type === PAY_TYPE.CASH_INV) {
                    pay_type = "CASH";
                } else {
                    pay_type = p.type;
                }
            }
        });
        fv.set('items_payed', total.toFixed(2));
        fv.set('pay_type', pay_type);

        let logistic_payed = parseFloat(this.state.formValues.get('inv_logistic_payed'));
        if (isNaN(logistic_payed)) logistic_payed = 0;
        let logistic_price = parseFloat(this.state.formValues.get('price'));
        if (isNaN(logistic_price)) logistic_price = 0;
        total += logistic_payed;
        if (this.state.formValues.get('priceType') === PAY_TYPE.BANKA) {
            total_banka += logistic_payed;
            inv_payed += logistic_payed;
            inv_price += logistic_price;
        }
        if (this.state.formValues.get('priceType') === PAY_TYPE.CASH) total_cash += logistic_payed;
        if (this.state.formValues.get('priceType') === PAY_TYPE.CASH_INV) {
            total_cash_inv += logistic_payed;
            inv_payed += logistic_payed;
            inv_price += logistic_price;
        }
        if (this.state.formValues.get('priceType') === PAY_TYPE.CREDIT) total_credit += logistic_payed;

        let palets_payed = parseFloat(this.state.formValues.get('inv_palets_payed'));
        if (isNaN(palets_payed)) palets_payed = 0;
        let palets_price = parseFloat(this.state.formValues.get('inv_palets_price'));
        if (isNaN(palets_price)) palets_price = 0;
        total += palets_payed;
        if (this.state.formValues.get('invPaletsPriceType') === PAY_TYPE.BANKA) {
            total_banka += palets_payed;
            inv_payed += palets_payed;
            inv_price += palets_price;
        }
        if (this.state.formValues.get('invPaletsPriceType') === PAY_TYPE.CASH) total_cash += palets_payed;
        if (this.state.formValues.get('invPaletsPriceType') === PAY_TYPE.CASH_INV) {
            total_cash_inv += palets_payed;
            inv_payed += palets_payed;
            inv_price += palets_price;
        }
        if (this.state.formValues.get('invPaletsPriceType') === PAY_TYPE.CREDIT) total_credit += palets_payed;

        fv.set('total_payments', total.toFixed(2));
        fv.set('total_banka', total_banka.toFixed(2));
        fv.set('total_cash', total_cash.toFixed(2));
        fv.set('total_cash_inv', total_cash_inv.toFixed(2));
        fv.set('total_credit', total_credit.toFixed(2));
        fv.set('inv_payed', inv_payed.toFixed(2));
        fv.set('inv_price', inv_price.toFixed(2));

        let total_price = 0;
        let stockPrice = 0;
        let logisticPrice = 0;
        let paletsPrice = 0;

        logisticPrice = parseFloat(this.state.formValues.get("price"));
        if (isNaN(logisticPrice)) logisticPrice = 0;
        total_price += logisticPrice;

        paletsPrice = parseFloat(this.state.formValues.get("inv_palets_price"));
        if (isNaN(paletsPrice)) paletsPrice = 0;
        total_price += paletsPrice;

        let price = 0;
        for (let i = 0; i < 5; i++) {
            if (this.state.formValues.get('sale_type') === SALE_TYPE.others) {
                if (!this.state.formValues.get("others_exclude_" + i)) {
                    price = parseFloat(this.state.formValues.get("others_total_" + i));
                } else {
                    price = 0;
                }
            } else {
                price = parseFloat(this.state.formValues.get("item_price_" + i));
            }
            if (isNaN(price)) price = 0;
            if (price > 0) {
                stockPrice += price;
            }
        }
        total_price += stockPrice;

        fv.set('stockPrice', stockPrice.toFixed(2));
        fv.set('total_price', total_price.toFixed(2));
        fv.set('inv_balance', (total - total_price).toFixed(2));

        this.setState({formValues: new Map<string, any>(fv)});
    }

    updatePayments(idx: number, newItem: SalePayment) {
        const fv = this.state.formValues;
        const payments = fv.get('payments');
        payments[idx] = newItem;
        fv.set('payments', [...payments]);

        this.setState({formValues: new Map<string, any>(fv)});
        this.calculatePayments(payments);
        this.fixPaymentsList();
    }

    removePayment(idx: number) {
        const fv = this.state.formValues;
        const payments = fv.get('payments');
        payments.splice(idx, 1);
        fv.set('payments', payments);

        this.setState({formValues: new Map<string, any>(fv)});
        this.calculatePayments(payments);
    }

    scheduleCalculating() {
        window.setTimeout(() => {
            this.calculatePayments(this.state.formValues.get('payments'));
        }, 0);
    }

    fixPaymentsList() {
        const payments = [...this.state.formValues.get('payments')];
        let expNotePrice = parseFloat(this.state.formValues.get('stockPrice'));
        if (isNaN(expNotePrice)) expNotePrice = 0;

        if (expNotePrice === 0) {
            return <div className={"text-center"}>Няма стоки</div>
        }

        let totalPayments = parseFloat(this.state.formValues.get('items_payed'));
        if (isNaN(totalPayments)) totalPayments = 0;

        if (expNotePrice > totalPayments && (payments.length === 0 || payments[payments.length - 1].amount > 0)) {
            payments.push({
                date: new Date(),
                amount: 0,
                type: this.state.formValues.get('sale_type') == SALE_TYPE.bricks_wo_env ||
                this.state.formValues.get('sale_type') == SALE_TYPE.peleti_wo_env ? PAY_TYPE.CASH : PAY_TYPE.BANKA,
                amountInput: "",
            });
            this.updatePayments(payments.length - 1, payments[payments.length - 1]);
            return;
        }

        if (totalPayments === expNotePrice && payments.length > 0 && payments[payments.length - 1].amount === 0) {
            payments.splice(payments.length - 1, 1);
            const fv = this.state.formValues;
            fv.set('payments', [...payments]);
            this.setState({formValues: new Map<string, any>(fv)});
            return;
        }
    }

    renderPayments() {
        this.fixPaymentsList();
        const payments = [...this.state.formValues.get('payments')];

        let expNotePrice = parseFloat(this.state.formValues.get('stockPrice'));
        if (isNaN(expNotePrice)) expNotePrice = 0;

        if (expNotePrice === 0) {
            this.state.formValues.set('payments', []);
            return <div className={"text-center"}>Няма стоки</div>
        }

        return <table className={"table table-sm table-bordered table-no-padding w-100"}>
            <thead className={"text-center"}>
            <tr>
                <th>Тип:</th>
                <th>Цена:</th>
                <th>Платено:</th>
                <th>Аванс?</th>
                <th className={"w-100px"}>На дата</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                payments.map(
                    (i: SalePayment, idx: number) => {
                        const row = <tr key={"payment_" + idx}>
                                <td>
                                    <SelectAmountType value={i.type}
                                                      fullVersion={true}
                                                      onChange={
                                                          value => {
                                                              this.updatePayments(idx, {
                                                                  ...i,
                                                                  type: value as string
                                                              });
                                                          }
                                                      }/>
                                </td>
                                <td className={"w-100px"}>
                                    <input type="text" className={"form-control w-100 text-end"}
                                           value={expNotePrice.toFixed(2)}
                                           disabled={true}
                                    />
                                </td>
                                <td className={"w-100px"}>
                                    <input type="text" className={"form-control w-100 text-end"}
                                           value={i.amountInput}
                                           onChange={(e) => {
                                               let amount = parseFloat(e.target.value);
                                               if (isNaN(amount)) amount = 0;
                                               i.amountInput = amount.toFixed(2);
                                               this.updatePayments(idx, {
                                                   ...i,
                                                   amount: amount, amountInput: e.target.value
                                               })
                                           }}
                                           onBlur={(e) => {
                                               this.fixPaymentsList();
                                           }}
                                    />
                                </td>
                                <td className={"w-50px"} align={"center"}>
                                    {
                                        i.type !== PAY_TYPE.CREDIT &&
                                        <Form.Check checked={i.isAvans} className={"pt-2"}
                                                    onChange={(e) => this.updatePayments(idx, {
                                                        ...i,
                                                        isAvans: e.target.checked
                                                    })}
                                        />
                                    }
                                </td>
                                <td className={"form-control w-120px"}>
                                    <ReadOnlyDatePickerComponent
                                        value={moment(i.date).format('DD.MM.yyyy')}
                                        onChange={(date: Date) => this.updatePayments(idx, {...i, date: date})}
                                        hideDayControls={true}
                                        hideIcon={true}
                                    />
                                </td>
                                <td className={"w-35px"}>
                                    <button type={"button"} className={"btn btn-secondary"}
                                            onClick={() => this.removePayment(idx)}> -
                                    </button>
                                </td>
                            </tr>
                        ;
                        expNotePrice -= i.amount;
                        return row;
                    }
                )
            }
            </tbody>
        </table>
    }

    renderPaletsDataRow() {
        return <table className={"table table-sm table-bordered table-no-padding w-100"}>
            <thead className={"text-center"}>
            <tr>
                <th>Тип:</th>
                <th>Брой:</th>
                <th>Цена:</th>
                <th>Платено:</th>
                <th>Аванс?</th>
                <th className={" w-120px"}>На дата:</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <SelectAmountType value={this.state.formValues.get('invPaletsPriceType')}
                                      fullVersion={true}
                                      onChange={value => {
                                          this.setState({formValues: this.state.formValues.set('invPaletsPriceType', value as string)});
                                          this.scheduleCalculating();
                                      }}
                    />
                </td>
                <td className="w-75px">
                    <input type="text" className={"form-control w-100 text-end"}
                           name={"inv_palets_qty"}
                           value={this.state.formValues.get("inv_palets_qty")}
                           onChange={this.handleFormChange}
                    />
                </td>
                <td className="w-100px">
                    <input type="text" className={"form-control w-100 text-end"}
                           name={"inv_palets_price"}
                           value={this.state.formValues.get("inv_palets_price")}
                           onChange={this.handleFormChange}
                    />
                </td>
                <td className="w-100px">
                    <input type="text" className={"form-control w-100 text-end"}
                           name={"inv_palets_payed"}
                           value={this.state.formValues.get("inv_palets_payed")}
                           onChange={this.handleFormChange}
                    />
                </td>
                <td className={"w-50px"} align={"center"}>
                    {
                        this.state.formValues.get('invPaletsPriceType') !== PAY_TYPE.CREDIT &&
                        <Form.Check checked={this.state.formValues.get('inv_palets_is_avans') as boolean}
                                    className={"mt-2"}
                                    onChange={(e) => {
                                        this.setState({formValues: this.state.formValues.set('inv_palets_is_avans', e.target.checked as boolean)});
                                    }}
                        />
                    }
                </td>
                <td className={"form-control flex-nowrap w-100px"}>
                    <ReadOnlyDatePickerComponent
                        value={this.state.formValues.get('inv_palets_payed_date')}
                        onChange={(date: Date) => {
                            this.setState({formValues: this.state.formValues.set('inv_palets_payed_date', moment(date).format('DD.MM.YYYY'))});
                        }}
                        hideDayControls={true}
                        hideIcon={true}
                    />
                </td>
            </tr>
            </tbody>
        </table>
    }

    renderTransportDataRow() {
        return <table className={"table table-sm table-bordered table-no-padding w-100"}>
            <thead className={"text-center"}>
            <tr>
                <th>Тип:</th>
                <th>Цена:</th>
                <th>Платено:</th>
                <th>Аванс?</th>
                <th className={"w-120px"}>На дата:</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <SelectAmountType value={this.state.formValues.get('priceType')}
                                      fullVersion={true}
                                      onChange={value => {
                                          this.setState({formValues: this.state.formValues.set('priceType', value as string)});
                                          this.scheduleCalculating();
                                      }}
                    />
                </td>
                <td className="w-100px">
                    <input type="text" className={"form-control w-100 text-end"}
                           name={"price"}
                           value={this.state.formValues.get("price")}
                           onChange={this.handleFormChange}
                    />
                </td>
                <td className="w-100px">
                    <input type="text" className={"form-control w-100 text-end"}
                           name={"inv_logistic_payed"}
                           value={this.state.formValues.get("inv_logistic_payed")}
                           onChange={this.handleFormChange}
                    />
                </td>
                <td className={"w-50px"} align={"center"}>
                    {
                        this.state.formValues.get('priceType') !== PAY_TYPE.CREDIT &&
                        <Form.Check checked={this.state.formValues.get('inv_logistic_is_avans') as boolean}
                                    className={"mt-2"}
                                    onChange={(e) => {
                                        this.setState({formValues: this.state.formValues.set('inv_logistic_is_avans', e.target.checked as boolean)});
                                    }}
                        />
                    }
                </td>
                <td className={"form-control flex-nowrap w-120px"}>
                    <ReadOnlyDatePickerComponent
                        value={this.state.formValues.get('inv_logistic_payed_date')}
                        onChange={(date: Date) => {
                            this.setState({formValues: this.state.formValues.set('inv_logistic_payed_date', moment(date).format('DD.MM.YYYY'))});
                        }}
                        hideDayControls={true}
                        hideIcon={true}
                    />
                </td>
            </tr>
            </tbody>
        </table>
    }

    addPayment() {
        const fv = new Map<string, any>(this.state.formValues);
        const currentPayments = fv.get('payments');
        currentPayments.push({
            date: new Date(), amount: 0,
            type: this.state.formValues.get('sale_type') == SALE_TYPE.bricks_wo_env ||
            this.state.formValues.get('sale_type') == SALE_TYPE.peleti_wo_env ? PAY_TYPE.CASH : PAY_TYPE.BANKA,
        } as SalePayment);
        fv.set('payments', currentPayments);
        this.setState({formValues: fv});
    }


    render() {
        return (
            <>
                <div className={"modal overflow-auto"}
                     style={{display: "block", zIndex: this.props.zIndex || 10016}}>
                    <div className="modal-dialog modal-80">
                        <div className="modal-content bg-light shadow">
                            <div className="modal-header">
                                <h5 className="modal-title">Експедиционна
                                    бележка {this.state.formValues.get('sales_number')} (v2)</h5>
                                <span className={"pointer"} onClick={this.cancel}><FontAwesomeIcon
                                    icon="window-close"/></span>
                            </div>
                            <div className="modal-body">

                                <form>
                                    <div className={"row"}>

                                        <div className="mb-3 pr-0 col-2">
                                            <form className="form-inline">
                                                <div className={"lh-2em "}>
                                                    <div className={"col-auto"}>Дата:&nbsp;</div>
                                                    <div className={"col-auto"}>
                                                        <ReadOnlyDatePickerComponent
                                                            classes={"ml-0 pl-0"}
                                                            value={this.state.formValues.get('sales_date')}
                                                            onChange={(date: Date) => {
                                                                this.setState({formValues: this.state.formValues.set('sales_date', moment(date).format('DD.MM.YYYY'))});
                                                            }}
                                                            hideDayControls={true}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div className="mb-3 pr-0 col-2">
                                            <label className="form-label">Вид</label>
                                            <Form.Control as="select"
                                                          value={this.state.formValues.get('sale_type')}
                                                          onChange={(v) => {
                                                              this.setState({formValues: this.state.formValues.set('sale_type', v.target.value)})
                                                          }}
                                            >
                                                <option
                                                    value={SALE_TYPE.bricks_w_env}>{SALE_TYPE.bricks_w_env}</option>
                                                <option
                                                    value={SALE_TYPE.bricks_wo_env}>{SALE_TYPE.bricks_wo_env}</option>
                                                <option
                                                    value={SALE_TYPE.peleti_w_env}>{SALE_TYPE.peleti_w_env}</option>
                                                <option
                                                    value={SALE_TYPE.peleti_wo_env}>{SALE_TYPE.peleti_wo_env}</option>
                                                <option value={SALE_TYPE.others}>{SALE_TYPE.others}</option>
                                            </Form.Control>
                                        </div>

                                        <div className="mb-3 pr-0 col-3">
                                            <label className="form-label">Клиент</label>
                                            <InputGroup>
                                                <input type="text" className="form-control" name={"client"}
                                                       onChange={this.handleFormChange}
                                                       value={this.state.formValues.get("client")}/>

                                                <Dropdown show={this.state.showClientList}>
                                                    <DropdownToggle onClick={() => {
                                                        this.setState({showClientList: !this.state.showClientList})
                                                    }}>
                                                    </DropdownToggle>
                                                </Dropdown>

                                                {
                                                    this.state.showClientList &&
                                                    <SelectClient
                                                        filter={this.state.client_filter}
                                                        onSelect={this.onSelectClient}
                                                        onClose={() => this.setState({showClientList: !this.state.showClientList})}
                                                    />
                                                }
                                            </InputGroup>
                                        </div>

                                        <div className="mb-3 pr-0 col-3">
                                            <label className="form-label">Град</label>
                                            <input type="text" className="form-control" name={"client_city"}
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("client_city")}/>
                                        </div>

                                        <div className="col-2 mb-2">
                                            <label className="form-label">Телефон</label>
                                            <input type="text" className="form-control" name={"client_phone"}
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("client_phone")}/>
                                        </div>

                                    </div>

                                    {
                                        (this.state.formValues.get('sale_type') === SALE_TYPE.bricks_w_env ||
                                            this.state.formValues.get('sale_type') === SALE_TYPE.bricks_wo_env) &&

                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <table className={"table table-hover table-sm table-no-padding"}>
                                                    <thead className={"bg-secondary text-light"}>
                                                    <tr>
                                                        <th className={"w-50px text-end"}>No:</th>
                                                        <th>Наименование на стоката</th>
                                                        <th className={"w-120px text-end"}>Палети</th>
                                                        <th className={"w-120px text-end"}>Бр. в палет</th>
                                                        <th className={"w-120px text-end"}>К-во(бр.)</th>
                                                        <th className={"w-120px text-center"}>
                                                            <DropdownButton size={"sm"}
                                                                            title={"Ед.ц. (" + this.state.activePriceList + ") "}
                                                                            onSelect={eventKey => {
                                                                                this.setState({activePriceList: eventKey || 'Ф'});
                                                                                this.refreshPrices();
                                                                            }}
                                                            >
                                                                <DropdownItem eventKey={"Ф"}>Цена с
                                                                    фактура</DropdownItem>
                                                                <DropdownItem eventKey={"КБ"}>Цена с касов
                                                                    бон</DropdownItem>
                                                                <DropdownItem eventKey={"АБ"}>Цена аванс
                                                                    банка</DropdownItem>
                                                                <DropdownItem eventKey={"АК"}>Цена аванс
                                                                    кеш</DropdownItem>
                                                            </DropdownButton>

                                                        </th>
                                                        <th className={"w-120px text-end"}>ТО %</th>
                                                        <th className={"w-120px text-end"}>Цена</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        [0, 1, 2, 3, 4].map(
                                                            idx =>
                                                                <tr key={idx}>
                                                                    <td className={"text-end"}>{idx + 1}&nbsp;</td>
                                                                    <td>
                                                                        {this.renderBrickTypeSelect('property_id_' + idx)}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            // (this.state.formValues.get('property_id_' + idx) !== '999') &&
                                                                            <input type="number"
                                                                                   className="form-control form-control-sm text-end"
                                                                                   name={"palet_qty_" + idx}
                                                                                   value={this.state.formValues.get("palet_qty_" + idx)}
                                                                                   onChange={this.handleFormChange}/>
                                                                        }
                                                                    </td>
                                                                    <td className={"text-end"}>
                                                                        {
                                                                            ((this.state.formValues.get('property_id_' + idx) !== '999') ||
                                                                                (this.state.formValues.get("palet_qty_" + idx) * 1 > 0)) &&
                                                                            this.renderQtyInPaletSelect('qty_in_palet_' + idx)
                                                                        }
                                                                    </td>
                                                                    <td className={"text-end"}>
                                                                        {
                                                                            (this.state.formValues.get('property_id_' + idx) !== '999') ||
                                                                            (this.state.formValues.get("palet_qty_" + idx) * 1 > 0) ?
                                                                                <span className={"pr-3"}
                                                                                      style={{lineHeight: "32px"}}>{this.state.formValues.get("total_" + idx)}</span>
                                                                                :
                                                                                <input type="number"
                                                                                       className="form-control form-control-sm text-end p-0 m-0"
                                                                                       name={"total_" + idx}
                                                                                       value={this.state.formValues.get("total_" + idx)}
                                                                                       onChange={this.handleFormChange}/>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <input type="number"
                                                                               className="form-control form-control-sm text-end"
                                                                               name={"item_ed_price_" + idx}
                                                                               value={this.state.formValues.get("item_ed_price_" + idx)}
                                                                               onBlur={() => this.calcPriceTotal()}
                                                                               onChange={this.handleFormChange}
                                                                               onDoubleClick={(e) => this.updateBrickPrice(idx)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input type="number"
                                                                               className="form-control form-control-sm text-end"
                                                                               name={"item_to_" + idx}
                                                                               value={this.state.formValues.get("item_to_" + idx)}
                                                                               onBlur={() => this.calcPriceTotal()}
                                                                               onChange={this.handleFormChange}/>
                                                                    </td>
                                                                    <td>
                                                                        <input type="number"
                                                                               className="form-control form-control-sm text-end"
                                                                               name={"item_price_" + idx}
                                                                               value={this.state.formValues.get("item_price_" + idx)}
                                                                               onBlur={() => this.calcPriceTotal()}
                                                                               onChange={this.handleFormChange}/>
                                                                    </td>
                                                                </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }

                                    {
                                        (this.state.formValues.get('sale_type') === SALE_TYPE.peleti_w_env ||
                                            this.state.formValues.get('sale_type') === SALE_TYPE.peleti_wo_env) &&

                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <table className={"table table-hover table-sm table-no-padding"}>
                                                    <thead className={"bg-secondary text-light"}>
                                                    <tr>
                                                        <th className={"w-100px text-end"}>No:</th>
                                                        <th>Пелети</th>
                                                        <th>М/Е</th>
                                                        <th className={"w-180px text-end"}>Брой</th>
                                                        <th className={"w-180px text-end"}>Килограми</th>
                                                        <th className={"w-180px text-end"}>Цена</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        [0, 1, 2, 3, 4].map(
                                                            idx =>
                                                                <tr key={idx}>
                                                                    <td className={"text-end"}>{idx + 1}&nbsp;</td>
                                                                    <td>
                                                                        {this.renderPeletTypeSelect('pelet_type_' + idx)}
                                                                    </td>
                                                                    <td className={"text-end"}>
                                                                        {this.renderBagTypeSelect('bag_type_' + idx)}
                                                                    </td>
                                                                    <td>
                                                                        <input type="number"
                                                                               className="form-control form-control-sm text-end"
                                                                               name={"bag_qty_" + idx}
                                                                               value={this.state.formValues.get("bag_qty_" + idx)}
                                                                               onChange={this.handleFormChange}/>
                                                                    </td>
                                                                    <td className={"text-end"}>
                                                                        {zeroToEmptyStringAsCurrency(this.state.formValues.get("total_pelet_" + idx))}&nbsp;</td>
                                                                    <td>
                                                                        <input type="number"
                                                                               className="form-control form-control-sm text-end"
                                                                               name={"item_price_" + idx}
                                                                               value={this.state.formValues.get("item_price_" + idx)}
                                                                               onBlur={() => {
                                                                                   this.setState(
                                                                                       {
                                                                                           formValues: this.state.formValues.set("item_price_" + idx,
                                                                                               parseFloat(this.state.formValues.get("item_price_" + idx)).toFixed(2))
                                                                                       })
                                                                               }
                                                                               }
                                                                               onChange={this.handleFormChange}/>
                                                                    </td>
                                                                </tr>
                                                        )
                                                    }


                                                    {/*<tr className={"bg-secondary text-light "}>*/}
                                                    {/*    <td></td>*/}
                                                    {/*    <td colSpan={3}>Всичко пелети (кг)</td>*/}
                                                    {/*    <td className={"text-end"}>{this.state.formValues.get('total_pelet')}</td>*/}
                                                    {/*</tr>*/}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.formValues.get('sale_type') === SALE_TYPE.others &&
                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <table className={"table table-hover table-sm table-no-padding"}>
                                                    <thead className={"bg-secondary text-light"}>
                                                    <tr>
                                                        <th className={"w-100px text-end"}>No:</th>
                                                        <th>Артикул</th>
                                                        <th className={"w-120px"}>М/Е</th>
                                                        <th className={"w-120px text-end"}>Количество</th>
                                                        <th className={"w-120px text-end"}>Ед.Цена</th>
                                                        <th className={"w-120px text-end"}>Общо</th>
                                                        <th className={"w-50px"}
                                                            title={"Изключи от сумата за стоки"}>Изкл.?
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        [0, 1, 2, 3, 4].map(
                                                            idx =>
                                                                <tr key={idx}>
                                                                    <td className={"text-end"}>{idx + 1}&nbsp;</td>
                                                                    <td>
                                                                        <input
                                                                            className="form-control form-control-sm"
                                                                            name={"others_" + idx}
                                                                            value={this.state.formValues.get("others_" + idx)}
                                                                            onChange={this.handleFormChange}/>
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            className="form-control form-control-sm"
                                                                            name={"others_me_" + idx}
                                                                            value={this.state.formValues.get("others_me_" + idx)}
                                                                            onChange={this.handleFormChange}/>
                                                                    </td>
                                                                    <td>
                                                                        <input type="number"
                                                                               className="form-control form-control-sm text-end"
                                                                               name={"others_qty_" + idx}
                                                                               value={this.state.formValues.get("others_qty_" + idx)}
                                                                               onChange={this.handleFormChange}/>
                                                                    </td>
                                                                    <td className={"text-end"}>
                                                                        <input type="number"
                                                                               className="form-control form-control-sm text-end"
                                                                               name={"others_price_" + idx}
                                                                               value={this.state.formValues.get("others_price_" + idx)}
                                                                               onChange={this.handleFormChange}/>
                                                                    </td>
                                                                    <td className={"text-end"}>{this.calcOthersTotal(idx)}</td>
                                                                    <td className={"text-center"}>
                                                                        <Form.Check
                                                                            title={"Изключи от сумата за стоки"}
                                                                            name={"others_exclude_" + idx}
                                                                            checked={this.state.formValues.get("others_exclude_" + idx)}
                                                                            onChange={this.handleFormChange}/>
                                                                    </td>
                                                                </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }

                                    <div className={"row"}>
                                        <div className={"col"}>
                                            <table className={"table table-hover table-sm table-no-padding"}>
                                                <tbody>
                                                <tr className={"bg-secondary text-light"}>
                                                    <td className={"w-100px text-end"}></td>
                                                    <td colSpan={3}>Върнати палети</td>
                                                    <td className={"w-180px text-end"}>
                                                        <input type="number"
                                                               className="form-control form-control-sm text-end"
                                                               name={"returned"}
                                                               value={this.state.formValues.get("returned")}
                                                               onChange={this.handleFormChange}/>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>


                                    <div className={"row"}>
                                        <div className="mb-3 pr-0 col-3">
                                            <label className="form-label">Фирма превозвач:</label>
                                            <InputGroup>
                                                <input type="text" className="form-control" name={"currier"}
                                                       onChange={this.handleFormChange}
                                                       value={this.state.formValues.get("currier")}/>

                                                <Dropdown show={this.state.showLogisticList}>
                                                    <DropdownToggle onClick={() => {
                                                        this.setState({showLogisticList: !this.state.showLogisticList})
                                                    }}>
                                                    </DropdownToggle>
                                                </Dropdown>

                                                {
                                                    this.state.showLogisticList &&
                                                    <SelectLogistic
                                                        filter={this.state.logistic_filter}
                                                        onSelect={this.onSelectLogistic}
                                                        onClose={() => this.setState({showLogisticList: !this.state.showLogisticList})}
                                                    />
                                                }
                                            </InputGroup>
                                        </div>

                                        <div className="mb-3 col-2">
                                            <label className="form-label">Получена от:</label>
                                            <input type="text" className="form-control" name={"driver"}
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("driver")}/>
                                        </div>

                                        <div className="mb-3 col-2">
                                            <label className="form-label">Телефон</label>
                                            <input type="text" className="form-control" name={"driver_phone"}
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("driver_phone")}/>
                                        </div>

                                        <div className="mb-3 col-2">
                                            <label className="form-label">Кола номер</label>
                                            <input type="text" className="form-control" name={"license_plate"}
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("license_plate")}/>
                                        </div>

                                        <div className="mb-3 col">
                                            <label className="form-label">Началник склад</label>
                                            <input type="text" className="form-control" name={"store_manager"}
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("store_manager")}/>
                                        </div>

                                    </div>

                                    <Row className={"mb-3"}>
                                        <Col>
                                            <BlockTitleComponent title={"ПРИЕМО-ПРЕДАВАТЕЛЕН ПРОТОКОЛ"}/>
                                            <div className={"row"}>
                                                <div className="col-6">
                                                    <label className="form-label">Натоварил стоката:</label>
                                                    <input type="text" className="form-control" name={"loader_name"}
                                                           onChange={this.handleFormChange}
                                                           value={this.state.formValues.get("loader_name")}/>
                                                </div>

                                                <div className="col-6">
                                                    <label className="form-label">Предал стоката:</label>
                                                    <input type="text" className="form-control"
                                                           name={"provider_name"}
                                                           onChange={this.handleFormChange}
                                                           value={this.state.formValues.get("provider_name")}/>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <BlockTitleComponent title={"ФАКТУРИРАНЕ"}/>
                                            <div className={"row"}>
                                                <div className="col-3">
                                                    <label className="form-label">Дължимо:</label>
                                                    <input type="text" className={"form-control w-100 text-end"}
                                                           name={"inv_price"}
                                                           value={this.state.formValues.get("inv_price")}
                                                           onChange={(e) => {
                                                               this.handleFormChange(e);
                                                           }}
                                                    />
                                                </div>

                                                <div className="col-6">
                                                    <label className="form-label">Фактура No / дата:</label>
                                                    <input type="text" className={"form-control w-100"}
                                                           name={"inv_number"}
                                                           value={this.state.formValues.get("inv_number")}
                                                           onChange={this.handleFormChange}
                                                    />
                                                </div>

                                                <div className="col-3">
                                                    <label className="form-label">Платено:</label>
                                                    <input type="text" className={"form-control w-100 text-end"}
                                                           name={"inv_payed"}
                                                           value={this.state.formValues.get("inv_payed")}
                                                           onChange={(e) => {
                                                               this.handleFormChange(e);
                                                           }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12} md={12} lg={12} xl={6}>
                                            <BlockTitleComponent title={"ТРАНСПОРТ"}/>
                                            <div className={"row"}>
                                                <div className="col">
                                                    {this.renderTransportDataRow()}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={12} lg={12} xl={6}>
                                            <BlockTitleComponent title={"ПАЛЕТИ"}/>
                                            <div className={"row"}>
                                                <div className="col">
                                                    {this.renderPaletsDataRow()}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col xs={12} md={12} lg={12} xl={6}>
                                            <BlockTitleComponent title={"СТОКИ"}/>
                                            <div className={"row"}>
                                                <div className="col">
                                                    {this.renderPayments()}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md={12} lg={12} xl={6}>
                                            <BlockTitleComponent title={"ОБОБЩЕНИЕ"}/>
                                            <Table bordered>
                                                <thead>
                                                <tr className={"text-end"}>
                                                    <th></th>
                                                    <th>БАНКА</th>
                                                    <th>КЕШ</th>
                                                    <th>КЕШ Ф-РА</th>
                                                    <th>КРЕДИТ</th>
                                                    <th>ВСИЧКО</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>ДЪЛЖИМО:</td>
                                                    <td className={"text-end"} colSpan={5}>
                                                        <strong
                                                            className="text-danger bg-light">&nbsp;{this.state.formValues.get('total_price') || '0.00'}лв&nbsp;</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>ПЛАТЕНО:</td>
                                                    <td className={"text-end"}>
                                                        <strong
                                                            className="text-success bg-light">&nbsp;{this.state.formValues.get('total_banka') || '0.00'}лв&nbsp;</strong>
                                                    </td>
                                                    <td className={"text-end"}>
                                                        <strong
                                                            className="text-success bg-light">&nbsp;{this.state.formValues.get('total_cash') || '0.00'}лв&nbsp;</strong>
                                                    </td>
                                                    <td className={"text-end"}>
                                                        <strong
                                                            className="text-success bg-light">&nbsp;{this.state.formValues.get('total_cash_inv') || '0.00'}лв&nbsp;</strong>
                                                    </td>
                                                    <td className={"text-end"}>
                                                        <strong
                                                            className="text-success bg-light">&nbsp;{this.state.formValues.get('total_credit') || '0.00'}лв&nbsp;</strong>
                                                    </td>
                                                    <td className={"text-end"}>
                                                        <strong
                                                            className="text-success bg-light">&nbsp;{this.state.formValues.get('total_payments') || '0.00'}лв&nbsp;</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>РАЗЛИКА:</td>
                                                    <td className={"text-end"} colSpan={5}>
                                                        <strong
                                                            className="text-primary bg-light">&nbsp;{this.state.formValues.get('inv_balance') || '0.00'}лв&nbsp;</strong>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>


                                    <div className={"row"}>
                                        <div className="col-12">
                                            <input type="text" className={"form-control w-100"}
                                                   name={"notes"} placeholder={"Забележки"}
                                                   value={this.state.formValues.get("notes")}
                                                   onChange={this.handleFormChange}
                                            />
                                        </div>
                                    </div>

                                </form>

                            </div>
                            <div className="modal-footer">
                                {
                                    this.state.working ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <>
                                            <div className="lh-2em pt-3 mr-3 flex-fill align-content-end">
                                                {
                                                    this.state.hasBalance &&
                                                    <table className={"table table-sm table-bordered"}>
                                                        <tr className={"text-white text-end"}>
                                                            <td className={this.state.formValues.get('balance')?.bank < 0 ? "bg-danger" : "bg-success"}
                                                                title={"Баланс банка"}>
                                                                <span style={{fontSize: "smaller"}}>БАНКА:</span>
                                                                <strong>{this.state.formValues.get('balance')?.bank || '0.00'}</strong>&nbsp;
                                                            </td>
                                                            <td className={this.state.formValues.get('balance')?.cash < 0 ? "bg-danger" : "bg-success"}
                                                                title={"Баланс кеш"}>
                                                                <span style={{fontSize: "smaller"}}>КЕШ:</span>
                                                                <strong>{this.state.formValues.get('balance')?.cash || '0.00'}</strong>&nbsp;
                                                            </td>
                                                            <td className={this.state.formValues.get('balance')?.avans_bank < 0 ? "bg-danger" : "bg-success"}
                                                                title={"Баланс аванс банка"}>
                                                                <span style={{fontSize: "smaller"}}>АВ.БАНКА:</span>
                                                                <strong>{this.state.formValues.get('balance')?.avans_bank || '0.00'}</strong>&nbsp;
                                                            </td>
                                                            <td className={this.state.formValues.get('balance')?.avans_cash < 0 ? "bg-danger" : "bg-success"}
                                                                title={"Баланс аванс кеш"}>
                                                                <span style={{fontSize: "smaller"}}>АВ.КЕШ:</span>
                                                                <strong>{this.state.formValues.get('balance')?.avans_cash || '0.00'}</strong>&nbsp;
                                                            </td>
                                                            <td className={this.state.formValues.get('balance')?.credit < 0 ? "bg-danger" : "bg-success"}
                                                                title={"Баланс кредит"}>
                                                                    <span
                                                                        style={{fontSize: "smaller"}}>Ав.КРЕДИТ:</span>
                                                                <strong>{this.state.formValues.get('balance')?.credit || '0.00'}</strong>&nbsp;
                                                            </td>
                                                            <td className={this.state.formValues.get('balance')?.palet < 0 ? "bg-danger" : "bg-success"}
                                                                title={"Баланс палети"}>
                                                                <span style={{fontSize: "smaller"}}>ПАЛЕТИ:</span>
                                                                <strong>{this.state.formValues.get('balance')?.palet || 0}</strong>&nbsp;
                                                            </td>
                                                            <td className={this.state.formValues.get('balance')?.logistics < 0 ? "bg-danger" : "bg-success"}
                                                                title={"Баланс транспорт"}>
                                                                    <span
                                                                        style={{fontSize: "smaller"}}>ТРАНСПОРТ:</span>
                                                                <strong>{this.state.formValues.get('balance')?.logistics || '0.00'}</strong>&nbsp;
                                                            </td>
                                                        </tr>
                                                    </table>
                                                }
                                            </div>

                                            <button type="button" className="btn btn-primary"
                                                    onClick={this.save.bind(this)}>Запази
                                            </button>
                                            <button type="button" className="btn btn-secondary"
                                                    onClick={this.cancel.bind(this)}>Откажи
                                            </button>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.planning &&
                    <ConfirmModal
                        title={"Внимание"}
                        message={"Този клиент присъства в експедиционния план. Искате ли да маркирам плана за този клиент като изпълнен?"}
                        buttons={["Маркирай", "Не"]}
                        primaryBtn={"Маркирай"}
                        onClose={() => this.cancelPlanningUpdate()}
                        onButtonClick={(btn) => this.processPlanningUpdate(btn)}/>
                }
            </>
        )
    }
}
