import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../api";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {BlockTitleComponent} from "../components/common/BlockTitleComponent";
import {ReadOnlyDatePickerComponent} from "../components/common/ReadOnlyDatePickerComponent";
import moment from "moment";
import {BricksSalesReportPage} from "../components/financial/BricksSalesReportPage";
import {ClientsBalanceReportPage} from "../components/financial/ClientsBalanceReportPage";
import {IncomeOutgoingReportPage} from "../components/financial/IncomeOutgoingReportPage";
import {ClientsPaletsBalanceReportPage} from "../components/financial/ClientsPaletsBalanceReportPage";
import {ClientsNotPaidReportPage} from "../components/financial/ClientsNotPaidReportPage";
import {City, Client} from "../types";
import {Button, Dropdown, InputGroup} from "react-bootstrap";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {SelectClient} from "../components/clients/SelectClient";
import {ClientsRatingByOborotPage} from "../components/financial/ClientsRatingByOborotPage";
import {ProfitReportPage} from "../components/financial/ProfitReportPage";
import {FuelBalancePage} from "./FuelBalancePage";
import {PeletsSalesReportPage} from "../components/financial/PeletsSalesReportPage";
import {BankExpensesPage} from "./BankExpensesPage";


export interface ReportsPageProps {
    hideCard?: boolean,
    report?: string,
    periodFrom: string,
    periodTo: string,
    client?: Client,
}

interface ReportsPageState {
    loading: number,
    formValues: Map<string, any>,
    showClientList: boolean,
    client: Client | null,
}


export class FinancialReportsPage extends React.Component<any, ReportsPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: 0,
            formValues: new Map<string, any>(),
            showClientList: false,
            client: null,
        }

        this.loadData = this.loadData.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.periodToday = this.periodToday.bind(this);
        this.selectDate = this.selectDate.bind(this);
        this.onSelectClient = this.onSelectClient.bind(this);

        this.state.formValues.set('report', 'Продажби по вид тухли за период');
        this.periodToday();
    }

    async componentDidMount() {
        this.periodToday();
        // @ts-ignore
        // let params = queryString.parse(this.props.location.search?.substr(1));
        // console.log(params, this.props);
    }

    periodToday() {
        this.selectDate('periodFrom', new Date());
        this.selectDate('periodTo', new Date());
        this.loadData();
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const fv = this.state.formValues;
        fv.set(name, value);
        this.setState({formValues: fv});
    }

    selectDate(dateFieldName: string, newDate: Date) {
        this.setState({formValues: this.state.formValues.set(dateFieldName, moment(newDate).format('DD.MM.YYYY'))});
    }

    loadData() {
        const fv = this.state.formValues;

        fv.set('activeReport', fv.get('report'));
        fv.set('activePeriodFrom', fv.get('periodFrom'));
        fv.set('activePeriodTo', fv.get('periodTo'));
        fv.set('activeClient', this.state.client);


        this.setState({formValues: new Map<string, any>(fv)});
    }

    onSelectClient(client: Client | null, city: City | null) {
        this.setState({
            showClientList: false, client: client
        });
    }

    render() {

        const content = <>
            <div className="row">
                <div className="col">
                    {
                        this.state.loading > 0 &&
                        <div className={"m-3"}>Зареждам данните ...</div>
                    }
                </div>
            </div>

            <BlockTitleComponent title={"Параметри на справката"}/>
            <div className={"row mb-5"}>
                <div className={"col-auto ml-2 mr-2 pr-1"} style={{paddingTop: 7}}>
                    <label className={"justify-content-start"}>Изберете отчет:</label>
                    <select className="form-control w-100" name="report" required
                            value={this.state.formValues?.get('report')}
                            onChange={this.handleFormChange}>
                        <option value="Продажби по вид тухли за период">Продажби по вид тухли за период</option>
                        <option value="Продажби по вид пелети за период">Продажби по вид пелети за период</option>
                        <option value="Класиране на клиентите по продажби за период">Класиране на клиентите по продажби
                            за период
                        </option>
                        {/*<option value="Клиенти с неплатени фактури към дата">Клиенти с неплатени фактури КЕШ към дата*/}
                        {/*</option>*/}
                        <option value="Длъжници с касов бон и банка към дата">Длъжници с касов бон и банка към дата
                        </option>
                        <option value="Клиенти с аванс към дата">Клиенти с аванс към дата</option>
                        <option value="Баланс палети по клиенти към дата">Баланс палети по клиенти към дата</option>
                        {/*<option value="Приход за период">Приход за период</option>*/}
                        <option value="Приход и разход за транспорт">Приход и разход за транспорт</option>
                        {
                            // [50, 53, 55, 64].some(id => id === API.user?.id) &&
                            // <option value="Печалба по месеци">Печалба по месеци</option>
                        }
                        <option value={"Разходи по банка и каса"}>Разходи по банка и каса</option>
                    </select>
                </div>

                {
                    this.state.formValues?.get('report') !== "Печалба по месеци" &&
                    this.state.formValues?.get('report') !== "Разходи по банка и каса" &&
                    this.state.formValues?.get('report') !== "Приход и разход за транспорт" &&
                    <>
                        {
                            this.state.formValues?.get('report') !== "Длъжници с касов бон и банка към дата" &&
                            this.state.formValues?.get('report') !== "Клиенти с аванс към дата" &&
                            this.state.formValues?.get('report') !== "Баланс палети по клиенти към дата" &&
                            this.state.formValues?.get('report') !== "Клиенти с неплатени фактури към дата" &&

                            <div className={"col-auto mr-2 pl-0"}>
                                <form className="form-inline">
                                    <div>
                                        <div className={"col-12 mb-2"}>От дата:</div>
                                        <div className={"col-auto"}>
                                            <ReadOnlyDatePickerComponent
                                                onChange={(newDate: Date) => this.selectDate('periodFrom', newDate)}
                                                value={this.state.formValues.get('periodFrom')}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }

                        <div className={"col-auto ml-2 mr-2"}>
                            <form className="form-inline">
                                <div>
                                    <div className={"col-12 mb-2"}>До дата:&nbsp;</div>
                                    <div className={"col-auto"}>
                                        <ReadOnlyDatePickerComponent
                                            onChange={(newDate: Date) => this.selectDate('periodTo', newDate)}
                                            value={this.state.formValues.get('periodTo')}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>

                        {
                            this.state.formValues?.get('report') === "Продажби по вид пелети за период" &&
                            <div className={"col-auto ml-2 mr-2"}>
                                <form className="form-inline">
                                    <div>
                                        <label className="form-label">Kлиент:</label>
                                        <InputGroup>
                                            <Dropdown show={this.state.showClientList}>
                                                <div className={"border p-1 pb-0"}>
                                                    {this.state.client?.name || "Изберете клиент..."}
                                                </div>
                                                <DropdownToggle onClick={() => {
                                                    this.setState({showClientList: !this.state.showClientList})
                                                }}>
                                                </DropdownToggle>
                                                <Button onClick={() => this.onSelectClient(null, null)}>X</Button>
                                            </Dropdown>

                                            {
                                                this.state.showClientList &&
                                                <SelectClient
                                                    onSelect={this.onSelectClient}
                                                    onClose={() => this.setState({showClientList: !this.state.showClientList})}
                                                    filter={new Map<string, any>()}
                                                />
                                            }
                                        </InputGroup>
                                    </div>
                                </form>
                            </div>
                        }
                    </>
                }

                <div className={"col-auto"}>
                    {
                        this.state.loading ?
                            <>
                                <label className={"col-form-label w-100"}>&nbsp; </label>
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </>
                            :
                            <>
                                <label className={"col-form-label w-100 pb-0"}>&nbsp; </label>
                                <button className="btn btn-primary" onClick={this.loadData}>Калкулирай</button>
                            </>
                    }
                </div>
            </div>

            <ReportViewer
                client={this.state.formValues.get('activeClient')}
                report={this.state.formValues.get('activeReport')}
                periodFrom={this.state.formValues.get('activePeriodFrom')}
                periodTo={this.state.formValues.get('activePeriodTo')}/>
        </>

        if (this.props.hideCard) return content
        else
            return (
                <div className="container" style={{maxWidth: '100%'}}>

                    <div className="card shadow">

                        <div className="card-header text-center row">
                            <div className="col-2 text-start">
                                {
                                    (this.state.loading > 0) ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <Link to={PATHS.home + API.getAPITokenAsQuery()}><span
                                            className="btn btn-secondary"><FontAwesomeIcon
                                            icon={faArrowLeft}/></span></Link>
                                }

                            </div>
                            <h2 className="col">Финансови справки</h2>
                            <div className="col-2">
                            </div>
                        </div>

                        <div className="card-body">
                            {content}
                        </div>

                        <div className="card-footer">{<></>}</div>
                    </div>

                </div>
            );
    }

}

const ReportViewer = function (props: ReportsPageProps) {
    switch (props.report) {
        case 'Продажби по вид тухли за период' :
            return <BricksSalesReportPage
                client={props.client || null}
                periodFrom={props.periodFrom}
                periodTo={props.periodTo}/>
        case 'Продажби по вид пелети за период' :
            return <PeletsSalesReportPage
                client={props.client || null}
                periodFrom={props.periodFrom}
                periodTo={props.periodTo}/>
        case 'Клиенти с неплатени фактури към дата' :
            return <ClientsNotPaidReportPage periodTo={props.periodTo}/>
        case 'Класиране на клиентите по продажби за период' :
            return <ClientsRatingByOborotPage periodFrom={props.periodFrom} periodTo={props.periodTo}/>
        case 'Длъжници с касов бон и банка към дата' :
            return <ClientsBalanceReportPage showAvance={false} periodTo={props.periodTo}/>
        case 'Клиенти с аванс към дата' :
            return <ClientsBalanceReportPage showAvance={true} periodTo={props.periodTo}/>
        case 'Баланс палети по клиенти към дата' :
            return <ClientsPaletsBalanceReportPage
                periodTo={props.periodTo}/>
        case 'Приход за период' :
            return <IncomeOutgoingReportPage
                periodFrom={props.periodFrom}
                periodTo={props.periodTo}/>
        case 'Печалба по месеци' :
            return <ProfitReportPage />
        case 'Приход и разход за транспорт' :
            return <FuelBalancePage />
        case 'Разходи по банка и каса' :
            return <BankExpensesPage skipCard />


    }

    return <></>
}
