import {Accordion, Button, Col, Dropdown, DropdownButton, FormControl, Row} from "react-bootstrap";
import {zeroToEmptyString} from "../../common";
import React, {useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export type ExpenceType = {
    id: number;
    parent_id: number;
    name: string;
}

export interface DailyFullReportExpRowData {
    recordType: "exp" | "pelets" | "ico";
    description?: string;
    qty?: number;
    price?: number;
    hasVat?: boolean;
}

interface DailyFullReportExpRowProps {
    exp_types: Array<ExpenceType>,
    record: DailyFullReportExpRowData;
    onchange: (record: DailyFullReportExpRowData) => void;
    onRemove: () => void;
}

const DailyFullReportExpRow = ({
                                   exp_types,
                                   record,
                                   onchange,
                                   onRemove,
                               }: DailyFullReportExpRowProps) => {

    const [qty, setQty] = React.useState<string | undefined>();
    const [price, setPrice] = React.useState<string | undefined>();

    useEffect(() => {
        setQty(zeroToEmptyString((record.qty ?? 0).toString()));
        setPrice((1 * (record.price ?? 0)).toFixed(2));
    }, [record]);

    return <tr>
        <td className={"no-padding w-480px"}>
            <Row>
                <Col>
                    <input
                        className={"form-control form-control-sm description w-100"}
                        type={"text"}
                        readOnly={!record.description?.startsWith("ДРУГИ ПЛАЩАНИЯ")}
                        value={record.description}
                        onChange={event => {
                            onchange({
                                ...record,
                                description: event.target.value,
                            });
                        }}
                    />
                </Col>
                <Col sm={"auto"} className={"ps-0 ms-0 small"}>
                    <DropdownButton
                        onSelect={event => {
                            onchange({
                                ...record,
                                description: event ?? undefined,
                            });
                        }}
                        size={"sm"} title={""}
                    >
                        <Accordion style={{fontSize: "0.75rem", minWidth: "350px"}}>
                            {
                                exp_types.filter(
                                    e => e.parent_id === 0
                                ).sort(
                                    (a, b) =>
                                        a.name > b.name ? 1 : (
                                            a.name < b.name ? -1 : 0
                                        )
                                ).map(
                                    e => <Accordion.Item eventKey={e.name}>
                                        <Accordion.Header
                                            style={{fontSize: "0.75rem"}}>{e.name}</Accordion.Header>
                                        <Accordion.Body>
                                            <div style={{
                                                maxHeight: "150px",
                                                overflow: "auto",
                                            }}>
                                                {
                                                    exp_types.filter(
                                                        e1 => e1.parent_id === e.id
                                                    ).sort(
                                                        (a, b) =>
                                                            a.name > b.name ? 1 : (
                                                                a.name < b.name ? -1 : 0
                                                            )
                                                    ).map(
                                                        e2 =>
                                                            <Dropdown.Item
                                                                eventKey={e2.name}
                                                                key={e2.name}>{e2.name}</Dropdown.Item>
                                                    )
                                                }
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            }
                        </Accordion>
                        <div className={"mt-2 mb-2 text-center"}>
                            <Button title={"Изчисти полето"} onClick={() => {
                                onchange({
                                    ...record,
                                    description: undefined,
                                    qty: undefined,
                                    price: undefined,
                                });
                            }} variant={"secondary"}>Изчисти полето</Button>
                        </div>
                    </DropdownButton>
                </Col>
            </Row>
        </td>
        <td>
            <input
                className={"text-end form-control form-control-sm"} type={"text"}
                value={qty}
                onChange={
                    event => {
                        const value = event.target.value;
                        setQty(value);
                    }
                }
                onBlur={() => {
                    const newQty = Number(qty?.replace(",", "."));
                    if (isNaN(newQty)) {
                        setQty("0");
                    }
                    onchange({
                        ...record,
                        qty: newQty,
                    });
                }}
            />
        </td>
        <td>
            <input
                className={"text-end form-control form-control-sm"} type={"text"}
                value={price}
                onChange={
                    event => {
                        const value = event.target.value;
                        setPrice(value);
                    }
                }
                onBlur={() => {
                    const newPrice = Number(price?.replace(",", "."));
                    if (isNaN(newPrice)) {
                        setPrice("0");
                    }
                    onchange({
                        ...record,
                        price: newPrice,
                    });
                }}
            />
        </td>
        <td align={"center"}>
            <input type={"checkbox"}
                   checked={record.hasVat}
                   onChange={event => {
                       onchange({
                           ...record,
                           hasVat: event.target.checked,
                       });
                   }}
            />
        </td>
        <td className={"text-end"}>
            <Button
                className={"btn-sm text-dark"} variant={"danger"} title={"Премахни запис"}
                onClick={onRemove}
            >
                <FontAwesomeIcon icon="trash-alt"/>
            </Button>
        </td>
    </tr>;
}

export default DailyFullReportExpRow;
