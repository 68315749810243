import {Table} from "react-bootstrap";
import {FC} from "react";
import {zeroToEmptyStringAsCurrencyWithCommas, zeroToEmptyStringAsCurrencyWithSpaces} from "../../common";

interface BudgetsRefTableProps {
    budgets: any;
    expenses: any;
    expensesTypes: any;
    year: number;
    months: number[];
}

const BudgetsRefTable: FC<BudgetsRefTableProps> = ({budgets, expenses, expensesTypes, year, months}) => {
    function getExpense(id: number) {
        let result = 0;
        for(const month of months) {
            const monthAsIdx = (month + 1).toString().padStart(2, "0");
            result += 1 * (expenses[id] ? (expenses[id][year] ? (expenses[id][year][monthAsIdx] ?? 0) : 0) : 0);
        }
        return result;
    }

    function getBudget(id: number) {
        let result = 0;
        for(const month of months) {
            result += 1 * (budgets[id] ? (budgets[id][month] ?? 0) : 0);
        }
        return result;
    }

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>No:</th>
                <th className={"w-50"}>Група разходи</th>
                <th className={"text-end"}>Планирано</th>
                <th className={"text-end"}>Реално</th>
                <th className={"text-end"}>Разлика</th>
            </tr>
            </thead>
            <tbody>
            {
                expensesTypes.map((e: any, index: number) => {
                    const expense = getExpense(e.id);
                    const budget = getBudget(e.id);
                    const diff = budget - expense;
                    const diffClass = diff < 0 ? "text-danger" : "text-success";

                    return (
                        <tr key={index}>
                            <td className={"text-end"}>{(index + 1)}&nbsp;</td>
                            <td className={"text-nowrap fa-sm"}>{e.name}</td>
                            <td className={"text-end"}>{zeroToEmptyStringAsCurrencyWithSpaces(budget.toString())}</td>
                            <td className={"text-end"}>{zeroToEmptyStringAsCurrencyWithSpaces(expense.toString())}</td>
                            <td className={"text-end " + diffClass}>{zeroToEmptyStringAsCurrencyWithSpaces(diff.toString())}</td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}

export default BudgetsRefTable;
