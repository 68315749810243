import React, {ChangeEvent} from "react";
import {ExtruderMushtukCorrection, ExtruderProduction, ExtruderQuantity, ExtruderReport, PropertyValue} from "../../types";
import moment, {Moment} from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";
import {FACTORY} from "../../factories";
import {cleanup} from "@testing-library/react";
import {TimePickerComponent} from "../common/TimePickerComponent";
import {Col, Modal, Row} from "react-bootstrap";
import {showErrorsListInToast} from "../../common";

export interface ExtruderQuantityProps {
    items: ExtruderProduction[],
    brick_types: PropertyValue[],
    extr_waste_reasons: PropertyValue[],
    onChange: any,
}

export interface ExtruderQuantityState {
    items: ExtruderProduction[],
    formValues: Map<string, any>,
    showTimePicker: boolean,
    editorVisible: boolean,
    editIndex: number,
}

export class ExtruderQuantityComponent extends React.Component<ExtruderQuantityProps, ExtruderQuantityState> {
    constructor(props: ExtruderQuantityProps) {
        super(props);
        this.state = {
            items: props.items || [],
            formValues: new Map<string, any>(),
            showTimePicker: false,
            editorVisible: false,
            editIndex: -1,
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.saveItem = this.saveItem.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.renderFormRow = this.renderFormRow.bind(this);
        this.renderDataRow = this.renderDataRow.bind(this);
        this.editItem = this.editItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )
    }

    notifyParent(items: ExtruderProduction[]) {
        if(this.props.onChange) {
            this.props.onChange('production', items);
        }
    }

    saveItem(event: any) {
        const newRec: ExtruderProduction = {
            time: this.state.formValues.get('time'),
            brick: this.state.formValues.get('brick'),
            vacuum: this.state.formValues.get('vacuum'),
            pressure_from: this.state.formValues.get('pressure_from'),
            pressure_to: this.state.formValues.get('pressure_to'),
            wagonsQty: this.state.formValues.get('wagonsQty'),
            waste: this.state.formValues.get('waste'),
            layers_waste: this.state.formValues.get('layers_waste'),
            reason1: this.state.formValues.get('reason1'),
            reason2: this.state.formValues.get('reason2'),
            reason3: this.state.formValues.get('reason3'),
            reason1_percent: this.state.formValues.get('reason1_percent'),
            reason2_percent: this.state.formValues.get('reason2_percent'),
            reason3_percent: this.state.formValues.get('reason3_percent'),
        }

        let errors: string[] = [];
        if(!newRec.time) errors.push('Попълнете полето ЧАС');
        if(!newRec.brick) errors.push('Попълнете полето ВИД ТУХЛА');
        if(!newRec.wagonsQty || newRec.wagonsQty < 0) errors.push('Попълнете правилно полето БРОЙ ВАГОНЕТКИ')
        if(newRec.waste && (newRec.waste < 1 || newRec.waste > 999)) errors.push('Попълнете правилно полето БРАК')

        if(errors.length > 0) {
            showErrorsListInToast('Открити грешки', errors);
            return;
        }

        const newFormValues = this.state.formValues;
        newFormValues.set('time', '');
        newFormValues.set('brick', -1);
        newFormValues.set('reason1', -1);
        newFormValues.set('reason2', -1);
        newFormValues.set('reason3', -1);
        newFormValues.set('wagonsQty', '');
        newFormValues.set('waste', '');
        newFormValues.set('vacuum', '');
        newFormValues.set('pressure_from', '');
        newFormValues.set('pressure_to', '');
        newFormValues.set('reason1_percent', '');
        newFormValues.set('reason2_percent', '');
        newFormValues.set('reason3_percent', '');

        let newItems: ExtruderProduction[] = [];
        if(this.state.editIndex === -1) newItems = [...this.state.items, newRec];
        else {
            newItems = [...this.state.items];
            newItems[this.state.editIndex] = newRec;
        }

        this.setState({editorVisible: false, formValues: newFormValues, items: newItems});
        this.notifyParent(newItems);
    }

    renderNoDataRow = function () {
        return (
            <tr>
                <td colSpan={8}>Няма въведени данни</td>
            </tr>
        )
    }

    renderDataRow(idx: number) {
        const item: ExtruderProduction = this.state.items[idx];

        return item ?
            (
                <tr key={idx}>
                    <td className={"w-120px"}>{item.time}</td>
                    <td>{item.brick ? this.props.brick_types[Number(item.brick)].description.shortName : ""}</td>
                    <td className={"w-100px text-end"}>{item.vacuum}</td>
                    <td className={"w-100px text-center"}>{item.pressure_from} - {item.pressure_to}</td>
                    <td className={"w-100px text-end"}>{item.wagonsQty}</td>
                    <td className={"w-100px text-end"}>{item.layers_waste}</td>
                    <td className={"w-100px text-end"}>{item.waste}</td>
                    <td>

                        <table className={"table table-sm table-no-padding"}>
                            <tbody>
                            <tr>
                                <td>{this.props.extr_waste_reasons[item.reason1]?.value}</td>
                            </tr>
                            <tr>
                                <td>{this.props.extr_waste_reasons[item.reason2]?.value}</td>
                            </tr>
                            <tr>
                                <td>{this.props.extr_waste_reasons[item.reason3]?.value}</td>
                            </tr>
                            </tbody>
                        </table>

                    </td>
                    <td className={"w-120px"}>
                        <table className={"table table-no-padding table-sm"}>
                            <tr>
                                <td className={"text-end"}>{item.reason1_percent}</td>
                            </tr>
                            <tr>
                                <td className={"text-end"}>{item.reason2_percent}</td>
                            </tr>
                            <tr>
                                <td className={"text-end"}>{item.reason3_percent}</td>
                            </tr>
                        </table>
                    </td>
                    <td className={"text-nowrap text-end"}>
                        <span className="btn btn-primary btn-light" onClick={() => this.editItem(idx)}><FontAwesomeIcon icon="pen"/></span> &nbsp;
                        <span className="btn btn-primary btn-light" onClick={() => this.removeItem(idx)}><FontAwesomeIcon icon="trash-alt"/></span>
                    </td>
                </tr>
            ) : null;
    }

    renderFormRow() {
        return (
            <tr>
                <td className={"w-100px"}>
                    <input className="form-control" type="text" readOnly={true} name="time" required
                           value={this.state.formValues.get('time')}
                           onClick={() => this.setState({showTimePicker: !this.state.showTimePicker})}
                    />
                    {
                        this.state.showTimePicker && <TimePickerComponent
                            onSelect={(time: string) => this.setState({showTimePicker: false, formValues: this.state.formValues.set('time', time)})}
                            value={this.state.formValues?.get('time')}/>
                    }
                </td>
                <td className={"w-180px"}>
                    <select className="form-control" name="brick"
                            value={this.state.formValues.get('brick')} onChange={this.handleFormChange} required>
                        <option value={-1}></option>
                        {this.props.brick_types.map((i: PropertyValue, idx: number) => <option value={idx} key={idx}>{i.description.shortName}</option>)}
                    </select>
                </td>
                <td className={"w-100px"}><input className="form-control" type="number" name="vacuum" required
                                                 value={this.state.formValues.get('vacuum')}
                                                 onChange={this.handleFormChange}/>
                </td>

                <td className={"text-nowrap text-center"}>
                    <Row className={"flex-nowrap"}>
                        <Col>
                            <input className="form-control w-100px float-left" type="number" name="pressure_from" required
                                   value={this.state.formValues.get('pressure_from')}
                                   onChange={this.handleFormChange}/>
                        </Col>
                        <Col xs={"auto"}>
                            <span className={"float-left font-weight-bold pt-1"}> - </span>
                        </Col>
                        <Col>
                            <input className="form-control w-100px float-left d-inline-block" type="number" name="pressure_to" required
                                   value={this.state.formValues.get('pressure_to')}
                                   onChange={this.handleFormChange}/>
                        </Col>
                    </Row>
                </td>

                <td className={"w-100px"}>
                    <input className="form-control" type="number" name="wagonsQty" required
                           value={this.state.formValues.get('wagonsQty')}
                           onChange={this.handleFormChange}/>
                </td>
                <td className={"w-100px"}>
                    <input className="form-control" type="number" name="layers_waste" required min={1} max={999}
                           value={this.state.formValues.get('layers_waste')}
                           onChange={this.handleFormChange}/>
                </td>
                <td className={"w-100px"}>
                    <input className="form-control" type="number" name="waste" required min={1} max={999}
                           disabled={!(
                               (this.state.formValues.get('reason1') > -1) ||
                               (this.state.formValues.get('reason2') > -1) ||
                               (this.state.formValues.get('reason3') > -1))}
                           value={this.state.formValues.get('waste')}
                           onChange={this.handleFormChange}/>
                </td>
                <td>
                    <table className={"table table-borderless table-sm table-no-padding"}>
                        <tbody>
                        <tr>
                            <td>
                                <select className="form-control" name="reason1"
                                        value={this.state.formValues.get('reason1')} onChange={this.handleFormChange} required>
                                    <option value={-1}></option>
                                    {this.props.extr_waste_reasons.map((i: PropertyValue, idx: number) => <option value={idx} key={idx}>{i.value}</option>)}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <select className="form-control" name="reason2"
                                        value={this.state.formValues.get('reason2')} onChange={this.handleFormChange} required>
                                    <option value={-1}></option>
                                    {this.props.extr_waste_reasons.map((i: PropertyValue, idx: number) => <option value={idx} key={idx}>{i.value}</option>)}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <select className="form-control" name="reason3"
                                        value={this.state.formValues.get('reason3')} onChange={this.handleFormChange} required>
                                    <option value={-1}></option>
                                    {this.props.extr_waste_reasons.map((i: PropertyValue, idx: number) => <option value={idx} key={idx}>{i.value}</option>)}
                                </select>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </td>
                <td className={"w-100px"}>
                    <table className={"table table-borderless table-sm table-no-padding"}>
                        <tr>
                            <td>
                                <input className="form-control" type="number" name="reason1_percent" required
                                       value={this.state.formValues.get('reason1_percent')}
                                       onChange={this.handleFormChange}/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input className="form-control" type="number" name="reason2_percent" required
                                       value={this.state.formValues.get('reason2_percent')}
                                       onChange={this.handleFormChange}/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input className="form-control" type="number" name="reason3_percent" required
                                       value={this.state.formValues.get('reason3_percent')}
                                       onChange={this.handleFormChange}/>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        )
    }

    removeItem(idx: number) {
        const newItems = [...this.state.items];
        delete newItems[idx];
        this.setState({ items: newItems });
        this.notifyParent(newItems);
    }

    renderData() {
        const rows = [];
        const cnt = this.state.items.length;
        for (let idx = 0; idx < cnt; idx++) {
            rows.push(this.renderDataRow(idx))
        }
        return rows;
    }

    addNewItem() {
        this.setState({editorVisible: true, formValues: new Map<string, any>(), editIndex: -1});
    }

    editItem(idx: number) {
        const formValues: Map<string, any> = new Map<string, any>(Object.entries(this.state.items[idx]));

        this.setState({editorVisible: true, formValues: formValues, editIndex: idx});
    }

    render() {
        return (
            <>
                <table className="table table-hover table-sm">
                    <thead>
                    <tr>
                        <th>Час</th>
                        <th>Вид тухла</th>
                        <th className={"text-end"}>Вакуум</th>
                        <th className={"text-center"}>Налягане<br/>от-до</th>
                        <th className={"text-end"}>Брой<br/>вагонетки</th>
                        <th className={"text-end"}>Брак<br/>слоеве</th>
                        <th className={"text-end"}>Брак<br/>тухли</th>
                        <th>Причина</th>
                        <th className={"text-end"}>%</th>
                        <th style={{width: "7rem"}}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.items.length === 0 && this.renderNoDataRow()
                    }
                    {
                        this.renderData()
                    }
                    <td colSpan={10}>
                        <button className={"btn btn-outline-primary w-100 font-weight-bold"} onClick={this.addNewItem}>
                            Добави нов запис
                        </button>
                    </td>
                    </tbody>
                </table>


                <Modal
                    className={"modal-90 m-3"} centered
                    size={"xl"}
                    show={this.state.editorVisible}
                    onHide={() => this.setState({editorVisible: false})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Добавяне/корекция</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <table className="table table-hover table-sm">
                            <thead>
                            <tr>
                                <th>Час</th>
                                <th>Вид тухла</th>
                                <th className={"text-end"}>Вакуум</th>
                                <th className={"text-center w-240px"}>Налягане<br/>от-до</th>
                                <th className={"text-end"}>Брой<br/>вагонетки</th>
                                <th className={"text-end"}>Брак<br/>слоеве</th>
                                <th className={"text-end"}>Брак<br/>тухли</th>
                                <th>Причина</th>
                                <th className={"text-end"}>%</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.renderFormRow()
                            }
                            </tbody>
                        </table>

                    </Modal.Body>

                    <Modal.Footer>
                        <button type={"button"} className={"btn btn-secondary"} onClick={this.saveItem}>Запази</button>
                        <button type={"button"} className={"btn btn-primary"} onClick={() => this.setState({editorVisible: false})}>Откажи</button>
                    </Modal.Footer>
                </Modal>

            </>
        );
    }
}
