import HighchartsReact from "highcharts-react-official";
import {FC, useRef} from "react";
import Highcharts from "highcharts";
import PieChart from "highcharts-react-official";

interface BudgetsRefGraph1Props {
    values: Map<string, number>;
}

const BudgetsRefGraph1: FC<BudgetsRefGraph1Props> = (props) => {
    const total = Array.from(props.values.values()).reduce((a, b) => a + b, 0);
    const totalStr = total.toFixed(2);

    const options: Highcharts.Options = {
        title: {
            text: 'Разпределние на разходите'
        },
        subtitle: {
            useHTML: true,
            text: "<div><div>Всичко:</div><div><strong>" + totalStr + " лв.</strong></div></div>",
            floating: true,
            verticalAlign: 'middle',
            align: 'center',
            y: 10
        },
        chart: {
            type: 'pie',
            plotShadow: true,
            margin: 30
        },
        series: [
            {
                size: '60%',
                innerSize: '70%',
                type: 'pie',
                name: "",
                data: Array.from(props.values),
            }
        ],
        tooltip: {
            pointFormat: '<b>{point.y:.2f} лв.</b>',
        }
    };

    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    return (
        <div>
            <PieChart
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
                {...props}
            />
        </div>
    )
}

export default BudgetsRefGraph1
