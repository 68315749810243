import HighchartsReact from "highcharts-react-official";
import {FC, useRef} from "react";
import Highcharts from "highcharts";

interface BudgetsRefGraph1Props {
    types: any[];
    expenses: any[];
    budgets: any[];
    month: number;
    year: number;
}

const BudgetsRefGraph1: FC<BudgetsRefGraph1Props> = (props) => {
    const titles: string[] = [];
    const expenses: number[] = [];
    const budgets: number[] = [];

    const monthAsString = (props.month+1).toString().padStart(2, '0');
    props.types.forEach((type, idx) => {
        const typeId = type.id;
        const budget = props.budgets[typeId];
        const expense = props.expenses[typeId];
        if(budget && expense && expense[props.year]) {
            let budgetValue = budget[props.month] * 1;
            let expenseValue = expense[props.year][monthAsString] * 1;

            if(isNaN(budgetValue)) budgetValue = 0;
            if(isNaN(expenseValue)) expenseValue = 0;

            if(budgetValue || expenseValue) {
                titles.push(type.name.toLowerCase());
                expenses.push(expenseValue);
                budgets.push(budgetValue);
            }
        }
    });

    const options: Highcharts.Options = {
        title: {
            text: 'Бюджет/Реалност'
        },
        chart: {
            type: 'column',
        },
        xAxis: {
            categories: titles,
            crosshair: true,
            accessibility: {
                description: 'Разходи'
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Стойност в лв.'
            }
        },
        series: [
            {
                type: 'column',
                name: 'Бюджет',
                data: budgets,
            },
            {
                type: 'column',
                name: 'Реалност',
                data: expenses,
            }
        ],
        tooltip: {
            pointFormat: '<b>{point.y:.2f} лв.</b>',
        }
    };

    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
                {...props}
            />
        </div>
    )
}

export default BudgetsRefGraph1
