import {API} from "../../api";
import React, {FC, useEffect, useState} from "react";
import {API_CALL, SHORT_MONTHS_NAMES} from "../../types";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../common";
import {BlockTitleComponent} from "../../components/common/BlockTitleComponent";
import {Col, Row, Spinner, Tab, Tabs, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import moment from "moment";
import IOReportTable from "./IOReportTable";
import IOReportInvestmentTable from "./IOReportInvestmentTable";
import IORefChart from "./IORefChart";

const IOReference = () => {
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const [selectedMonths, setSelectedMonths] = useState<string[]>([SHORT_MONTHS_NAMES[new Date().getMonth()]]);
    const [loading, setLoading] = useState<boolean>(false);
    const [reportData, setReportData] = useState<any>({});

    let yearsList = [];
    for (let i = 0; i < 4; i++) {
        const year = moment().year() - i;
        if (year > 2023) {
            yearsList.push((moment().year() - i));
        }
    }

    const loadData = () => {
        API.getIOReference(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    setReportData(apiCall.data.data.report);
                }
            },
            year
        );

    }

    useEffect(() => {
        loadData();
    }, [year]);

    return (
        <div>
            <Row>
                <Col xs={"auto"}>
                    <Tabs activeKey={year} onSelect={(k) => setYear(parseInt(k || moment().year().toString()))}>
                        {yearsList.map(y => <Tab key={y} eventKey={y} title={y}/>)}
                    </Tabs>
                </Col>
                <Col/>
                <Col xs={"auto"}>
                    <ToggleButtonGroup type="checkbox" value={selectedMonths}
                                       onChange={
                                           (v) => {
                                               if (v) {
                                                   setSelectedMonths(v);
                                               }
                                           }
                                       }
                    >
                        {
                            SHORT_MONTHS_NAMES.map(
                                m => <ToggleButton
                                    key={m}
                                    id={`toggle-check-${m}`}
                                    value={m}>{m}</ToggleButton>
                            )
                        }
                    </ToggleButtonGroup>
                </Col>
            </Row>

            {
                loading ?
                    <Row>
                        <Col className={"text-center"}>
                            <br/>
                            <Spinner animation={"border"}></Spinner> Зареждам данните ...
                        </Col>
                    </Row>
                    :
                    <>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs={12}>
                                        <BlockTitleComponent title={"РАЗХОДИ (без ДДС)"} />
                                        <IOReportTable
                                            selectedMonths={selectedMonths}
                                            data={reportData?.outcome}
                                            showDetails={true}
                                        />
                                    </Col>
                                    {
                                        reportData?.dds &&
                                        <Col xs={12}>
                                            <BlockTitleComponent title={"РАЗХОДИ ЗА ДДС"}/>
                                            <IOReportTable
                                                selectedMonths={selectedMonths}
                                                data={{dds: reportData.dds}}
                                                showDetails={false}
                                                skipTotal={true}
                                            />
                                        </Col>
                                    }
                                </Row>
                            </Col>
                            <Col>
                                <BlockTitleComponent title={"ПРИХОДИ (без ДДС)"}/>
                                <IOReportTable
                                    selectedMonths={selectedMonths}
                                    data={reportData?.income}
                                />
                                <br/>
                                <BlockTitleComponent title={"ФИНАНСОВ РЕЗУЛТАТ (без ДДС)"}/>
                                <IOReportTable
                                    skipTotal={true}
                                    firstRowBold={true}
                                    selectedMonths={selectedMonths}
                                    data={reportData?.financialResult}
                                />
                                <br/>
                                <BlockTitleComponent title={"ГРАФИКА"}/>
                                <IORefChart data={reportData?.totals} year={year}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <BlockTitleComponent title={"ИНВЕСТИЦИИ"}/>
                                <IOReportInvestmentTable
                                    data={reportData?.investments}
                                    year={year}
                                />
                            </Col>
                        </Row>
                    </>
            }
        </div>
    )
};

export default IOReference;
