import {Col, FormSelect, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import React, {useEffect} from "react";
import {BlockTitleComponent} from "../../components/common/BlockTitleComponent";
import moment from "moment/moment";
import {API} from "../../api";
import {API_CALL} from "../../types";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../common";
import BudgetsPlanTable from "./BudgetsPlanTable";
import {BudgetsReport} from "./BudgetsTypes";

const BudgetsPlan = () => {
    const [year, setYear] = React.useState<number>(moment().year());
    const [loading, setLoading] = React.useState<boolean>(false);
    const [budgets, setBudgets] = React.useState<BudgetsReport | undefined>(undefined);

    let yearsList = [];
    for (let i = -1; i < 3; i++) {
        const year = moment().year() - i;
        if(year > 2023) {
            yearsList.push((moment().year() - i));
        }
    }

    const loadBudgets = (forYear: number) => {
        setLoading(true);
        API.getBudgets(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    setBudgets(apiCall.data.data as BudgetsReport);
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Възникна грешка", translateError);
                    setBudgets(undefined);
                }
            }, year
        )
    }

    useEffect(() => {
        loadBudgets(year);
    }, [year]);

    return (
        <div>
            <BlockTitleComponent title={""}/>
            <Row>
                <Col>
                    <Tabs activeKey={year} onSelect={(k) => setYear(parseInt(k || moment().year().toString()))}>
                        {yearsList.map(y => <Tab key={y} eventKey={y} title={y}/>)}
                    </Tabs>
                </Col>
            </Row>

            {
                loading || !budgets ?
                    <Row>
                        <Col className={"text-center"}>
                            <br/>
                            <Spinner animation={"border"}></Spinner> Зареждам данните ...
                        </Col>
                    </Row>
                    :
                    <>
                        <BlockTitleComponent title={"БАНКА"}/>
                        <Row>
                            <Col>
                                <BudgetsPlanTable
                                    budgets={budgets}
                                    viewMode={"BANK"}
                                    year={year}
                                />
                            </Col>
                        </Row>

                        <BlockTitleComponent title={"КАСА"}/>
                        <Row>
                            <Col>
                                <BudgetsPlanTable
                                    budgets={budgets}
                                    viewMode={"CASH"}
                                    year={year}
                                />
                            </Col>
                        </Row>
                    </>
            }
        </div>
    );
};

export default BudgetsPlan;
