import React from "react";
import {API_CALL, PARAMETERS, PropertyValue} from "../../types";
import {
    CASH_EXPENSES,
    isError,
    isLoading,
    isSuccess,
    mapToObject, numberWithCommas,
    showErrorsInToast,
    showMsgInToast,
    zeroToEmptyString,
    zeroToEmptyStringAsNumber
} from "../../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment, {Moment} from "moment";
import {API} from "../../api";
import {Link} from "react-router-dom";
import {PATHS} from "../../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {ReadOnlyDatePickerComponent} from "../../components/common/ReadOnlyDatePickerComponent";
import {DailyReportsPage} from "../DailyReportsPage";
import {BlockTitleComponent} from "../../components/common/BlockTitleComponent";
import {Col, Dropdown, DropdownButton, Row} from "react-bootstrap";
import DailyFullReportExpences from "../../components/daily-full-report/DailyFullReportExpences";
import {ExpenceType} from "../../components/daily-full-report/DailyFullReportExpRow";
import DailyFullReportHristo from "./DailyFullReportHristo";

interface DailyFullReportPageState {
    working: boolean,
    loading: number,
    selectedDate: moment.Moment,
    report: any | null,
    reports: any[],
    peletTypes: PropertyValue[],
    brickTypes: PropertyValue[],
    bank_exp_types: Array<ExpenceType>,
    cash_exp_types: Array<ExpenceType>,
    formValues: Map<string, any>,
}

export let PREV_BAGS_IN_PALET: number[] = [70, 55];
export let BAGS_IN_PALET: number[] = [70, 55];

export class DailyFullReportPage extends React.Component<any, DailyFullReportPageState> {
    totals = new Map<string, number>();

    constructor(props: any) {
        super(props);
        this.state = {
            working: false,
            loading: 0,
            selectedDate: moment(),
            report: null,
            reports: [],
            peletTypes: [],
            brickTypes: [],
            bank_exp_types: [],
            cash_exp_types: [],
            formValues: new Map<string, any>(),
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.loadReports = this.loadReports.bind(this);
        this.RenderConsumption = this.RenderConsumption.bind(this);
        this.RenderPeleti = this.RenderPeleti.bind(this);
        this.RenderReport = this.RenderReport.bind(this);
        this.RenderBricks = this.RenderBricks.bind(this);
        this.RenderCash = this.RenderCash.bind(this);
        this.saveReport = this.saveReport.bind(this);
        this.showPrevDate = this.showPrevDate.bind(this);
        this.showNextDate = this.showNextDate.bind(this);
    }

    componentDidMount() {
        this.loadParameters(this.state.selectedDate);
    }

    loadParameters(selectedDate: Moment) {
        const _forDate = selectedDate.format("Y-MM-DD");
        const _prevDate = moment(selectedDate).subtract(1, "day").format("Y-MM-DD")
        this.setState({loading: this.state.loading + 3});
        API.getParameterValuesForDate(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    BAGS_IN_PALET = [];
                    apiCall.data.data.items?.values.forEach(
                        (p: any) => BAGS_IN_PALET.push(p.description.paletQty / p.description.sackQty)
                    );

                    this.setState({
                        loading: this.state.loading - 1,
                        peletTypes: apiCall.data.data.items?.values
                    });

                    if (this.state.loading <= 1) {
                        this.loadReports();
                    }
                }
            }, PARAMETERS.PELET_TYPE, _forDate
        );
        API.getParameterValuesForDate(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    PREV_BAGS_IN_PALET = [];
                    apiCall.data.data.items?.values.forEach(
                        (p: any) => PREV_BAGS_IN_PALET.push(p.description.paletQty / p.description.sackQty)
                    );

                    this.setState({
                        loading: this.state.loading - 1,
                        peletTypes: apiCall.data.data.items?.values
                    });

                    if (this.state.loading <= 1) {
                        this.loadReports();
                    }
                }
            }, PARAMETERS.PELET_TYPE, _prevDate
        );
        API.getParameterValuesForDate(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        brickTypes: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.BRICKS, _forDate
        );
    }

    loadReports(newDate: Moment | null = null) {
        API.getFullDailyReports(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});
                if (isSuccess(apiCall)) {
                    const report = apiCall.data.data.report;
                    const fv = new Map<string, any>();

                    fv.set('is_locked', report.is_locked == 1);
                    fv.set('description', report.description);

                    this.setState({bank_exp_types: report.bank_exp_types});
                    this.setState({cash_exp_types: report.cash_exp_types});


                    Object.keys(report.production.items).map(
                        (key: any, idx: number) => {
                            const item = report.production.items[key];
                            fv.set("bricks_saldo_" + idx, item.prev_saldo);
                        }
                    );

                    Object.keys(report.pelets).map(
                        (key: any, idx: number) => {
                            const item = report.pelets[key];
                            fv.set("pelets_saldo_kg_" + idx, item.prev_saldo.kg);

                            let palets = item.prev_saldo.palets;
                            let bags = item.prev_saldo.bags + palets * PREV_BAGS_IN_PALET[idx];

                            palets = Math.floor(bags / BAGS_IN_PALET[idx]);
                            bags = bags - palets * BAGS_IN_PALET[idx];

                            fv.set("pelets_saldo_palets_" + idx, palets);
                            fv.set("pelets_saldo_bags_" + idx, bags);

                            fv.set("pelets_income_kg_" + idx, item.income.kg);
                            fv.set("pelets_income_palets_" + idx, item.income.palets);
                            fv.set("pelets_consumption_others_" + idx, item.consumption.others);
                        }
                    );

                    for (let i = 0; i < 50; i++) {
                        fv.set("cash_expense_description_" + i, '');
                    }

                    report.cash.withdraw.map(
                        (item: any) => {
                            Object.keys(item).map(
                                (key: string) => fv.set(key, key === 'amount' ? Number.parseFloat(item[key] || 0).toFixed(2) : item[key])
                            );
                        }
                    );

                    for (let i = 0; i < 50; i++) {
                        fv.set("bank_expense_description_" + i, '');
                    }

                    report.bank.map(
                        (item: any) => {
                            Object.keys(item).map(
                                (key: string) => fv.set(key, key.indexOf('bank_expense_amount_') > -1 ? Number.parseFloat(item[key] || 0).toFixed(2) : item[key])
                            );
                        }
                    );
                    fv.set("cash_saldo", Number.parseFloat(report.cash.prev_saldo || 0).toFixed(2));

                    fv.set('sludge_prev_saldo', zeroToEmptyStringAsNumber(report.sludge.prev_saldo.toString() || '0'));
                    fv.set('sludge_income', report.sludge.income);
                    fv.set('sludge_withdraw', report.sludge.withdraw);
                    fv.set('sludge_saldo', report.sludge.saldo);

                    fv.set('sludge_day_price', report.sludge.day_price);
                    fv.set('6mm_day_price', report.consumption.price_6mm);
                    fv.set('8mm_day_price', report.consumption.price_8mm);

                    fv.set('stretch_saldo', zeroToEmptyStringAsNumber(report.stretch_saldo.toString() || '0'));
                    fv.set('stretch_income', zeroToEmptyStringAsNumber(report.stretch_income || '0'));
                    fv.set('stretch_consumption', report.stretch_consumption || 0);
                    fv.set('stretch_total', report.stretch_total || 0);

                    fv.set('diesel_saldo', report.diesel_saldo || 0);
                    fv.set('diesel_income', report.diesel_income || 0);
                    fv.set('diesel_consumption', report.diesel_consumption || 0);
                    fv.set('diesel_total', report.diesel_total || 0);

                    fv.set('gas_saldo', report.gas_saldo || 0);
                    fv.set('gas_income', report.gas_income || 0);
                    fv.set('gas_consumption', report.gas_consumption || 0);
                    fv.set('gas_total', report.gas_total || 0);

                    fv.set('bank_palet_qty', report.bank_palet_qty || 0);
                    fv.set('bank_palet_price', report.bank_palet_price || 0);
                    fv.set('cash_palet_qty', report.cash_palet_qty || 0);
                    fv.set('cash_palet_price', report.cash_palet_price || 0);

                    fv.set('hristoBankOut', report.hristoBankOut ?? 0);
                    fv.set('hristoBankIn', report.hristoBankIn ?? 0);
                    fv.set('hristoCashOut', report.hristoCashOut ?? 0);
                    fv.set('hristoCashIn', report.hristoCashIn ?? 0);

                    this.setState({report: report, formValues: fv});
                }
            }, newDate ? newDate : this.state.selectedDate
        );
    }

    saveReport() {
        if (this.state.formValues.get('is_locked') !== true) {
            this.state.formValues.set('description', null)
        }

        this.totals.forEach(
            (v, k) => {
                this.state.formValues.set(k, v);
            }
        );

        const reportData = new Map(this.state.formValues);

        for (let i = 0; i < 50; i++) {
            if (!reportData.get(`cash_expense_amount_${i}`)) {
                reportData.delete(`cash_expense_description_${i}`);
                reportData.delete(`cash_expense_qty_${i}`);
                reportData.delete(`cash_expense_amount_${i}`);
                reportData.delete(`cash_expense_has_vat_${i}`);
            } else {
                reportData.set(`cash_expense_has_vat_${i}`, reportData.get(`cash_expense_has_vat_${i}`) ? 1 : 0);
            }

            if (!reportData.get(`bank_expense_amount_${i}`)) {
                reportData.delete(`bank_expense_description_${i}`);
                reportData.delete(`bank_expense_qty_${i}`);
                reportData.delete(`bank_expense_amount_${i}`);
                reportData.delete(`bank_expense_has_vat_${i}`);
            } else {
                reportData.set(`bank_expense_has_vat_${i}`, reportData.get(`bank_expense_has_vat_${i}`) ? 1 : 0);
            }
        }

        API.saveFullDailyReports(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    showMsgInToast('Отчета е записан успешно!');
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Грешка');
                }
            }, mapToObject(reportData), this.state.selectedDate
        );
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )
    }

    RenderBricks() {
        if (!this.state.report) return null;
        const me = this;
        return (
            <table className={"table table-sm table-bordered"}>
                <thead className={"text-center"}>
                <tr>
                    <th colSpan={13}><strong>ПРОИЗВЕДЕНИ И ПРОДАДЕНИ ТУХЛИ</strong></th>
                </tr>
                <tr>
                    <th rowSpan={3}>Артикул</th>
                    <th className={"w-120px"}>Начално салдо</th>
                    <th colSpan={4}>I смяна</th>
                    <th colSpan={4}>II смяна</th>
                    <th rowSpan={2}>Продажби</th>
                    <th rowSpan={2}>Ср.цена</th>
                    <th rowSpan={2}>Крайно салдо</th>
                </tr>
                <tr>
                    <th rowSpan={2}>палет</th>
                    <th colSpan={3}>Производство</th>
                    <th>Брак</th>
                    <th colSpan={3}>Производство</th>
                    <th>Брак</th>
                </tr>
                <tr>
                    <th>вагони</th>
                    <th>тухли</th>
                    <th>палет</th>
                    <th>тухли</th>
                    <th>вагони</th>
                    <th>тухли</th>
                    <th>палет</th>
                    <th>тухли</th>
                    <th>палет</th>
                    <th>лв/тухла</th>
                    <th>палет</th>
                </tr>
                </thead>
                <tbody>

                {
                    Object.keys(this.state.report.production.items).map(
                        (key: any, idx: number) => {
                            if (key) {
                                const item = this.state.report.production.items[key];

                                const calcSaldo = function (prevSaldo: number, idx: number) {
                                    const saldo = Math.round(prevSaldo * 1 + item.day_shift.palets * 1 + item.night_shift.palets * 1 - item.sales * 1);
                                    me.totals.set('bricks_total_' + idx, saldo);
                                    return saldo;
                                }

                                const calcPrice = function (item: any) {
                                    return ((item.price * 1 > 0) && (item.bricks_cnt * 1 > 0)) ?
                                        ((item.price * 1) / (item.bricks_cnt * 1)).toFixed(2)
                                        : 0
                                }

                                return (
                                    <tr className={"text-end"}>
                                        <td className={"text-start"}>{item.articul}</td>
                                        <td className={"no-padding"}>
                                            <input
                                                className={"form-control form-control-sm"} type={"text"}
                                                name={"bricks_saldo_" + idx} key={"bricks_saldo_" + idx}
                                                onChange={this.handleFormChange}
                                                value={zeroToEmptyString(Math.round(this.state.formValues.get("bricks_saldo_" + idx)))}
                                            />
                                        </td>
                                        <td>{zeroToEmptyString(item.day_shift.wagons)}</td>
                                        <td>{zeroToEmptyString(item.day_shift.bricks)}</td>
                                        <td>{zeroToEmptyString(Math.round(item.day_shift.palets))}</td>
                                        <td>{zeroToEmptyString(item.day_shift.waste)}</td>
                                        <td>{zeroToEmptyString(item.night_shift.wagons)}</td>
                                        <td>{zeroToEmptyString(item.night_shift.bricks)}</td>
                                        <td>{zeroToEmptyString(Math.round(item.night_shift.palets))}</td>
                                        <td>{zeroToEmptyString(item.night_shift.waste)}</td>
                                        <td>{zeroToEmptyString(Math.round(item.sales))}</td>
                                        <td>{zeroToEmptyString(calcPrice(item))}</td>
                                        <td>{zeroToEmptyString(calcSaldo(this.state.formValues.get("bricks_saldo_" + idx), idx))}</td>
                                    </tr>
                                )
                            } else return null
                        }
                    )
                }

                </tbody>
            </table>
        )
    }

    RenderConsumption() {
        const me = this;
        const calcSaldo = function (fv: Map<string, any>) {
            let prevSaldo = fv.get('sludge_prev_saldo') * 1;
            if (isNaN(prevSaldo)) prevSaldo = 0;
            let income = fv.get('sludge_income') * 1;
            if (isNaN(income)) income = 0;
            let withdraw = me.state.report?.sludge.withdraw * 1;
            if (isNaN(withdraw)) withdraw = 0;

            const saldo = Math.round(prevSaldo + income - withdraw);
            me.totals.set('sludge_saldo', saldo);
            return saldo;
        }
        const calcDayPrice = function (item: string) {
            const day_price = me.state.formValues.get(item + "_day_price") || 0;
            let total = 0;

            switch (item) {
                case "sludge" :
                    total = me.state.report?.sludge.withdraw * 1;
                    break;
                case "6mm" :
                    total = me.state.report?.consumption.day_shift["6 мм"].heater * 1 + me.state.report?.consumption.night_shift["6 мм"].heater * 1 +
                        me.state.report?.consumption.day_shift["6 мм"].oven * 1 + me.state.report?.consumption.night_shift["6 мм"].oven * 1;
                    break;
                case "8mm" :
                    total = me.state.report?.consumption.day_shift["8 мм"].heater * 1 + me.state.report?.consumption.night_shift["8 мм"].heater * 1 +
                        me.state.report?.consumption.day_shift["8 мм"].oven * 1 + me.state.report?.consumption.night_shift["8 мм"].oven * 1;
                    break;
            }
            const sum = total * day_price;
            return isNaN(sum) ? 0 : sum.toFixed(2);
        }

        return (
            <table className={"table table-sm table-bordered "}>
                <thead className={"text-center"}>
                <tr>
                    <th colSpan={5}><strong>РАЗХОД ПЕЛЕТИ В ПЕЩ И СУШИЛНЯ (в кг)</strong></th>
                    <th colSpan={4}><strong>ДВИЖЕНИЕ ШЛАМ (в кг)</strong></th>
                    <th colSpan={4}><strong>РАЗХОД (в лв)</strong></th>
                </tr>
                <tr>
                    <th rowSpan={2}>Смяна</th>
                    <th colSpan={2}>Сушилня</th>
                    <th colSpan={2}>Пещ</th>

                    <th>Нач.салдо</th>
                    <th>Приход</th>
                    <th>Разход</th>
                    <th>Крайно салдо</th>

                    <th>Артикул</th>
                    <th>Покупна цена</th>
                    <th>Всичко за деня</th>
                </tr>
                <tr>
                    <th>6 mm</th>
                    <th>8 mm</th>
                    <th>6 mm</th>
                    <th>8 mm</th>

                    <td className={"w-120px no-padding"}>
                        <input
                            className={"form-control form-control-sm"} type={"text"} name={"sludge_prev_saldo"}
                            onChange={this.handleFormChange}
                            value={zeroToEmptyString(this.state.formValues.get("sludge_prev_saldo"))}
                        />
                    </td>
                    <td className={"w-120px no-padding"}>
                        <input
                            className={"form-control form-control-sm"} type={"text"} name={"sludge_income"}
                            onChange={this.handleFormChange}
                            value={zeroToEmptyString(this.state.formValues.get("sludge_income"))}
                        />
                    </td>
                    <td className={"w-120px text-end"}>{zeroToEmptyString(this.state.report?.sludge.withdraw)} </td>
                    <td className={"w-120px text-end"}>{zeroToEmptyString(calcSaldo(this.state.formValues))} </td>

                    <td className={"text-center font-weight-bold"}>
                        Шлам
                    </td>
                    <td className={"w-120px no-padding"}>
                        <input
                            className={"form-control form-control-sm"} type={"text"} name={"sludge_day_price"}
                            onChange={this.handleFormChange}
                            value={zeroToEmptyString(this.state.formValues.get("sludge_day_price"))}
                        />
                    </td>
                    <td className={"text-end"}>
                        {calcDayPrice('sludge')}
                    </td>
                </tr>
                </thead>
                <tbody className={"text-end"}>
                <tr>
                    <td className={"text-start"}>I смяна</td>
                    <td>{zeroToEmptyString(this.state.report?.consumption.day_shift['6 мм'].heater)}</td>
                    <td>{zeroToEmptyString(this.state.report?.consumption.day_shift['8 мм'].heater)}</td>
                    <td>{zeroToEmptyString(this.state.report?.consumption.day_shift['6 мм'].oven)}</td>
                    <td>{zeroToEmptyString(this.state.report?.consumption.day_shift['8 мм'].oven)}</td>
                    <td colSpan={4}></td>

                    <td className={"text-center font-weight-bold"}>
                        6 mm
                    </td>
                    <td className={"w-120px no-padding"}>
                        <input
                            className={"form-control form-control-sm"} type={"text"} name={"6mm_day_price"}
                            onChange={this.handleFormChange}
                            value={zeroToEmptyString(this.state.formValues.get("6mm_day_price"))}
                        />
                    </td>
                    <td>
                        {calcDayPrice('6mm')}
                    </td>
                </tr>
                <tr>
                    <td className={"text-start"}>II смяна</td>
                    <td>{zeroToEmptyString(this.state.report?.consumption.night_shift['6 мм'].heater)}</td>
                    <td>{zeroToEmptyString(this.state.report?.consumption.night_shift['8 мм'].heater)}</td>
                    <td>{zeroToEmptyString(this.state.report?.consumption.night_shift['6 мм'].oven)}</td>
                    <td>{zeroToEmptyString(this.state.report?.consumption.night_shift['8 мм'].oven)}</td>
                    <td colSpan={4}></td>

                    <td className={"text-center font-weight-bold"}>
                        8 mm
                    </td>
                    <td className={"w-120px no-padding"}>
                        <input
                            className={"form-control form-control-sm"} type={"text"} name={"8mm_day_price"}
                            onChange={this.handleFormChange}
                            value={zeroToEmptyString(this.state.formValues.get("8mm_day_price"))}
                        />
                    </td>
                    <td>
                        {calcDayPrice('8mm')}
                    </td>
                </tr>
                </tbody>
            </table>
        );
    }

    RenderPeleti() {
        if (!this.state.report) return null;

        const me = this;
        return (
            <table className={"table table-sm table-bordered "}>
                <thead className={"text-center"}>
                <tr>
                    <th colSpan={15}><strong>ДВИЖЕНИЕ ПЕЛЕТИ</strong></th>
                </tr>
                <tr>
                    <th rowSpan={2}>Артикул</th>
                    <th colSpan={3}>Нач.салдо</th>
                    <th colSpan={2}>Приход</th>
                    <th colSpan={3}>Разход</th>
                    <th colSpan={3}>Продажби</th>
                    <th colSpan={3}>Крайно салдо</th>
                </tr>
                <tr>
                    <th className={"w-120px"}>кг</th>
                    <th className={"w-120px"}>палети</th>
                    <th className={"w-120px"}>чувал</th>
                    <th className={"w-120px"}>кг</th>
                    <th className={"w-120px"}>палети</th>
                    <th>кг</th>
                    <th>палети</th>
                    <th>други(кг)</th>
                    <th>кг</th>
                    <th>палети</th>
                    <th>чувал</th>
                    <th>кг</th>
                    <th>палети</th>
                    <th>чувал</th>
                </tr>
                </thead>
                <tbody className={"text-end"}>
                {
                    Object.keys(this.state.report?.pelets).map(
                        (key: any, idx: number) => {
                            const item = this.state.report?.pelets[key];

                            const calcSaldo = function (prevSaldo: number, income: number, others: number, suff: string, idx: number) {
                                if (isNaN(income * 1)) income = 0;
                                if (isNaN(others * 1)) others = 0;

                                const saldo = Math.round(prevSaldo * 1 + income * 1 - others * 1 - item.consumption[suff] * 1 - item.sales[suff] * 1);
                                me.totals.set('pelets_total_' + suff + '_' + idx, saldo);

                                return saldo;
                            }
                            const calcSaldoPalets = function (consumptionPalets: number, salesPalets: number, salesBags: number, idx: number) {

                                const resp = {palets: 0, bags: 0};

                                let pelets_saldo_palets = 1 * me.state.formValues.get("pelets_saldo_palets_" + idx);
                                if (isNaN(pelets_saldo_palets)) pelets_saldo_palets = 0;

                                let pelets_income_palets = 1 * me.state.formValues.get("pelets_income_palets_" + idx);
                                if (isNaN(pelets_income_palets)) pelets_income_palets = 0;

                                let pelets_saldo_bags = 1 * me.state.formValues.get("pelets_saldo_bags_" + idx);
                                if (isNaN(pelets_saldo_bags)) pelets_saldo_bags = 0;

                                const prevSaldoBags = pelets_saldo_palets * BAGS_IN_PALET[idx] +
                                    pelets_income_palets * BAGS_IN_PALET[idx] +
                                    pelets_saldo_bags - consumptionPalets * BAGS_IN_PALET[idx] -
                                    salesPalets * BAGS_IN_PALET[idx] - salesBags;

                                resp.palets = Math.floor(prevSaldoBags / BAGS_IN_PALET[idx]);
                                resp.bags = prevSaldoBags % BAGS_IN_PALET[idx];

                                me.totals.set('pelets_total_palets_' + idx, resp.palets);
                                me.totals.set('pelets_total_bags_' + idx, resp.bags);

                                return resp;
                            }
                            const calcPaletsBags = function (palets: number, bags: number, idx: number) {
                                const resp = {palets: 0, bags: 0};

                                let _palets = 1 * palets;
                                if (isNaN(_palets)) _palets = 0;
                                let _bags = 1 * bags;
                                if (isNaN(_bags)) _bags = 0;
                                let totalBags = _palets * BAGS_IN_PALET[idx] + _bags;

                                resp.palets = Math.floor(totalBags / BAGS_IN_PALET[idx]);
                                resp.bags = totalBags % BAGS_IN_PALET[idx];

                                return resp;
                            }
                            return (
                                <tr>
                                    <td className={"text-start"}>{key}</td>
                                    <td className={"no-padding"}>
                                        <input
                                            className={"form-control form-control-sm"} type={"text"}
                                            name={"pelets_saldo_kg_" + idx}
                                            key={"pelets_saldo_kg_" + idx} onChange={this.handleFormChange}
                                            value={zeroToEmptyString(this.state.formValues.get("pelets_saldo_kg_" + idx))}
                                        />
                                    </td>
                                    <td className={"no-padding"}>
                                        <input
                                            className={"form-control form-control-sm"} type={"text"}
                                            name={"pelets_saldo_palets_" + idx}
                                            key={"pelets_saldo_palets_" + idx} onChange={this.handleFormChange}
                                            value={zeroToEmptyString(
                                                calcPaletsBags(this.state.formValues.get("pelets_saldo_palets_" + idx),
                                                    this.state.formValues.get("pelets_saldo_bags_" + idx), idx).palets
                                            )}
                                        />
                                    </td>
                                    <td className={"no-padding"}>
                                        <input
                                            className={"form-control form-control-sm"} type={"text"}
                                            name={"pelets_saldo_bags_" + idx}
                                            key={"pelets_saldo_bags_" + idx} onChange={this.handleFormChange}
                                            value={zeroToEmptyString(
                                                calcPaletsBags(this.state.formValues.get("pelets_saldo_palets_" + idx),
                                                    this.state.formValues.get("pelets_saldo_bags_" + idx), idx).bags
                                            )}
                                        />
                                    </td>
                                    <td className={"no-padding"}>
                                        <input
                                            className={"form-control form-control-sm"} type={"text"}
                                            name={"pelets_income_kg_" + idx}
                                            key={"pelets_income_kg_" + idx} onChange={this.handleFormChange}
                                            value={zeroToEmptyString(this.state.formValues.get("pelets_income_kg_" + idx))}
                                        />
                                    </td>
                                    <td className={"no-padding"}>
                                        <input
                                            className={"form-control form-control-sm"} type={"text"}
                                            name={"pelets_income_palets_" + idx}
                                            key={"pelets_income_palets_" + idx} onChange={this.handleFormChange}
                                            value={zeroToEmptyString(this.state.formValues.get("pelets_income_palets_" + idx))}
                                        />
                                    </td>
                                    <td>{zeroToEmptyString(item.consumption.kg)}</td>
                                    <td>{zeroToEmptyString(Math.round(item.consumption.palets))}</td>
                                    <td className={"no-padding w-120px"}>
                                        <input
                                            className={"form-control form-control-sm"} type={"text"}
                                            name={"pelets_consumption_others_" + idx}
                                            key={"pelets_consumption_others_" + idx} onChange={this.handleFormChange}
                                            value={zeroToEmptyString(this.state.formValues.get("pelets_consumption_others_" + idx))}
                                        />
                                    </td>
                                    <td>{zeroToEmptyString(item.sales.kg)}</td>
                                    <td>{zeroToEmptyString(Math.round(item.sales.palets))}</td>
                                    <td>{zeroToEmptyString(Math.round(item.sales.bags))}</td>
                                    <td>{zeroToEmptyString(
                                        calcSaldo(this.state.formValues.get("pelets_saldo_kg_" + idx),
                                            this.state.formValues.get("pelets_income_kg_" + idx),
                                            this.state.formValues.get("pelets_consumption_others_" + idx), 'kg', idx))}</td>
                                    <td>{zeroToEmptyString(
                                        calcSaldoPalets(item.consumption.palets, item.sales.palets, item.sales.bags, idx).palets)}</td>
                                    <td>{zeroToEmptyString(
                                        calcSaldoPalets(item.consumption.palets, item.sales.palets, item.sales.bags, idx).bags)}</td>
                                </tr>
                            )
                        }
                    )
                }
                </tbody>
            </table>
        );
    }

    RenderOthers() {
        if (!this.state.report) return null;
        const me = this;
        const calcSaldo = function (prevSaldo: number, income: number, consumption: number, fieldName: string) {
            if (isNaN(income * 1)) income = 0;
            if (isNaN(consumption * 1)) consumption = 0;

            const saldo = (prevSaldo * 1 + income * 1 - consumption * 1);
            me.totals.set(fieldName, saldo);

            return saldo;
        }
        return (
            <table className={"table table-sm table-bordered "}>
                <thead className={"text-center"}>
                <tr>
                    <th colSpan={5}><strong>ДВИЖЕНИЕ ГОРИВО</strong></th>
                    <th colSpan={5}><strong>ДВИЖЕНИЕ СТРЕЧ</strong></th>
                </tr>
                <tr>
                    <th>Артикул</th>
                    <th>Нач.салдо</th>
                    <th>Приход</th>
                    <th>Разход</th>
                    <th>Крайно салдо</th>
                    <th>Артикул</th>
                    <th>Нач.салдо</th>
                    <th>Приход</th>
                    <th>Разход</th>
                    <th>Крайно салдо</th>
                </tr>
                </thead>
                <tbody className={"text-end"}>
                <tr>
                    <td className={"text-start"}>Гориво дизел (л.)</td>
                    <td className={"no-padding w-120px"}>
                        <input
                            className={"form-control form-control-sm"} type={"text"} name={"diesel_saldo"}
                            key={"diesel_saldo"} onChange={this.handleFormChange}
                            value={zeroToEmptyString(this.state.formValues.get("diesel_saldo"))}
                        />
                    </td>
                    <td className={"no-padding w-120px"}>
                        <input
                            className={"form-control form-control-sm"} type={"text"} name={"diesel_income"}
                            key={"diesel_income"} onChange={this.handleFormChange}
                            value={zeroToEmptyString(this.state.formValues.get("diesel_income"))}
                        />
                    </td>
                    <td>{zeroToEmptyString(this.state.formValues.get("diesel_consumption"))}</td>
                    <td>
                        {(
                            calcSaldo(this.state.formValues.get("diesel_saldo"),
                                this.state.formValues.get("diesel_income"),
                                this.state.formValues.get("diesel_consumption"), "diesel_total"))}
                    </td>

                    <td className={"text-start"}>Стреч фолио (кг.)</td>
                    <td className={"no-padding w-120px"}>
                        <input
                            className={"form-control form-control-sm"} type={"text"} name={"stretch_saldo"}
                            key={"stretch_saldo"} onChange={this.handleFormChange}
                            value={this.state.formValues.get("stretch_saldo")}
                        />
                    </td>
                    <td className={"no-padding w-120px"}>
                        <input
                            className={"form-control form-control-sm"} type={"text"} name={"stretch_income"}
                            key={"stretch_income"} onChange={this.handleFormChange}
                            value={this.state.formValues.get("stretch_income")}
                        />
                    </td>
                    <td>{zeroToEmptyStringAsNumber(this.state.formValues.get("stretch_consumption"))}</td>
                    <td>
                        {(
                            zeroToEmptyStringAsNumber(
                                calcSaldo(
                                    this.state.formValues.get("stretch_saldo"),
                                    this.state.formValues.get("stretch_income"),
                                    this.state.formValues.get("stretch_consumption"), "stretch_total")
                                    .toString()))}
                    </td>
                </tr>
                <tr>
                    <td className={"text-start"}>Гориво газ (бут.)</td>
                    <td className={"no-padding w-120px"}>
                        <input
                            className={"form-control form-control-sm"} type={"text"} name={"gas_saldo"}
                            key={"gas_saldo"} onChange={this.handleFormChange}
                            value={zeroToEmptyString(this.state.formValues.get("gas_saldo"))}
                        />
                    </td>
                    <td className={"no-padding w-120px"}>
                        <input
                            className={"form-control form-control-sm"} type={"text"} name={"gas_income"}
                            key={"gas_income"} onChange={this.handleFormChange}
                            value={zeroToEmptyString(this.state.formValues.get("gas_income"))}
                        />
                    </td>
                    <td>{(this.state.formValues.get("gas_consumption"))}</td>
                    <td>
                        {zeroToEmptyString(
                            calcSaldo(this.state.formValues.get("gas_saldo"),
                                this.state.formValues.get("gas_income"),
                                this.state.formValues.get("gas_consumption"), "gas_total"))}
                    </td>
                </tr>
                </tbody>
            </table>
        );
    }

    RenderCash() {
        if (!this.state.report) return null;
        const me = this;

        const calculateTotalIncome = function (deposit: any, fv: Map<string, any>) {
            let total = 0;
            deposit.map(
                (i: any) => {
                    const amount = i.amount * 1;
                    if (!isNaN(amount)) {
                        total += amount;
                    }
                }
            );

            for (let i = 0; i < 50; i++) {
                if (fv.get('cash_expense_description_' + i) !== "ВЪРНАТИ ПАРИ В КАСА") continue;
                const amount = fv.get('cash_expense_amount_' + i) * 1;
                if (!isNaN(amount)) {
                    total += amount;
                }
            }

            if(fv.has('hristoCashIn') && fv.get('hristoCashIn') > 0) {
                total += fv.get('hristoCashIn') * 1;
            }

            return total;
        }

        const calculateTotalExpenses = function (fv: Map<string, any>) {
            let total = 0;
            for (let i = 0; i < 50; i++) {
                if (fv.get('cash_expense_description_' + i) === "ВЪРНАТИ ПАРИ В КАСА") continue;
                const amount = fv.get('cash_expense_amount_' + i) * 1;
                if (!isNaN(amount)) {
                    total += amount;
                }
            }

            if(fv.has('hristoCashOut') && fv.get('hristoCashOut') > 0) {
                total += fv.get('hristoCashOut') * 1;
            }

            return total;
        }

        const calculateTotal = function (deposit: any, fv: Map<string, any>) {
            let totalIncome = calculateTotalIncome(deposit, fv);
            let totalExpenses = calculateTotalExpenses(fv);
            let prevSaldo = fv.get('cash_saldo') * 1;
            if (isNaN(prevSaldo)) prevSaldo = 0;

            const total = prevSaldo + totalIncome - totalExpenses;
            me.totals.set('cash_total', total);
            return total;
        }

        const calculateBankTotal = () => {
            let total = 0;
            for (let i = 0; i < 50; i++) {
                let value = this.state.formValues.get("bank_expense_amount_" + i);
                if (value) {
                    total += value * 1;
                }
            }
            total += this.state.formValues.get("bank_palet_price") * 1;
            return numberWithCommas(total.toFixed(2)) + ' лв.'
        }

        return (
            <table className={"table table-sm table-bordered casa"}>
                <thead className={"text-center"}>
                <tr>
                    <th rowSpan={2}><strong>РАЗХОДИ БАНКА</strong></th>
                    <th colSpan={3}><strong>ДВИЖЕНИЕ КАСА</strong></th>
                </tr>
                <tr className={"text-center"}>
                    <td><strong>РАЗХОДИ ЗА ДЕНЯ</strong></td>
                    <td><strong>ПРИХОДИ ЗА ДЕНЯ</strong></td>
                    <td></td>
                </tr>

                </thead>
                <tbody>
                <tr>
                    <td className={"no-padding align-top"}>
                        <DailyFullReportExpences
                            formValues={this.state.formValues}
                            setFormValues={
                                (fv: Map<string, any>) => {
                                    this.setState({formValues: fv})
                                }
                            }
                            exp_types={this.state.bank_exp_types}
                            exp_type={"bank"}
                        />
                    </td>

                    <td className={"no-padding align-top"}>
                        <DailyFullReportExpences
                            formValues={this.state.formValues}
                            setFormValues={
                                (fv: Map<string, any>) => {
                                    this.setState({formValues: fv})
                                }
                            }
                            exp_types={this.state.cash_exp_types}
                            exp_type={"cash"}
                        />
                    </td>

                    {/*<td className={"no-padding align-top"}>*/}
                    {/*    <table className={"table table-sm table-bordered "}>*/}
                    {/*        <tr className={"text-center"}>*/}
                    {/*            <td className={"w-480px"}><strong>Описание</strong></td>*/}
                    {/*            <td className={"w-120px"}><strong>Сума(лв)</strong></td>*/}
                    {/*        </tr>*/}
                    {/*        {*/}
                    {/*            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(*/}
                    {/*                idx => <tr key={"cash_exp_" + idx}>*/}
                    {/*                    <td className={"no-padding w-480px"}>*/}
                    {/*                        <Row>*/}
                    {/*                            <Col>*/}
                    {/*                                <input*/}
                    {/*                                    className={"form-control form-control-sm description w-100"}*/}
                    {/*                                    type={"text"}*/}
                    {/*                                    name={"cash_expense_description_" + idx}*/}
                    {/*                                    key={"cash_expense_description_" + idx}*/}
                    {/*                                    onChange={this.handleFormChange}*/}
                    {/*                                    value={this.state.formValues.get("cash_expense_description_" + idx)}*/}
                    {/*                                />*/}
                    {/*                            </Col>*/}
                    {/*                            <Col sm={"auto"} className={"ps-0 ms-0"}>*/}
                    {/*                                <DropdownButton onSelect={event => {*/}
                    {/*                                    const fv = new Map(this.state.formValues);*/}
                    {/*                                    fv.set("cash_expense_description_" + idx, event);*/}
                    {/*                                    this.setState({formValues: fv})*/}
                    {/*                                }} size={"sm"} title={""}*/}
                    {/*                                >*/}
                    {/*                                    {*/}
                    {/*                                        CASH_EXPENSES.filter(i => i.isActive).map(*/}
                    {/*                                            i =>*/}
                    {/*                                                <Dropdown.Item eventKey={i.name}*/}
                    {/*                                                               key={i.name}>{i.name}</Dropdown.Item>*/}
                    {/*                                        )*/}
                    {/*                                    }*/}


                    {/*                                </DropdownButton>*/}
                    {/*                            </Col>*/}
                    {/*                        </Row>*/}
                    {/*                    </td>*/}
                    {/*                    <td className={"no-padding"}>*/}
                    {/*                        <input*/}
                    {/*                            className={"text-end form-control form-control-sm"} type={"text"}*/}
                    {/*                            name={"cash_expense_amount_" + idx}*/}
                    {/*                            key={"cash_expense_amount_" + idx} onChange={this.handleFormChange}*/}
                    {/*                            value={zeroToEmptyString(this.state.formValues.get("cash_expense_amount_" + idx))}*/}
                    {/*                        />*/}
                    {/*                    </td>*/}
                    {/*                </tr>*/}
                    {/*            )*/}
                    {/*        }*/}
                    {/*        <tr>*/}
                    {/*            <td className={"no-padding w-480px"}>*/}
                    {/*                <Row>*/}
                    {/*                    <Col>*/}
                    {/*                        <input*/}
                    {/*                            className={"form-control form-control-sm description w-100"}*/}
                    {/*                            type={"text"} readOnly={true}*/}
                    {/*                            value={"РАЗХОД ЗА ПАЛЕТИ"}*/}
                    {/*                        />*/}
                    {/*                    </Col>*/}
                    {/*                    <Col sm={"auto"} className={"text-end pt-2"}>*/}
                    {/*                        Брой:*/}
                    {/*                    </Col>*/}
                    {/*                    <Col sm={2} className={"ps-0 ms-0 small"}>*/}
                    {/*                        <input*/}
                    {/*                            className={"text-end form-control form-control-sm"} type={"text"}*/}
                    {/*                            name={"cash_palet_qty"} onChange={this.handleFormChange}*/}
                    {/*                            value={zeroToEmptyString(this.state.formValues.get("cash_palet_qty"))}*/}
                    {/*                        />*/}
                    {/*                    </Col>*/}
                    {/*                </Row>*/}
                    {/*            </td>*/}
                    {/*            <td className={"no-padding"}>*/}
                    {/*                <input*/}
                    {/*                    className={"text-end form-control form-control-sm"} type={"text"}*/}
                    {/*                    name={"cash_palet_price"} onChange={this.handleFormChange}*/}
                    {/*                    value={zeroToEmptyString(this.state.formValues.get("cash_palet_price"))}*/}
                    {/*                />*/}
                    {/*            </td>*/}
                    {/*        </tr>*/}
                    {/*    </table>*/}
                    {/*</td>*/}
                    <td className={"no-padding align-top"}>
                        <div style={{width: "100%", height: "300px"}}>
                            <table className={"table table-sm table-bordered "}>
                                <tr className={"text-center"}>
                                    <td className={"w-480px"}><strong>Описание</strong></td>
                                    <td className={"w-120px"}><strong>Сума</strong></td>
                                </tr>
                                {
                                    this.state.report.cash.deposit.map(
                                        (item: any) => {
                                            return (
                                                <tr>
                                                    <td>{item.description}</td>
                                                    <td className={"text-end"}>{Number.parseFloat(item.amount).toFixed(2)} лв</td>
                                                </tr>
                                            )
                                        }
                                    )
                                }
                            </table>
                        </div>
                        <table className={"table table-sm table-bordered font-weight-bold "}>
                            <thead>
                            <tr>
                                <th colSpan={2} className={"text-center"}>ВСИЧКО</th>
                            </tr>
                            </thead>
                            <tr>
                                <td><strong>Начално салдо</strong></td>
                                <td className={"no-padding text-end"}>
                                    <input
                                        className={"form-control form-control-sm"} type={"text"} name={"cash_saldo"}
                                        style={{textAlign: "right"}}
                                        key={"cash_saldo"} onChange={this.handleFormChange}
                                        value={zeroToEmptyString(this.state.formValues.get("cash_saldo"))}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Всичко приходи</strong></td>
                                <td className={"text-end"}>
                                    <strong>{calculateTotalIncome(this.state.report.cash.deposit, this.state.formValues).toFixed(2)} лв</strong>
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Всичко разходи</strong></td>
                                <td className={"text-end"}>
                                    <strong>{calculateTotalExpenses(this.state.formValues).toFixed(2)} лв</strong></td>
                            </tr>
                            <tr>
                                <td><strong>Крайно салдо</strong></td>
                                <td className={"text-end"}>
                                    <strong>{calculateTotal(this.state.report.cash.deposit, this.state.formValues).toFixed(2)} лв</strong>
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td>
                    </td>
                </tr>
                </tbody>
            </table>
        );
    }

    RenderReport() {
        return (
            <form className={"form"}>
                <BlockTitleComponent title={'ОТЧЕТ'}/>
                <table className="table table-sm table-bordered table-no-padding w-100" style={{fontSize: "0.75rem"}}>
                    <tbody>
                    <tr>
                        <td>
                            {this.RenderBricks()}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {this.RenderConsumption()}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {this.RenderPeleti()}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {this.RenderOthers()}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {this.RenderCash()}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </form>
        )
    }

    showPrevDate() {
        const sd = moment(this.state.selectedDate);
        sd.subtract(1, 'day');
        this.setState({
            selectedDate: moment(sd),
            formValues: new Map<string, any>()
        });
        this.totals.clear();
        this.loadParameters(moment(sd));
    }

    showNextDate() {
        const sd = moment(this.state.selectedDate);
        sd.add(1, 'day');
        this.setState({
            selectedDate: moment(sd),
            formValues: new Map<string, any>()
        });
        this.totals.clear();
        this.loadParameters(moment(sd));
    }

    render() {
        return (
            <div className="container daily-full-report" style={{maxWidth: "100%"}}>

                <div className="card">

                    <div className="card-header text-center row">
                        <div className="col-2 text-start no-print">
                            {
                                (this.state.loading > 0) ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                        <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                            }

                        </div>
                        <h2 className="col">Дневен отчет за предприятието</h2>
                        <div className="col-3">
                            <h2 className={"print text-end"}>
                                {this.state.selectedDate.format('DD.MM.YYYY')}
                            </h2>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                {
                                    this.state.loading > 0 &&
                                    <div>Зареждам данните ...</div>
                                }
                                {
                                    this.state.loading === 0 &&
                                    <>
                                        <div className="card bg-light p-3 mb-3 no-print">
                                            <form className="form-inline">
                                                <div className={"lh-2em "}>
                                                    <div className={"col-auto"}>Изберете дата:&nbsp;</div>
                                                    <div className={"col-auto"}>
                                                        <ReadOnlyDatePickerComponent
                                                            value={this.state.selectedDate.format('DD.MM.YYYY')}
                                                            onChange={(date: Date) => {
                                                                this.setState({
                                                                    selectedDate: moment(date),
                                                                    formValues: new Map<string, any>()
                                                                });
                                                                this.totals.clear();
                                                                this.loadParameters(moment(date));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        {
                                            !this.state.working &&
                                            <>
                                                <DailyReportsPage key={this.state.selectedDate.date()}
                                                                  selectedDate={this.state.selectedDate}
                                                                  showOnlyData={true}/>


                                                <BlockTitleComponent title={'РАЗЧЕТИ С ХРИСТО'}/>
                                                <form className={"form w-50"}>
                                                    <DailyFullReportHristo
                                                        hristoBankOut={this.state.formValues.get('hristoBankOut') ?? 0}
                                                        hristoBankIn={this.state.formValues.get('hristoBankIn') ?? 0}
                                                        hristoCashOut={this.state.formValues.get('hristoCashOut') ?? 0}
                                                        hristoCashIn={this.state.formValues.get('hristoCashIn') ?? 0}
                                                        onChange={
                                                            (bankOut, bankIn, cashOut, cashIn) => {
                                                                const fv = new Map(this.state.formValues);
                                                                fv.set('hristoBankOut', bankOut);
                                                                fv.set('hristoBankIn', bankIn);
                                                                fv.set('hristoCashOut', cashOut);
                                                                fv.set('hristoCashIn', cashIn);
                                                                this.setState({formValues: fv});
                                                            }
                                                        }
                                                    />
                                                </form>
                                                {this.RenderReport()}
                                            </>
                                        }

                                    </>
                                }

                            </div>

                        </div>

                    </div>

                    <div className="card-footer">
                        {
                            this.state.working ?
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <Row>
                                    <Col xs={"auto"}>
                                        <button className="btn btn-primary" onClick={this.saveReport}>Запази отчета
                                        </button>
                                        &nbsp;
                                        <button className="btn btn-secondary" onClick={() => window.print()}>Печат на
                                            отчета
                                        </button>
                                    </Col>
                                    <Col xs={"auto"}>
                                    <span className={"float-end bold text-danger"}>
                                        <label style={{height: "38px", lineHeight: "38px"}}>
                                            <input type="checkbox" value={1} name={'is_locked'}
                                                   className={"footer-input"}
                                                   checked={this.state.formValues.get('is_locked')}
                                                   onChange={this.handleFormChange}/> Този отчет е заключен</label>
                                    </span>
                                    </Col>
                                    <Col>
                                        {
                                            this.state.formValues.get('is_locked') &&
                                            <input
                                                className={"form-control footer-input"} type={"text"}
                                                name={"description"}
                                                onChange={this.handleFormChange}
                                                value={this.state.formValues.get('description')}
                                                placeholder={"Въведете причината за заключване..."}
                                            />
                                        }
                                    </Col>
                                </Row>
                        }
                    </div>
                </div>

            </div>
        );
    }

}
