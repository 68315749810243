import {SHORT_MONTHS_NAMES} from "../../types";
import {zeroToEmptyStringAsCurrencyWithSpaces} from "../../common";
import {useEffect, useState} from "react";
import IOReportTableDetails from "./IOReportTableDetails";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderMinus, faFolderPlus} from "@fortawesome/free-solid-svg-icons";
import React from "react";

interface Props {
    data: any;
    selectedMonths: string[];
    showDetails?: boolean;
    skipTotal?: boolean;
    firstRowBold?: boolean;
}

const IOReportTable: React.FC<Props> = ({
                                            data,
                                            selectedMonths,
                                            showDetails,
                                            skipTotal,
                                            firstRowBold
                                        }) => {
    const [sortedMonths, setSortedMonths] = useState<string[]>([]);
    const [showDetailsFor, setShowDetailsFor] = useState<string | null>(null);

    useEffect(() => {
        const _sortedMonths: string[] = [];
        SHORT_MONTHS_NAMES.forEach(
            month => {
                if (selectedMonths.includes(month)) {
                    _sortedMonths.push(month);
                }
            }
        );
        setSortedMonths(_sortedMonths);
    }, [selectedMonths]);

    if (!data) return null;
    const keys = Object.keys(data);

    const totalRow = skipTotal ? null :
        <tr className={"bg-dark text-light fw-bold"}>
            <td>
                <strong>Общо</strong>
            </td>
            {
                sortedMonths.map((month: string) => {
                    let total = 0;
                    keys.forEach((key: string) => {
                        const monthIndex = SHORT_MONTHS_NAMES.indexOf(month);
                        total += data[key]['values'][monthIndex];
                    });
                    return (
                        <td key={month}
                            className={"text-end fw-bold text-nowrap"}>{zeroToEmptyStringAsCurrencyWithSpaces(total.toString())}</td>
                    )
                })
            }
            <td className={"text-end fw-bold text-nowrap"}>
                {zeroToEmptyStringAsCurrencyWithSpaces(
                    keys.reduce((acc: number, key: string) => {
                        sortedMonths.forEach((month: string) => {
                            const monthIndex = SHORT_MONTHS_NAMES.indexOf(month);
                            acc += data[key]['values'][monthIndex];
                        });
                        return acc;
                    }, 0).toString()
                )}
            </td>
        </tr>;

    let fs = "16px";
    return (
        <table className="table table-bordered table-hover text-nowrap" style={{fontSize: fs}}>
            <thead>
            <tr>
                <th>Перо</th>
                {
                    sortedMonths.map((month: string) => {
                        return (
                            <th key={month} className={"text-center"}>{month}</th>
                        )
                    })
                }
                <th className={"text-end"}>ОБЩО</th>
            </tr>
            </thead>
            <tbody>
            {totalRow}
            {
                keys.map((key: string, idx) => {
                    let total = 0;
                    return (
                        <React.Fragment key={key}>
                            <tr className={(firstRowBold && idx === 0) ? "bg-dark text-light fw-bold" : "" }>
                                <td>
                                    {
                                        showDetails &&
                                        <button className={"btn btn-link"}
                                                onClick={() => setShowDetailsFor(showDetailsFor === key ? null : key)}>
                                            {showDetailsFor === key ? <FontAwesomeIcon icon={faFolderMinus}/> :
                                                <FontAwesomeIcon icon={faFolderPlus}/>}
                                        </button>
                                    }
                                    {data[key]['title']}
                                </td>
                                {
                                    sortedMonths.map((month: string) => {
                                        const monthIndex = SHORT_MONTHS_NAMES.indexOf(month);
                                        total += data[key]['values'][monthIndex];
                                        return (
                                            <td key={`${key}-${month}`}
                                                className={"text-end"}>{zeroToEmptyStringAsCurrencyWithSpaces(data[key]['values'][monthIndex])}</td>
                                        )
                                    })
                                }
                                <td className={"text-end fw-bold"}>{zeroToEmptyStringAsCurrencyWithSpaces(total.toString())}</td>
                            </tr>
                            {
                                showDetails &&
                                <IOReportTableDetails
                                    data={data[key]['items']}
                                    selectedMonths={selectedMonths}
                                    showDetails={showDetailsFor === key}
                                />
                            }
                        </React.Fragment>
                    )
                })
            }
            </tbody>
        </table>
    )
}

export default IOReportTable;
