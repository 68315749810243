import React from "react";
import {$enum} from "ts-enum-util";
import {API_CALL, City, Client, PARAMETERS, PropertyValue, REPAIR_TYPE, Sale} from "../../types";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {ReportsPageProps} from "../../pages/ReportsPage";
import {API} from "../../api";
import {
    isError,
    isLoading,
    isSuccess,
    minutesToHours,
    showErrorsInToast, translateError,
    zeroToEmptyStringAsCurrency
} from "../../common";
import moment, {Moment} from "moment";
import {Dropdown, InputGroup} from "react-bootstrap";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {SelectClient} from "../clients/SelectClient";
import {SaleDetailsModalComponent} from "../sales/SaleDetailsModalComponent";
import {NewSaleDetailsModalComponent} from "../sales/NewSaleDetailsModalComponent";


interface PeletsSalesReportPageState {
    loading: boolean;
    working: boolean;
    periodFrom: string;
    periodTo: string;
    data: any;
    editSale: Sale | null,
    bricks: PropertyValue[],
    pelet_types: PropertyValue[];
    bag_types: PropertyValue[];
}

export interface PeletsSalesReportPageProps extends ReportsPageProps {
    client: Client | null
}

export class PeletsSalesReportPage extends React.Component<PeletsSalesReportPageProps, PeletsSalesReportPageState> {

    constructor(props: PeletsSalesReportPageProps) {
        super(props);

        this.state = {
            periodFrom: (props.periodFrom || moment().format('DD.MM.YYYY')),
            periodTo: (props.periodTo || moment().format('DD.MM.YYYY')),
            loading: false,
            working: false,
            data: null,
            editSale: null,
            bricks: [],
            pelet_types: [],
            bag_types: [],
        }

        this.loadData = this.loadData.bind(this);
        this.loadSale = this.loadSale.bind(this);
        this.processData = this.processData.bind(this);
    }

    async componentDidMount() {
        this.loadParams();
        this.loadData();
    }

    loadParams() {
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    const bricks: PropertyValue[] = apiCall.data.data.items?.values;
                    bricks.push({
                        id: 999,
                        value: 'ТУХЛА ВТОРО КАЧЕСТВО',
                        description: {
                            shortName: 'II качество'
                        }
                    });
                    this.setState({
                        bricks: bricks
                    });
                }
            }, PARAMETERS.BRICKS
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        pelet_types: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.PELET_TYPE
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        bag_types: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.BAG_TYPE
        )
    }

    loadData() {
        API.calcPeletsSalesReport(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    this.processData(apiCall.data.data.report);
                }
            }, {
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo,
            }
        )
    }

    processData(data: any) {
        this.setState({data: data.items});
    }

    componentDidUpdate(prevProps: Readonly<PeletsSalesReportPageProps>, prevState: Readonly<PeletsSalesReportPageState>, snapshot?: any) {
        if (
            (this.state.periodFrom !== this.props.periodFrom) ||
            (this.state.periodTo !== this.props.periodTo)
        ) {
            this.setState({
                periodFrom: (this.props.periodFrom || moment().format('DD.MM.YYYY')),
                periodTo: (this.props.periodTo || moment().format('DD.MM.YYYY')),
            });
            this.loadData();
        } else if (prevProps.client?.name !== this.props.client?.name) {
            this.loadData();
        }
    }

    loadSale(id: number) {
        API.getExpeditionNoteDetails(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});
                if (isSuccess(apiCall)) {
                    const payments = apiCall.data.data.sale.payments || [];
                    const sale = {...apiCall.data.data.sale, payments: payments} as Sale;
                    this.setState({working: false, editSale: sale});
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Възникна грешка', translateError);
                }
            }, id
        );
    }

    renderReport(data: any) {
        let ftotalKg = 0;
        let fBankTotalKg = 0;
        let fCashTotalKg = 0;
        let fBankTotalLogistics = 0;
        let fCashTotalLogistics = 0;
        let ftotalLogistics = 0;
        let fBankTotalPrice = 0;
        let fCashTotalPrice = 0;
        let ftotalPrice = 0;
        let totalKg = 0;
        let totalLogistics = 0;
        let totalPrice = 0;

        return (
            <>
                <BlockTitleComponent title={data.pelet}/>
                {
                    data.sales
                        .filter((sale: any) => !this.props.client || (this.props.client?.name === sale.client))
                        .length == 0 ?
                        <div><strong>Няма продажби за този период</strong></div>
                        :
                        <table className={"table table-sm"}>
                            <thead>
                            <tr>
                                <th>Дата</th>
                                <th>ЕБ #</th>
                                <th>Фирма</th>
                                <th>Тип</th>
                                <th>Вид</th>
                                <th className={""}>M/E</th>
                                <th className={"text-end"}>Брой</th>
                                <th className={"text-end"}>КГ</th>
                                <th className={"text-end"}>Общо КГ</th>
                                <th className={"text-end"}>Транспорт (лв)</th>
                                <th className={"text-end"}>Сума (лв)</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                data.sales
                                    .filter((sale: any) => !this.props.client || (this.props.client?.name === sale.client))
                                    .map((sale: any) => {

                                            sale.bagQty = sale.bagQty * 1;
                                            sale.kg = sale.kg * 1;
                                            sale.logistics = sale.logistics * 1;
                                            sale.price = sale.price * 1;
                                            sale.totalKg = sale.bagQty * sale.kg;

                                            totalKg += sale.totalKg;
                                            totalLogistics += sale.logistics;
                                            totalPrice += sale.price;

                                            if (sale.saleType === "peleti_w_env") {
                                                ftotalKg += sale.totalKg;
                                                ftotalLogistics += sale.logistics;
                                                ftotalPrice += sale.price;
                                                if(sale.payType == "BANKA") {
                                                    fBankTotalPrice += sale.price;
                                                    fBankTotalKg += sale.totalKg;
                                                    fBankTotalLogistics += sale.logistics;
                                                } else {
                                                    fCashTotalPrice += sale.price;
                                                    fCashTotalLogistics += sale.logistics;
                                                    fCashTotalKg += sale.totalKg;
                                                }
                                            }

                                            return <tr className={sale.isPayed ? "" : "bg-light text-danger"}>
                                                <td>{sale.date}</td>
                                                <td>
                                                    <a href="" onClick={(e) => {
                                                        e.preventDefault();
                                                        this.loadSale(sale.sale_id);
                                                    }}>{sale.sale_number}</a>
                                                </td>
                                                <td>{sale.client}</td>
                                                <td>{sale.payType === "BANKA" ? 'Банка' : 'Кеш'}</td>
                                                <td>{sale.saleType === "peleti_w_env" ? 'Ф' : 'КБ'}</td>
                                                <td className={""}>{sale.bagType.value}</td>
                                                <td className={"text-end"}>{sale.bagQty}</td>
                                                <td className={"text-end"}>{sale.kg}</td>
                                                <td className={"text-end"}>{sale.totalKg}</td>
                                                <td className={"text-end"}>{zeroToEmptyStringAsCurrency(sale.logistics)}</td>
                                                <td className={"text-end"}>{zeroToEmptyStringAsCurrency(sale.price)}</td>
                                            </tr>
                                        }
                                    )
                            }

                            <tr className={"bg-secondary-light font-weight-bold"}
                                style={{borderTop: "solid 3px"}}>
                                <td colSpan={8} className={"text-end"}>Всичко с фактура по банка:</td>
                                <td className={"text-end"}>{fBankTotalKg}</td>
                                <td className={"text-end"}>{zeroToEmptyStringAsCurrency(fBankTotalLogistics.toString())}</td>
                                <td className={"text-end"}>{zeroToEmptyStringAsCurrency(fBankTotalPrice.toString())}</td>
                            </tr>

                            <tr className={"bg-secondary-light font-weight-bold"}>
                                <td colSpan={8} className={"text-end"}>Всичко с фактура кеш:</td>
                                <td className={"text-end"}>{fCashTotalKg}</td>
                                <td className={"text-end"}>{zeroToEmptyStringAsCurrency(fCashTotalLogistics.toString())}</td>
                                <td className={"text-end"}>{zeroToEmptyStringAsCurrency(fCashTotalPrice.toString())}</td>
                            </tr>

                            <tr className={"bg-success text-light font-weight-bold"}
                                style={{borderTop: "solid 3px"}}>
                                <td colSpan={8} className={"text-end"}>Всичко с фактура:</td>
                                <td className={"text-end"}>{ftotalKg}</td>
                                <td className={"text-end"}>{zeroToEmptyStringAsCurrency(ftotalLogistics.toString())}</td>
                                <td className={"text-end"}>{zeroToEmptyStringAsCurrency(ftotalPrice.toString())}</td>
                            </tr>

                            <tr className={"bg-success text-light font-weight-bold"}>
                                <td colSpan={8} className={"text-end"}>Всичко с касов бон:</td>
                                <td className={"text-end"}>{totalKg - ftotalKg}</td>
                                <td className={"text-end"}>{zeroToEmptyStringAsCurrency((totalLogistics - ftotalLogistics).toString())}</td>
                                <td className={"text-end"}>{zeroToEmptyStringAsCurrency((totalPrice - ftotalPrice).toString())}</td>
                            </tr>
                            <tr className={"bg-info text-light font-weight-bold"}>
                                <td colSpan={8} className={"text-end"}>Всичко:</td>
                                <td className={"text-end"}>{totalKg}</td>
                                <td className={"text-end"}>{zeroToEmptyStringAsCurrency(totalLogistics.toString())}</td>
                                <td className={"text-end"}>{zeroToEmptyStringAsCurrency(totalPrice.toString())}</td>
                            </tr>
                            </tbody>
                        </table>
                }
                <div>&nbsp;</div>
            </>
        )
    }

    renderReports() {
        return (
            <div style={{opacity: (this.state.working ? 0.5 : 1)}}>
                {
                    this.state.data.map((brick: any) => this.renderReport(brick))
                }
            </div>
        )
    }

    afterSaved() {
        this.loadData();
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {
                            this.state.loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                    </div>
                </div>

                {
                    !this.state.loading && this.state.data && this.renderReports()
                }

                {
                    this.state.editSale &&
                    (
                        this.state.editSale.recordVersion === "v1" ?
                            <SaleDetailsModalComponent
                                sale={this.state.editSale}
                                onAfterSave={this.afterSaved.bind(this)}
                                onCancel={() => {
                                    this.setState({editSale: null})
                                }}
                                brick_types={this.state.bricks}
                                pelet_types={this.state.pelet_types}
                                bag_types={this.state.bag_types}
                            /> :
                            <NewSaleDetailsModalComponent
                                sale={this.state.editSale}
                                onAfterSave={this.afterSaved.bind(this)}
                                onCancel={() => {
                                    this.setState({editSale: null})
                                }}
                                brick_types={this.state.bricks}
                                pelet_types={this.state.pelet_types}
                                bag_types={this.state.bag_types}
                            />
                    )
                }

            </>
        );
    }
}
