import {Button, Modal, Tab, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {
    AccountOperation,
    API_CALL,
    Client,
    OPERATION_TYPE,
    PARAMETERS,
    PAY_TYPE,
    PropertyValue,
    Sale
} from "../../types";
import {EditClientAccountDetails} from "./EditClientAccountDetails";
import {EditClientPaletDetails} from "./EditClientPaletDetails";
import {EditAccountOperation} from "./EditAccountOperation";
import {API} from "../../api";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../common";
import moment from "moment";
import {EditClientAccountOperation} from "./EditClientAccountOperation";
import EditClientPricesDetails from "./EditClientPricesDetails";
import {EditClientLogisticDetails} from "./EditClientLogisticDetails";
import {SaleDetailsModalComponent} from "../sales/SaleDetailsModalComponent";
import {EditClientNotPaid} from "./EditClientNotPaid";
import {NewSaleDetailsModalComponent} from "../sales/NewSaleDetailsModalComponent";

export interface EditClientAccountModalProps {
    client: Client;
    onReload?: any;
    onClose?: any;
    modalStyle?: any;
}


export function EditClientAccountModal(props: EditClientAccountModalProps) {
    const [rerenderKey, setRerenderKey] = useState(0);
    const [loading, setLoading] = useState(false);
    const [viewMode, setViewMode] = useState<PAY_TYPE>(PAY_TYPE.BANKA);
    const [operations, setOperations] = useState<AccountOperation[]>([]);
    const [operation, setOperation] = useState<AccountOperation | null>(null);
    const [isNew, setIsNew] = useState(false);
    const [balanceBank, setBalanceBank] = useState<number>(0);
    const [balanceCash, setBalanceCash] = useState<number>(0);
    const [balanceBankAvans, setBalanceBankAvans] = useState<number>(0);
    const [balanceCashAvans, setBalanceCashAvans] = useState<number>(0);
    const [balanceCredit, setBalanceCredit] = useState<number>(0);

    const [bricks, setBricks] = useState<PropertyValue[]>([]);
    const [peletTypes, setPeletTypes] = useState<PropertyValue[]>([]);
    const [bagTypes, setBagTypes] = useState<PropertyValue[]>([]);
    const [editSale, setEditSale] = useState<Sale | null>(null);

    const loadParams = function () {
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    const bricks: PropertyValue[] = apiCall.data.data.items?.values;
                    bricks.push({
                        id: 999,
                        value: 'ТУХЛА ВТОРО КАЧЕСТВО',
                        description: {
                            shortName: 'II качество'
                        }
                    });
                    setBricks(bricks);
                }
            }, PARAMETERS.BRICKS
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    setPeletTypes(apiCall.data.data.items?.values);
                }
            }, PARAMETERS.PELET_TYPE
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    setBagTypes(apiCall.data.data.items?.values);
                }
            }, PARAMETERS.BAG_TYPE
        )
    }

    useEffect(() => {
        loadParams();
        loadData();
    }, []);


    const loadData = function () {
        setOperation(null);
        setEditSale(null);

        API.getClientAccounts(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Грешка');
                }
                if (isSuccess(apiCall)) {
                    setOperations(apiCall.data.data.operations);
                    setBalanceBank(apiCall.data.data.balance.bank);
                    setBalanceCash(apiCall.data.data.balance.cash);
                    setBalanceBankAvans(apiCall.data.data.balance['bank_avans']);
                    setBalanceCashAvans(apiCall.data.data.balance['cash_avans']);
                    setBalanceCredit(apiCall.data.data.balance.credit);
                }
            }, props.client.id
        );

    }

    const editOperation = (item: AccountOperation) => {
        // debugger
        setOperation(item);
        setIsNew(false);
    }

    const removeOperation = (item: AccountOperation) => {
        // debugger
        API.deleteClientOperation(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    setOperation(null);
                    loadData();
                }
            }, item.id
        )
    }

    const addOperation = () => {
        // debugger
        setOperation({
            id: -1,
            operation: OPERATION_TYPE.DEPOSIT,
            account_type: viewMode,
            amount: 0,
            timestamp: moment().format('DD.MM.YYYY'),
        } as AccountOperation);
        setIsNew(true);
    }


    const loadSale = function (id: number) {
        API.getExpeditionNoteDetails(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    const payments = apiCall.data.data.sale.payments || [];
                    const sale = {...apiCall.data.data.sale, payments: payments} as Sale;
                    setEditSale(sale);
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Възникна грешка', translateError);
                }
            }, id
        );
    }

    function recalculateSalesForClientId(id: number) {
        API.recalculateSalesForClientId(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    loadData();
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Грешка', translateError);
                }
            }, id
        )
    }

    return (
        <>
            <Modal
                size={"lg"}
                show={!editSale}
                centered
                onHide={props.onClose}
                style={{...props.modalStyle, backgroundColor: 'rgba(0,0,0,0.5)'}}
            >
                <Modal.Header closeButton>
                    <div className={"row"}>
                        <div className="col">
                            <Modal.Title>Баланс на клиент<br/>{props.client.name}
                            </Modal.Title>
                        </div>
                        {
                            // API.user?.name === 'DEV' &&
                            // <div className="col">
                            //     <Button onClick={() => recalculateSalesForClientId(props.client.id)}
                            //             variant={"primary"}>Преразчет на продажби</Button>
                            // </div>
                        }
                    </div>
                </Modal.Header>

                <Modal.Body style={{minHeight: "60vh"}}>
                    <div>
                        <Tabs
                            variant={"tabs"}
                            activeKey={viewMode}
                            onSelect={(k: any) => setViewMode(k)}
                        >
                            <Tab eventKey={PAY_TYPE.BANKA} title="Банка">
                                {
                                    loading ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :

                                        <EditClientAccountDetails
                                            operations={operations.filter((item: AccountOperation) => item.account_type === 'BANKA' && item.is_avans === 0)}
                                            balance={balanceBank}
                                            onEditItem={(item) => editOperation(item)}
                                            onRemoveItem={(item) => removeOperation(item)}
                                            onAddItem={() => addOperation()}
                                            onEditSale={(saleId: number) => loadSale(saleId)}
                                        />
                                }
                            </Tab>
                            <Tab eventKey={PAY_TYPE.CASH} title="Кеш">
                                <EditClientAccountDetails
                                    operations={operations.filter((item: AccountOperation) => item.account_type === 'CASH' && item.is_avans === 0)}
                                    balance={balanceCash}
                                    onEditItem={(item) => editOperation(item)}
                                    onRemoveItem={(item) => removeOperation(item)}
                                    onAddItem={() => addOperation()}
                                    onEditSale={(saleId: number) => loadSale(saleId)}
                                />
                            </Tab>
                            <Tab eventKey={PAY_TYPE.AVANS_BANKA} title="Аванс банка">
                                <EditClientAccountDetails
                                    operations={operations.filter((item: AccountOperation) => item.account_type === 'BANKA' && item.is_avans === 1)}
                                    balance={balanceBankAvans}
                                    onEditItem={(item) => editOperation(item)}
                                    onRemoveItem={(item) => removeOperation(item)}
                                    onAddItem={() => addOperation()}
                                    onEditSale={(saleId: number) => loadSale(saleId)}
                                />
                            </Tab>
                            <Tab eventKey={PAY_TYPE.AVANS_CASH} title="Аванс кеш">
                                <EditClientAccountDetails
                                    operations={operations.filter((item: AccountOperation) => item.account_type === 'CASH' && item.is_avans === 1)}
                                    balance={balanceCashAvans}
                                    onEditItem={(item) => editOperation(item)}
                                    onRemoveItem={(item) => removeOperation(item)}
                                    onAddItem={() => addOperation()}
                                    onEditSale={(saleId: number) => loadSale(saleId)}
                                />
                            </Tab>
                            <Tab eventKey={PAY_TYPE.CREDIT} title="Аванс кредитно">
                                <EditClientAccountDetails
                                    operations={operations.filter((item: AccountOperation) => item.account_type === viewMode)}
                                    balance={balanceCredit}
                                    onEditItem={(item) => editOperation(item)}
                                    onRemoveItem={(item) => removeOperation(item)}
                                    onAddItem={() => addOperation()}
                                    onEditSale={(saleId: number) => loadSale(saleId)}
                                />
                            </Tab>
                            <Tab eventKey={PAY_TYPE.LOGISTIC} title="Транспорт">
                                <EditClientLogisticDetails
                                    client={props.client}
                                    onCancel={props.onClose}
                                    onRefresh={props.onReload}
                                    onEditSale={(saleId: number) => loadSale(saleId)}
                                />
                            </Tab>
                            <Tab eventKey={PAY_TYPE.PALET} title="Палети">
                                <EditClientPaletDetails
                                    rerenderKey={rerenderKey}
                                    client={props.client}
                                    onCancel={props.onClose}
                                    onRefresh={props.onReload}
                                    onEditSale={(saleId: number) => loadSale(saleId)}
                                />
                            </Tab>
                            {
                                API.userHasModul(29) &&
                                <Tab eventKey={PAY_TYPE.PRICELIST} title="Цени">
                                    <EditClientPricesDetails
                                        client={props.client}
                                        onCancel={props.onClose}
                                        onRefresh={props.onReload}
                                    />
                                </Tab>
                            }
                        </Tabs>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type={"button"} className={"btn btn-primary"} onClick={props.onClose}>Затвори</button>
                </Modal.Footer>
            </Modal>

            {
                operation &&
                <EditClientAccountOperation
                    editOperation={operation}
                    onSaved={() => {
                        loadData();
                        setRerenderKey(rerenderKey + 1);
                        if (props.onReload) props.onReload();
                    }}
                    client={props.client}
                    onCanceled={() => setOperation(null)}
                    pay_mode={viewMode}
                    isNew={isNew}
                />
            }

            {
                editSale && (
                    editSale.recordVersion === "v1" ?
                        <SaleDetailsModalComponent
                            sale={editSale}
                            onAfterSave={() => loadData()}
                            onCancel={() => setEditSale(null)}
                            brick_types={bricks}
                            pelet_types={peletTypes}
                            bag_types={bagTypes}
                            zIndex={10021}
                        /> :
                        <NewSaleDetailsModalComponent
                            sale={editSale}
                            onAfterSave={() => loadData()}
                            onCancel={() => setEditSale(null)}
                            brick_types={bricks}
                            pelet_types={peletTypes}
                            bag_types={bagTypes}
                            zIndex={10021}
                        />
                )
            }

        </>
    )
}
