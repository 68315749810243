import React, {FC, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {PATHS} from "../../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {API} from "../../api";
import {Tab, Tabs} from "react-bootstrap";
import BudgetsReference from "./BudgetsReference";
import BudgetsPlan from "./BudgetsPlan";

interface Props {

}

const BudgetsPage: FC<Props> = (props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<string>("reference");

    return (
        <div className="container" style={{maxWidth: "100%", minHeight: "50%"}}>

            <div className="card">
                <div className="card-header text-center row">
                    <div className="col-2 text-start no-print">
                        {
                            loading ?
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                    <span className="btn btn-secondary"><FontAwesomeIcon
                                        icon={faArrowLeft}/></span></Link>
                        }

                    </div>
                    <h2 className="col">Бюджетиране</h2>
                    <div className="col-3">
                    </div>
                </div>

                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            {
                                loading ?
                                    <div>Зареждам данните ...</div>
                                    :
                                    <div className="card bg-light p-3 mb-3 no-print">
                                        <Tabs
                                            activeKey={selectedTab}
                                            onSelect={(k) => setSelectedTab(k as string)}
                                        >
                                            <Tab title={"Справка"} eventKey={"reference"}/>
                                            <Tab title={"План"} eventKey={"plan"}/>
                                        </Tabs>

                                        {
                                            selectedTab === "reference" ?
                                                <BudgetsReference/>
                                                :
                                                <BudgetsPlan/>
                                        }
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BudgetsPage;
