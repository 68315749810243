import React, {FC} from "react";
import {Table} from "react-bootstrap";
import {BudgetsReport, BudgetsReportItemType} from "./BudgetsTypes";
import {zeroToEmptyStringAsCurrencyWithSpaces} from "../../common";
import BudgetsPlanTableValuesRow from "./BudgetsPlanTableValuesRow";

interface BudgetsPlanTableProps {
    budgets: BudgetsReport;
    viewMode: "CASH" | "BANK";
    year: number;
}

const BudgetsPlanTable: FC<BudgetsPlanTableProps> = ({budgets, viewMode, year}) => {
    const expenses = viewMode === "CASH" ? budgets.expensesCash : budgets.expensesBank;
    const values = viewMode === "CASH" ? budgets.budgetsCash : budgets.budgetsBank;

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Група разходи</th>
                <th>Януари</th>
                <th>Февруари</th>
                <th>Март</th>
                <th>Април</th>
                <th>Май</th>
                <th>Юни</th>
                <th>Юли</th>
                <th>Август</th>
                <th>Септември</th>
                <th>Октомври</th>
                <th>Ноември</th>
                <th>Декември</th>
            </tr>
            </thead>
            <tbody>
            {
                expenses.map((e, index) =>
                    <tr key={index}>
                        <td className={"text-nowrap fa-sm"}>{e.name}</td>
                        <BudgetsPlanTableValuesRow
                            expense_type={viewMode}
                            year={year}
                            budgets={values[e.id] as unknown as number[]}
                            expenseId={e.id.toString()}
                        />
                    </tr>
                )
            }
            </tbody>
        </Table>
    );
};

export default BudgetsPlanTable;
