import {
    isError, isLoading, isSuccess,
    showErrorsInToast,
    stringToCurrency,
    translateError,
    zeroToEmptyStringAsCurrencyWithSpaces
} from "../../common";
import {FC, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {BudgetsReportItemType, BudgetType} from "./BudgetsTypes";
import {API} from "../../api";
import { API_CALL } from "../../types";

interface BudgetsPlanTableValuesRowProps {
    budgets: number[];
    expenseId: string;
    year: number;
    expense_type: "CASH" | "BANK";
}

const BudgetsPlanTableValuesRow: FC<BudgetsPlanTableValuesRowProps> = ({budgets, expenseId, year, expense_type}) => {
    const [value, setValue] = useState<string[]>(["", "", "", "", "", "", "", "", "", "", "", ""]);
    const [valueChanged, setValueChanged] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    useEffect(() => {
        const values = [];
        for (let i = 0; i < 12; i++) {
            values.push(zeroToEmptyStringAsCurrencyWithSpaces(budgets[i]?.toString() ?? ""));
        }
        setValue(values);
    }, [budgets]);

    function saveValues(expense_type: BudgetType, expenseId: string, year: number, month: number, value: number) {
        const valueToSave = value;
        if(!valueChanged) return;
        API.setBudgetValue(
            (apiCall: API_CALL) => {
                setSaving(isLoading(apiCall));
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Възникна грешка", translateError);
                }
                if (isSuccess(apiCall)) {
                    setValueChanged(false);
                }
            },
            expense_type,
            expenseId,
            year,
            month,
            valueToSave
        )
    }

    return (
        <>
            {
                value.map((b, index) =>
                    <td key={index} className={"p-0 m-0"} >
                        <Form.Control
                            size={"sm"}
                            type={"text"}
                            readOnly={saving}
                            value={b}
                            onChange={(e) => {
                                setValueChanged(true);
                                setValue(value.map((v, i) => i === index ? e.target.value : v));
                            }}
                            onBlur={(e) => {
                                const valueAsNumber = stringToCurrency(e.target.value) ?? 0;
                                setValue(value.map((v, i) => i === index ? zeroToEmptyStringAsCurrencyWithSpaces(valueAsNumber.toString()) : v));
                                saveValues(expense_type, expenseId, year, index, valueAsNumber);
                            }}
                        />
                    </td>
                )
            }
        </>
    );
};

export default BudgetsPlanTableValuesRow;
