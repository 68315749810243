import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {SHORT_MONTHS_NAMES} from "../../types";


interface Props {
    data: any,
    year: number,
}

const IORefChart = ({data, year}: Props) => {
    const options = {
        chart: {
            type: 'column',
            height: '350px',
            zoom: 'x',
        },
        title: {
            align: 'left',
            useHTML: true,
            text: 'ПРИХОДИ И РАЗХОДИ',
            margin: 40,
        },
        subtitle: {
            floating: true,
            useHTML: true,
            align: 'left',
            text: 'Приходи и разходи по месеци за ' + year + ' година'
        },
        colors: ['#2f7ed8', '#f50000', '#f2f200'],
        tooltip: {
            enabled: true,
        },
        xAxis: {
            categories: SHORT_MONTHS_NAMES,
        },
        yAxis: {
            min: 0,
            title: {
                text: 'лв',
            },
        },
        credits: {
            enabled: false
        },
        series: [
            {
                name: 'Приходи',
                data: data?.income?.map((x: number) => Math.round(x)),

            }, {
                name: 'Разходи',
                data: data?.outcome?.map((x: number) => Math.round(x))
            }
        ],
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false,
                }
            }
        },
    }

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                allowChartUpdate
            />
        </>
    );

}

export default IORefChart;


// export class BricksProducedChart extends React.Component<BricksProducedChartProps, BricksProducedChartState> {
//     options = {
//         chart: {
//             type: 'column',
//             height: '300px',
//             zoom: 'x',
//         },
//         title: {
//             align: 'left',
//             useHTML: true,
//             text: '',
//         },
//         subtitle: {
//             floating: true,
//             useHTML: true,
//             align: 'left',
//             text: ''
//         },
//         colors: ['#2f7ed8', '#f50000', '#f2f200'],
//         tooltip: {
//             enabled: true,
//         },
//         xAxis: {
//             categories: ['Тухла'],
//             title: {
//                 text: null
//             }
//         },
//         yAxis: {
//             min: 0,
//             title: {
//                 text: 'Брой',
//                 align: 'high'
//             },
//             labels: {
//                 overflow: 'justify'
//             },
//         },
//         plotOptions: {
//             series: {
//                 dataLabels: {
//                     enabled: true,
//                     // align: 'left',
//                     border: true,
//                     borderWidth: 1,
//                     backgroundColor: 'rgba(252, 255, 197, 0.75)',
//                     color: 0,
//                     shadow: true,
//                     verticalAlign: 'end',
//                     // useHTML: true,
//                     // x: 10,
//                     y: +250,
//                     formatter: function (): any {
//                         // @ts-ignore
//                         return this.point.y > 0 ? ' ' + this.point.y + ' ' : null;
//                     }
//                 }
//             }
//         },
//         legend: {
//             align: 'right',
//             verticalAlign: 'top',
//             borderWidth: 1,
//             backgroundColor: '#FFFFFF',
//             shadow: true,
//             enabled: false,
//         },
//         credits: {
//             enabled: false
//         },
//         series: [{
//             name: 'Производство',
//             data: [0]
//         }]
//     }
//
//
//     constructor(props: BricksProducedChartProps) {
//         super(props);
//         this.state = {
//             periodFrom: props.periodFrom,
//             periodTo: props.periodTo,
//             shiftId: props.shiftId,
//             options: {},
//             periodTotal: 0,
//             daysCount: 1,
//         }
//
//         this.processData = this.processData.bind(this);
//     }
//
//     componentDidMount() {
//         this.processData(this.props.data);
//     }
//
//     processData(data: any) {
//         const fromPeriod = moment(this.props.periodFrom, 'DD.MM.YYYY');
//         const toPeriod = moment(this.props.periodTo, 'DD.MM.YYYY');
//         const dates: string[] = [];
//         let periodTotal = 0;
//
//         toPeriod.add(1, 'days');
//
//         while (fromPeriod.isBefore(toPeriod)) {
//             dates.push(fromPeriod.format('DD.MM.YYYY'));
//             fromPeriod.add(1, 'days');
//         }
//
//         const chartData: number[] = [];
//         dates.forEach(d => {
//                 const _d: number = data[d] || 0;
//                 chartData.push(_d);
//                 periodTotal += _d;
//             }
//         );
//
//         const _o = {
//             ...this.options,
//             xAxis: {
//                 categories: dates
//             },
//             yAxis: {
//                 plotLines: [{value: periodTotal / (chartData.length), width: 2, color: 'rgba(0, 0, 0)',
//                     label: {useHTML: true, text: 'Средно на ден'  , align: 'right'}}]
//             },
//             series: [{name: this.props.title, data: chartData}]
//         }
//
//         this.setState({options: _o, daysCount: dates.length, periodTotal: periodTotal});
//     }
//
//     componentDidUpdate(prevProps: Readonly<BricksProducedChartProps>, prevState: Readonly<BricksProducedChartState>, snapshot?: any) {
//         if (
//             (this.state.periodFrom !== this.props.periodFrom) ||
//             (this.state.periodTo !== this.props.periodTo) ||
//             (this.state.shiftId !== this.props.shiftId)
//         ) {
//             this.setState({
//                 periodFrom: this.props.periodFrom,
//                 periodTo: this.props.periodTo,
//                 shiftId: this.props.shiftId
//             });
//             this.processData(this.props.data);
//         }
//     }
//
//     render() {
//         return (
//             <>
//                 <BlockTitleComponent title={this.props.title}/>
//                 <HighchartsReact
//                     highcharts={Highcharts}
//                     options={this.state.options}
//                     allowChartUpdate
//                 />
//                 <div>
//                     <table className={"table table-bordered"}>
//                         <tr>
//                             <td className={"w-240px"}>Средно на ден:</td>
//                             <td><strong>{numberWithCommas(Math.round(this.state.periodTotal / this.state.daysCount))} тухли</strong></td>
//                         </tr>
//                         <tr>
//                             <td className={"w-240px"}>Всичко за периода:</td>
//                             <td><strong>{numberWithCommas(this.state.periodTotal)} тухли</strong></td>
//                         </tr>
//                     </table>
//                 </div>
//             </>
//         );
//     }
//
// }
