import React from "react";
import {API_CALL, PARAMETERS, PropertyValue} from "../types";
import {
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    translateError,
    zeroToEmptyStringAsCurrency
} from "../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment, {Moment} from "moment";
import {API} from "../api";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft, faBuilding, faEye, faMapMarker, faMoneyBill, faPhone, faPlus, faTrafficLight, faTruck, faCopy} from "@fortawesome/free-solid-svg-icons";
import {ConfirmDeleteModal} from "../common/ConfirmDeleteModal";
import {PlanningDetailsModalComponent} from "../components/planning/PlanningDetailsModalComponent";
import {Dropdown} from "react-bootstrap";
import {ConfirmModal} from "../components/common/ConfirmModal";

export interface SummaryItem {
    week: number,
    fromDate: string,
    toDate: string,
    new: number,
    prepaired: number,
    completed: number,
}

export interface WeekDataItem {
    id: number,
    client: string,
    client_city: string,
    client_id: number,
    client_phone: string,
    currier: string,
    driver: string,
    driver_phone: string,
    license_plate: string,
    plan_date: string,
    request_data: any,
    status: string,
    logistic_price: string,
    logistic_pay_type: string,
    pay_type: string,
}


interface PlanningPageProps {
    selectedDate?: Moment;
}

interface PlanningPageState {
    viewMode: string,
    loading: boolean,
    bricks: PropertyValue[],
    activeTab: string,
    editMode: boolean,
    editId: number,
    copyId: number | undefined,
    editDate: string,
    plan: WeekDataItem[],
    summary: SummaryItem[],
    years: string[],
    currentYear: string,
    currentWeek: SummaryItem | null,
    weekData: Map<string, WeekDataItem[]>,
    dialogConfirmDeleteVisible: boolean,
    deleteId: number,
    dialogConfirmGenerateSaleVisible: boolean,
    generateSaleForId: number,
    dialogGenerateSaleResultVisible: boolean,
    generateSaleDate: string,
    showCompleted: boolean,
    showItemContent: number | null,
}

export class PlanningPage extends React.Component<PlanningPageProps, PlanningPageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            viewMode: 'simple',
            loading: true,
            bricks: [],
            activeTab: 'tab0',
            editMode: false,
            editId: -1,
            copyId: undefined,
            editDate: moment().format('DD.MM.YYYY'),
            plan: [],
            summary: [],
            years: [],
            currentYear: '',
            currentWeek: null,
            weekData: new Map<string, WeekDataItem[]>(),
            dialogConfirmDeleteVisible: false,
            deleteId: -1,
            dialogConfirmGenerateSaleVisible: false,
            generateSaleForId: -1,
            dialogGenerateSaleResultVisible: false,
            generateSaleDate: '',
            showCompleted: false,
            showItemContent: null,
        }

        this.loadYears = this.loadYears.bind(this);
        this.loadReport = this.loadReport.bind(this);
        this.editPlan = this.editPlan.bind(this);
        this.renderTabPanel = this.renderTabPanel.bind(this);
        this.renderYearsPanel = this.renderYearsPanel.bind(this);
        this.renderWeeksPanel = this.renderWeeksPanel.bind(this);
        this.renderDataPanel = this.renderDataPanel.bind(this);
        this.showWeek = this.showWeek.bind(this);
    }

    componentDidMount() {
        this.loadParams();
    }

    loadParams() {
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    const bricks: PropertyValue[] = apiCall.data.data.items?.values;
                    bricks[999] = {
                        id: 999,
                        value: 'ТУХЛА ВТОРО КАЧЕСТВО',
                        description: {
                            shortName: 'II качество'
                        }
                    };

                    bricks[997] = {
                        id: 997,
                        value: 'ПЕЛЕТИ 6мм',
                        description: {
                            shortName: 'ПЕЛЕТИ 6мм'
                        }
                    };

                    bricks[998] = {
                        id: 998,
                        value: 'ПЕЛЕТИ 8мм',
                        description: {
                            shortName: 'ПЕЛЕТИ 8мм'
                        }
                    };
                    this.setState({
                        bricks: bricks
                    });

                    this.loadYears();
                }
            }, PARAMETERS.BRICKS
        );
    }

    getStatusName(status: string) {
        switch (status) {
            case 'NEW':
                return 'НОВ';
            case 'PREPAIRED':
                return 'ПОДГОТВЕН';
            case 'COMPLETED':
                return 'ЗАВЪРШЕН';

        }
    }

    getPayTypeName(pay_type: string) {
        switch (pay_type) {
            case '0':
                return 'КБ'
            case '1':
                return 'БАНКА'
        }
        return null;
    }

    getStatusColor(status: string) {
        switch (status) {
            case 'NEW':
                return 'bg-light';
            case 'PREPAIRED':
                return 'bg-warning';
            case 'COMPLETED':
                return 'bg-success text-light';

        }
    }

    getStatusVariant(status: string) {
        switch (status) {
            case 'NEW':
                return 'light';
            case 'PREPAIRED':
                return 'warning';
            case 'COMPLETED':
                return 'success';

        }
    }

    toggleCompleted() {
        this.loadReport(this.state.currentYear, !this.state.showCompleted);
    }

    loadYears() {
        API.getPlanningYears(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    const currentYear = apiCall.data.data.years[0] ? apiCall.data.data.years[0] : '';
                    this.setState({years: apiCall.data.data.years, currentYear: currentYear});
                    this.loadReport(currentYear, this.state.showCompleted);
                }
            }
        );
    }

    loadReport(forYear: string = '', showCompleted: boolean) {
        this.setState({currentYear: forYear, editMode: false, dialogConfirmDeleteVisible: false, showCompleted: showCompleted});

        if (forYear !== '')
            API.getPlanning(
                (apiCall: API_CALL) => {
                    this.setState({loading: isLoading(apiCall)});

                    if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                    if (isSuccess(apiCall)) {
                        const summary: SummaryItem[] = [];
                        // apiCall.data.data.summary.filter((s: SummaryItem) => s.new + s.prepaired + (this.state.showCompleted ? s.completed : 0) > 0);

                        this.setState({plan: apiCall.data.data.plan, summary: summary});
                        if (summary.length > 0) {
                            if (this.state.currentWeek) this.showWeek(this.state.currentWeek);
                            else this.showWeek(summary[0]);
                        }
                    }
                },
                forYear, showCompleted
            );
    }

    showWeek(week: SummaryItem, updateState: boolean = true) {
        const weekData = new Map<string, WeekDataItem[]>();
        const fromDate = moment(week.fromDate, 'DD.MM.YYYY');
        const toDate = moment(week.toDate, 'DD.MM.YYYY');

        while (fromDate <= toDate) {
            weekData.set(fromDate.format('DD.MM.YYYY'), []);
            fromDate.add(1, "day");
        }

        this.state.plan.forEach(
            p => {
                if (weekData.has(p.plan_date)) {
                    const list = weekData.get(p.plan_date) || [];

                        const rd = JSON.parse(p.request_data || '');
                    list.push({...p, pay_type: rd.pay_type || '', logistic_pay_type: rd.logistic_pay_type || '', logistic_price: rd.logtic_price || ''});
                    // console.log(rd);
                    // console.log({...p, pay_type: rd.pay_type || '', logistic_pay_type: rd.logistic_pay_type || '', logistic_price: rd.logtic_price || '' });

                    weekData.set(p.plan_date, list);
                }
            }
        )

        if (updateState) {
            this.setState({currentWeek: week, weekData: weekData});
        }

        return {currentWeek: week, weekData: weekData}
    }

    editPlan(id: number) {
        this.setState({
            editMode: true,
            editId: id,
            copyId: undefined,
        })
    }

    addPlanForDate(date: string) {
        this.setState({
            editMode: true,
            editId: -1,
            copyId: undefined,
            editDate: date,
        })
    }

    public setStatus(id: number, status: string) {
        API.getPlanningDetails(
            (apiCall: API_CALL) => {

                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {

                    API.savePlanning(
                        (apiCall: API_CALL) => {

                            this.setState({loading: isLoading(apiCall)});

                            if (isSuccess(apiCall)) {
                                this.loadReport(this.state.currentYear, this.state.showCompleted);
                            }

                            if (isError(apiCall)) {
                                showErrorsInToast(apiCall, "Промяната е неуспешна.", translateError);
                            }
                        }, {...JSON.parse(apiCall.data.data.plan.request_data), id: id, status: status}
                    );
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Промяната е неуспешна.", translateError);
                }
            }, id
        );
    }

    generateSaleNote(id: number) {
        this.setState({dialogConfirmGenerateSaleVisible: true, generateSaleForId: id});
    }

    doGenerateSaleNote(btn: string) {
        if(btn === "Създай") {
            API.generateExpeditionNoteFromPlan(
                (apiCall: API_CALL) => {

                    this.setState({loading: isLoading(apiCall)});

                    if (isSuccess(apiCall)) {
                        // this.setStatus(this.state.generateSaleForId, 'COMPLETED');
                        this.rejectGenerateSaleNote();
                        this.setState({
                            dialogGenerateSaleResultVisible: true,
                            generateSaleDate: apiCall.data.data.date
                        })
                    }

                    if (isError(apiCall)) {
                        showErrorsInToast(apiCall, "Неуспешно генериране на експедиционна бележка поради следните грешки:", translateError);
                    }
                }, this.state.generateSaleForId
            );
        } else this.rejectGenerateSaleNote();
    }

    processChoice(btn: string) {
        this.setState({dialogGenerateSaleResultVisible: false});
        if(btn === "Към бележките") {
            localStorage.setItem('sales_date', this.state.generateSaleDate);
            window.location.href = "/sales?t=" + API.getToken();
        }
    }

    rejectGenerateSaleNote() {
        this.setState({dialogConfirmGenerateSaleVisible: false, generateSaleForId: -1});
    }

    copyPlan(plan: WeekDataItem) {
        this.setState({
            editMode: true,
            editId: -1,
            copyId: plan.id,
            editDate: plan.plan_date,
        })
    }

    deletePlan(id: number) {
        this.setState({dialogConfirmDeleteVisible: true, deleteId: id});
    }

    doDeletePlan() {
        API.deletePlanning(
            (apiCall: API_CALL) => {

                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    this.loadReport(this.state.currentYear, this.state.showCompleted);
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Изтриването е неуспешно.", translateError);
                }
            }, this.state.deleteId
        );
    }

    renderTabPanel() {
        return (
            <ul className="nav nav-pills">
                <li className="nav-item">
                    <a className={"nav-link " + (this.state.activeTab === 'tab0' ? "active" : "")}
                       onClick={() => this.setState({activeTab: "tab0"})}
                       href="#">Незавършени</a>
                </li>
                <li className="nav-item">
                    <a className={"nav-link " + (this.state.activeTab === 'tab1' ? "active" : "")}
                       onClick={() => this.setState({activeTab: "tab1"})}
                       href="#">Завършени</a>
                </li>
                <li className="nav-item">
                    <a className={"nav-link " + (this.state.activeTab === 'tab2' ? "active" : "")}
                       onClick={() => this.setState({activeTab: "tab2"})}
                       href="#">Всички</a>
                </li>
            </ul>
        );
    }

    renderYearsPanel() {
        return (
            <ul className="nav nav-tabs pl-3 w-100 mb-3">
                {
                    this.state.years.map(
                        year =>
                            <li className="nav-item">
                                <a className={"nav-link " + (this.state.currentYear == year ? "active" : "")}
                                   onClick={() => this.loadReport(year, this.state.showCompleted)}
                                   href="#">{year}</a>
                            </li>
                    )
                }
            </ul>
        );
    }

    renderWeeksPanel() {
        return (
            <>
                <table className="table bordered text-center">
                    <thead>
                    <tr className="bg-secondary text-light">
                        <th colSpan={6}>Седмица</th>
                    </tr>
                    <tr>
                        <th>#</th>
                        <th>От дата</th>
                        <th>До Дата</th>
                        <th colSpan={3}>Статус</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.summary.map(
                            s =>
                                <tr className={"text-center " + (s.week === this.state.currentWeek?.week ? "bg-info font-weight-bold text-light" : "")}
                                    key={s.week}
                                    onClick={() => this.showWeek(s)}
                                >
                                    <td>{s.week}</td>
                                    <td>{s.fromDate}</td>
                                    <td>{s.toDate}</td>
                                    <td className={"bg-light font-weight-bold text-dark"}>{s.new}</td>
                                    <td className={"bg-warning font-weight-bold text-dark"}>{s.prepaired}</td>
                                    <td className={"bg-success font-weight-bold text-dark"}>{s.completed}</td>
                                </tr>
                        )
                    }
                    </tbody>
                </table>
            </>
        )
    }

    renderDataRowItems(item: WeekDataItem) {
        if (item.request_data) {
            const rd = JSON.parse(item.request_data);
            return [0, 1, 2, 3, 4].map(
                idx => {
                    if (rd['property_id_' + idx]) {
                        const pId = rd['property_id_' + idx];
                        if (this.state.bricks[pId]) {
                            const name = this.state.bricks[pId].value;
                            const paletQty = rd['palet_qty_' + idx];
                            const qtyInPalet = rd['qty_in_palet_' + idx];
                            const total = rd['total_' + idx];

                            return <div key={idx}>{name} : {paletQty}п. х {qtyInPalet}бр.</div>
                        }
                    }
                }
            )
        }

        return <div>Няма данни</div>;
    }

    renderDataRow(item: WeekDataItem) {
        return (
            <>
                <td className={this.getStatusColor(item.status)}>
                    <FontAwesomeIcon icon={faBuilding} style={{width: '30px', textAlign: 'right'}}/> {item.client} <br/>
                    <FontAwesomeIcon icon={faMapMarker} style={{width: '30px', textAlign: 'right'}}/> {item.client_city} <br/>
                    <FontAwesomeIcon icon={faPhone} style={{width: '30px', textAlign: 'right'}}/> <a href={"tel:" + item.client_phone}>{item.client_phone}</a>
                </td>
                <td className={this.getStatusColor(item.status)}>
                    <FontAwesomeIcon icon={faBuilding} style={{width: '30px', textAlign: 'right'}}/> {item.currier} <br/>
                    <FontAwesomeIcon icon={faTruck} style={{width: '30px', textAlign: 'right'}}/> {item.license_plate} <br/>
                    <FontAwesomeIcon icon={faPhone} style={{width: '30px', textAlign: 'right'}}/> {item.driver}, <a href={"tel:" + item.driver_phone}>{item.driver_phone}</a>
                </td>
                <td className={this.getStatusColor(item.status)}>
                    {
                        this.renderDataRowItems(item)
                    }
                    {
                        this.getPayTypeName(item.pay_type) &&
                        <div>Начин на плащане: {this.getPayTypeName(item.pay_type)}</div>
                    }
                    {
                        (zeroToEmptyStringAsCurrency(item.logistic_price) !== '') &&
                        <div>
                            Транспорт цена и начин: {zeroToEmptyStringAsCurrency(item.logistic_price)} - {this.getPayTypeName(item.logistic_pay_type)}
                        </div>
                    }
                </td>
                <td className={this.getStatusColor(item.status)}>
                    {this.getStatusName(item.status)}
                </td>
                <td className={this.getStatusColor(item.status) + " text-nowrap"}>
                    <Dropdown>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className={"w-100 pb-1 mb-1"}>
                            <FontAwesomeIcon icon={faTrafficLight}/>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => this.setStatus(item.id, 'NEW')}>Нов</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.setStatus(item.id, 'PREPAIRED')}>Подготвен</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.setStatus(item.id, 'COMPLETED')}>Завършен</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <button type={"button"} className={"btn btn-secondary"}
                            onClick={() => {
                                this.editPlan(item.id)
                            }}>
                        <FontAwesomeIcon icon={"pen"}/>
                    </button>
                    &nbsp;
                    <button type={"button"} className={"btn btn-secondary"}
                            onClick={() => {
                                this.deletePlan(item.id)
                            }}>
                        <FontAwesomeIcon icon={"trash-alt"}/>
                    </button>


                </td>
            </>
        )
    }

    renderDataPanel() {
        const rows: any[] = [];
        this.state.weekData.forEach(
            (value, key) => {
                if (value.length > 0) {
                    rows.push(
                        <tr>
                            <td rowSpan={value.length}>{key}</td>
                            {this.renderDataRow(value[0])}
                        </tr>
                    );

                    for (let i = 1; i < value.length; i++) {
                        rows.push(<tr>{this.renderDataRow(value[i])}</tr>)
                    }
                }
            }
        )


        return (
            <>
                <table className="table bordered t pl-2 " style={{opacity: (this.state.loading ? 0.3 : 1)}}>
                    <thead>
                    <tr className="bg-secondary text-light text-center">
                        <th colSpan={6}>Планирани клиенти за избраната седмица</th>
                    </tr>
                    <tr>
                        <th>Дата</th>
                        <th>Клиент</th>
                        <th>Транспорт</th>
                        <th>Продукти</th>
                        <th>Статус</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </table>
            </>
        )
    }

    renderSimplePanelDay(day: string, name: string, weekData: WeekDataItem[]) {
        return <>
            <table className="table table-sm table-borderless table-no-padding">
                <thead>
                <tr>
                    <th className={"bg-secondary text-light font-weight-bold font-size-small text-center"}>
                        {day + " (" + name + ")"}
                        <span className={"float-right"}>
                            <button className={"btn btn-secondary btn-sm"} onClick={()=>this.addPlanForDate(day)}><FontAwesomeIcon icon={faPlus} /></button>
                        </span>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    weekData.length > 0 ?
                        weekData.map(
                            item =>
                                <tr key={item.id}>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                id={'client_' + item.id}
                                                variant={this.getStatusVariant(item.status)}
                                                className={"w-100 text-start mb-1"}
                                                size={"sm"}
                                                onMouseLeave={() => {
                                                    this.setState({showItemContent: null})}
                                                }
                                            >

                                                {item.client}&nbsp;&nbsp;&nbsp;
                                                <FontAwesomeIcon icon={faEye}
                                                                 onMouseEnter={() => {
                                                                     this.setState({showItemContent: item.id})
                                                                 }} />
                                                &nbsp;&nbsp;&nbsp;
                                                {
                                                    this.state.showItemContent === item.id &&
                                                    <div style={{fontSize: "0.75rem"}} className={"bg-light text-dark p-1"}>
                                                        <div className={"border-bottom"}>
                                                            {
                                                                item.client_city &&
                                                                <span className={"m-2"}><FontAwesomeIcon icon={faBuilding}/> {item.client_city}</span>
                                                            }
                                                            {
                                                                item.client_phone &&
                                                                <span className={"m-2"}><FontAwesomeIcon icon={faPhone}/> {item.client_phone}</span>
                                                            }
                                                            {
                                                                item.pay_type &&
                                                                <span className={"m-2"}><FontAwesomeIcon icon={faMoneyBill}/> {this.getPayTypeName(item.pay_type)}</span>
                                                            }
                                                        </div>

                                                        <div className={"border-bottom"}>
                                                            {
                                                                item.currier &&
                                                                <span className={"m-2"}>{item.currier}</span>
                                                            }
                                                            {
                                                                item.license_plate &&
                                                                <span className={"m-2"}>
                                                                    <FontAwesomeIcon icon={faTruck}/> {item.license_plate}</span>
                                                            }
                                                            {
                                                                item.logistic_pay_type &&
                                                                <span className={"m-2"}>
                                                                    <FontAwesomeIcon icon={faMoneyBill}/> {this.getPayTypeName(item.logistic_pay_type)}</span>
                                                            }
                                                            {
                                                                item.logistic_price && (item.logistic_price.length > 0) &&
                                                                <>{zeroToEmptyStringAsCurrency(item.logistic_price)} лв.</>
                                                            }
                                                        </div>

                                                        {this.renderDataRowItems(item)}

                                                    </div>
                                                }
                                            </Dropdown.Toggle>


                                            <Dropdown.Menu style={{zIndex: 9999}}>
                                                <Dropdown.Item onClick={() => this.editPlan(item.id)}><FontAwesomeIcon icon={"pen"}/> Редакция</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.generateSaleNote(item.id)}><FontAwesomeIcon icon={"plus"}/> Създай експ.бележка</Dropdown.Item>
                                                <Dropdown.Divider></Dropdown.Divider>
                                                <Dropdown.Item onClick={() => this.setStatus(item.id, 'NEW')}>Нов</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.setStatus(item.id, 'PREPAIRED')}>Подготвен</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.setStatus(item.id, 'COMPLETED')}>Завършен</Dropdown.Item>
                                                <Dropdown.Divider></Dropdown.Divider>
                                                <Dropdown.Item onClick={() => this.copyPlan(item)}><FontAwesomeIcon icon={faCopy}/> Копирай</Dropdown.Item>
                                                <Dropdown.Item onClick={() => this.deletePlan(item.id)}><FontAwesomeIcon icon={"trash-alt"}/> Изтриване</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                        )
                        :
                        <tr>
                            <td>Няма клиенти</td>
                        </tr>
                }
                </tbody>
            </table>
        </>
    }

    renderSimplePanelWeekDays(week: any) {
        const html: any[] = [];

        const currentDate = moment(week.currentWeek.fromDate, 'DD.MM.YYYY');
        const lastDate = moment(week.currentWeek.toDate, 'DD.MM.YYYY');
        // lastDate.subtract(1, 'day');

        const days = [];
        const dayNames = ['Пон', 'Вто', 'Сря', 'Чет', 'Пет', 'Съб'];
        while (currentDate < lastDate) {
            days.push(currentDate.format('DD.MM.YYYY'))
            currentDate.add(1, 'day');
        }

        html.push(
            <tr>
                {
                    days.map(
                        (d, idx) =>
                            <td key={d}>{this.renderSimplePanelDay(d, dayNames[idx], week.weekData.get(d) || [])}</td>
                    )
                }
            </tr>
        );

        return html;
    }

    renderSimplePanelWeek(today: Moment) {
        let startDate = moment(today).startOf('isoWeek').format('DD.MM.YYYY');
        let endDate = moment(today).endOf('isoWeek').format('DD.MM.YYYY');
        let weekNumber = moment(today).week();

        const week = this.showWeek({
            fromDate: startDate,
            toDate: endDate,
            week: weekNumber,
            new: 0,
            prepaired: 0,
            completed: 0
        } as SummaryItem, false);

        return this.renderSimplePanelWeekDays(week);
    }

    renderSimplePanel() {
        let html: any[] = [];

        let today = moment();
        today.subtract(7, 'days');
        html = html.concat(this.renderSimplePanelWeek(today));
        today.add(7, 'days');
        html = html.concat(this.renderSimplePanelWeek(today));
        today.add(7, 'days');
        html = html.concat(this.renderSimplePanelWeek(today));
        today.add(7, 'days');
        html = html.concat(this.renderSimplePanelWeek(today));

        return html;
    }

    render() {

        return (
            <div className="container" style={{maxWidth: "100%"}}>

                <div className="card shadow">

                    <div className="card-header text-center row">
                        <div className="col-2 text-start">
                            {
                                this.state.loading ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                        <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                            }

                        </div>
                        <h2 className="col">Планиране</h2>
                        <div className="col-auto text-end">
                            {
                                !this.state.loading &&
                                <>
                                    <button type={"button"} className={"btn btn-primary"}
                                            onClick={() => this.editPlan(-1)}>Добави нов
                                    </button>
                                    &nbsp;
                                    <button type={"button"} className={"btn btn-secondary"}
                                            onClick={() => this.loadReport(this.state.currentYear, this.state.showCompleted)}>Обнови списъка
                                    </button>
                                    &nbsp;
                                    <button type={"button"} className={"btn btn-secondary"}
                                            onClick={() => this.toggleCompleted()}>{this.state.showCompleted ? 'Скрий' : 'Покажи'} завършените
                                    </button>

                                </>
                            }
                        </div>
                    </div>

                    <div className="card-body">
                        {
                            this.state.viewMode !== 'simple' &&
                            <>
                                <div className="row">
                                    {
                                        this.renderYearsPanel()
                                    }
                                </div>
                                <div className="row">
                                    {/*<div className="col-auto">{this.renderWeeksPanel()}</div>*/}
                                    <div className="col">{this.renderDataPanel()}</div>
                                </div>
                            </>
                        }
                        {
                            this.state.viewMode === 'simple' &&
                            <>
                                <table className="table table-bordered">
                                    <tbody>
                                    {this.renderSimplePanel()}
                                    </tbody>
                                </table>
                            </>
                        }
                    </div>
                </div>

                {
                    this.state.editMode &&
                    <PlanningDetailsModalComponent
                        id={this.state.editId}
                        copyId={this.state.copyId}
                        date={this.state.editDate}
                        onAfterSave={() => this.loadReport(this.state.currentYear, this.state.showCompleted)}
                        onCancel={() => {
                            this.setState({editMode: false})
                        }}
                        brick_types={this.state.bricks}
                    />
                }


                {
                    this.state.dialogConfirmDeleteVisible &&
                    <ConfirmDeleteModal
                        working={this.state.loading}
                        onConfirm={() => this.doDeletePlan()}
                        onReject={() => this.setState({dialogConfirmDeleteVisible: false})}
                    />
                }


                {
                    this.state.dialogConfirmGenerateSaleVisible &&
                    <ConfirmModal
                        title={"Потвърдете"}
                        message={"Моля, потвърдете създаването на нова експедиционна бележка..."}
                        buttons={['Създай', 'Откажи']}
                        primaryBtn={'Откажи'}
                        onButtonClick={(btn) => this.doGenerateSaleNote(btn)}
                        onClose={() => this.rejectGenerateSaleNote()}
                    />
                }


                {
                    this.state.dialogGenerateSaleResultVisible &&
                    <ConfirmModal
                        title={"Потвърдете"}
                        message={"Експедиционната бележка е създадена успешно!"}
                        buttons={['Към бележките', 'Затвори']}
                        primaryBtn={'Затвори'}
                        onButtonClick={(btn) => this.processChoice(btn)}
                        onClose={() => this.rejectGenerateSaleNote()}
                    />
                }
            </div>


        );
    }

}
