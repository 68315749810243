import {API} from "../../api";
import React, {FC, useEffect, useState} from "react";
import {API_CALL, SHORT_MONTHS_NAMES} from "../../types";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../common";
import {BlockTitleComponent} from "../../components/common/BlockTitleComponent";
import {Col, Row, Spinner, Tab, Tabs, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import BudgetsPlanTable from "./BudgetsPlanTable";
import moment from "moment";
import BudgetsRefTable from "./BudgetsRefTable";
import BudgetsRefGraph1 from "./BudgetsRefGraph1";
import BudgetsRefGraph2 from "./BudgetsRefGraph2";

const BudgetsReference = () => {
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const [month, setMonth] = useState<string>(SHORT_MONTHS_NAMES[new Date().getMonth()]);
    const [selectedMonths, setSelectedMonths] = useState<string[]>([SHORT_MONTHS_NAMES[new Date().getMonth()]]);
    const [loadingExpenses, setLoadingExpenses] = useState<boolean>(false);
    const [loadingBudgets, setLoadingBudgets] = useState<boolean>(false);
    const [bankExpenses, setBankExpenses] = useState<any[]>([]);
    const [cashExpenses, setCashExpenses] = useState<any[]>([]);
    const [bankBudgets, setBankBudgets] = useState<any[]>([]);
    const [cashBudgets, setCashBudgets] = useState<any[]>([]);
    const [bankExpensesTypes, setBankExpensesTypes] = useState<any[]>([]);
    const [cashExpensesTypes, setCashExpensesTypes] = useState<any[]>([]);

    let yearsList = [];
    for (let i = 0; i < 4; i++) {
        const year = moment().year() - i;
        if(year > 2023) {
            yearsList.push((moment().year() - i));
        }
    }

    const monthIdx = SHORT_MONTHS_NAMES.indexOf(month);
    const monthAsStr = monthIdx < 9 ? "0" + (monthIdx+1) : (monthIdx + 1);

    const monthIdxs = selectedMonths.map(m => SHORT_MONTHS_NAMES.indexOf(m));
    const monthAsStrs = monthIdxs.map(m => m < 9 ? "0" + (m+1) : (m + 1));

    const loadData = () => {
        API.getBankExpenses(
            (apiCall: API_CALL) => {
                setLoadingExpenses(isLoading(apiCall));

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    console.log(apiCall.data.data);
                    setBankExpenses(apiCall.data.data.expenses);
                    setCashExpenses(apiCall.data.data.cash);
                }
            },
            year, 12, 12, undefined, undefined
        );

        API.getBudgets(
            (apiCall: API_CALL) => {
                setLoadingBudgets(isLoading(apiCall));

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    console.log(apiCall.data.data);
                    setBankBudgets(apiCall.data.data.budgetsBank);
                    setCashBudgets(apiCall.data.data.budgetsCash);
                    setBankExpensesTypes(apiCall.data.data.expensesBank);
                    setCashExpensesTypes(apiCall.data.data.expensesCash);
                }
            },
            year
        );
    }

    useEffect(() => {
        loadData();
    }, [year]);

    function getBankValue(id: number) {
        const found = bankExpenses[id];
        if(found && found[year] && found[year][monthAsStr]) {
            return (found[year][monthAsStr]);
        }
        return 0;
    }

    function getCashValue(id: number) {
        const found = cashExpenses[id];
        if(found && found[year] && found[year][monthAsStr]) {
            return (found[year][monthAsStr]);
        }
        return 0;
    }

    function getTtitles(bankExpensesTypes: any[]) {
        if(bankExpensesTypes.length > 0) {
            return bankExpensesTypes.map(e => e.name);
        }
        return [];
    }

    return (
        <div>
            <BlockTitleComponent title={""}/>
            <Row>
                <Col xs={"auto"}>
                    <Tabs activeKey={year} onSelect={(k) => setYear(parseInt(k || moment().year().toString()))}>
                        {yearsList.map(y => <Tab key={y} eventKey={y} title={y}/>)}
                    </Tabs>
                </Col>
                <Col/>
                <Col xs={"auto"}>
                    <ToggleButtonGroup type="checkbox" value={selectedMonths}
                                       onChange={
                                             (v) => {
                                                  if(v) {
                                                    setSelectedMonths(v);
                                                  }
                                             }
                                       }
                    >
                        {
                            SHORT_MONTHS_NAMES.map(
                                m => <ToggleButton
                                    key={m}
                                    id={`toggle-check-${m}`}
                                    value={m}>{m}</ToggleButton>
                            )
                        }
                    </ToggleButtonGroup>

                    {/*<Tabs activeKey={month}*/}
                    {/*      variant={"pills"}*/}

                    {/*      onSelect={(k) => setMonth(k ?? "")}>*/}
                    {/*    {*/}
                    {/*        SHORT_MONTHS_NAMES.map(*/}
                    {/*            m => <Tab key={m} eventKey={m} title={m}/>*/}
                    {/*        )*/}
                    {/*    }*/}
                    {/*</Tabs>*/}
                </Col>
            </Row>

            {
                loadingExpenses || loadingBudgets ?
                    <Row>
                        <Col className={"text-center"}>
                            <br/>
                            <Spinner animation={"border"}></Spinner> Зареждам данните ...
                        </Col>
                    </Row>
                    :
                    <>
                        <BlockTitleComponent title={"БАНКА"}/>
                        <Row>
                            <Col>
                                <BudgetsRefTable
                                    budgets={bankBudgets}
                                    expenses={bankExpenses}
                                    expensesTypes={bankExpensesTypes}
                                    year={year}
                                    months={monthIdxs}
                                />
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <BudgetsRefGraph1
                                            values={
                                                new Map(
                                                    bankExpensesTypes
                                                        .filter(e => getBankValue(e.id) > 0)
                                                        .map(e => [e.name, getBankValue(e.id)])
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <BudgetsRefGraph2
                                            budgets={bankBudgets}
                                            expenses={bankExpenses}
                                            types={bankExpensesTypes}
                                            year={year}
                                            month={monthIdx}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <BlockTitleComponent title={"КАСА"}/>
                        <Row>
                            <Col>
                                <BudgetsRefTable
                                    budgets={cashBudgets}
                                    expenses={cashExpenses}
                                    expensesTypes={cashExpensesTypes}
                                    year={year}
                                    months={monthIdxs}
                                />
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <BudgetsRefGraph1
                                            values={
                                                new Map(
                                                    cashExpensesTypes
                                                        .filter(e => getCashValue(e.id) > 0)
                                                        .map(e => [e.name, getCashValue(e.id)])
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <BudgetsRefGraph2
                                            budgets={cashBudgets}
                                            expenses={cashExpenses}
                                            types={cashExpensesTypes}
                                            year={year}
                                            month={monthIdx}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
            }
        </div>
    )
};

export default BudgetsReference;
