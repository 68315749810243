import {FC} from "react";
import {SHORT_MONTHS_NAMES} from "../../types";
import {zeroToEmptyStringAsCurrencyWithSpaces} from "../../common";

interface Props {
    data: any;
    selectedMonths: string[];
    showDetails?: boolean;
}

const IOReportTableDetails: FC<Props> = ({data, selectedMonths, showDetails}) => {
    const items: string[] = [];
    selectedMonths.forEach((month: string) => {
        const monthIndex = SHORT_MONTHS_NAMES.indexOf(month);
        if (data[monthIndex]) {
            const keys = Object.keys(data[monthIndex]);
            keys.forEach((key: string) => {
                if (!items.includes(key)) {
                    items.push(key);
                }
            });
        }
    });
    const sortedMonths = selectedMonths.sort((a, b) => SHORT_MONTHS_NAMES.indexOf(a) - SHORT_MONTHS_NAMES.indexOf(b));

    return (<>
            {
                items.length > 0 && showDetails ?
                    items.map((item: string) => {
                        let total = 0;
                        return (
                            <tr key={item} style={{ fontSize: "14px"}}>
                                <td className={"ps-5 bg-secondary-light"}>{item}</td>
                                {
                                    sortedMonths.map((month: string) => {
                                        const monthIndex = SHORT_MONTHS_NAMES.indexOf(month);
                                        if (data[monthIndex]) {
                                            total += data[monthIndex][item];
                                            return (
                                                <td key={month} className={"text-end bg-secondary-light"}>
                                                    {zeroToEmptyStringAsCurrencyWithSpaces(data[monthIndex][item])}
                                                </td>
                                            )
                                        }
                                        return <td key={month} className={"text-end bg-secondary-light"}></td>;
                                    })
                                }
                                <td className={"text-end bg-secondary-light fw-bold"}>
                                    {zeroToEmptyStringAsCurrencyWithSpaces(total.toString())}
                                </td>
                            </tr>
                        )
                    }) : null
            }
        </>
    )
}

export default IOReportTableDetails;
