import React, {useEffect, useState} from "react";
import useInterval from "../../hooks/useInterval";
import {API} from "../../api";
import {API_CALL} from "../../types";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../common";
import {Report} from "../bonuses/BonusesControlModal";
import {Col, Row, Spinner} from "react-bootstrap";
import {ProductionCard} from "../homepage/ProductionCard";
import moment from "moment";
import {STAY_DURATION, titleStyle} from "../../pages/StatusPage";

const API_PATH_CURRENT = 'admin/bonuses/current';
const _statusPeriodFrom = moment().subtract(1, 'day').format('DD.MM.YYYY');
const _statusPeriodTo = moment().subtract(1, 'day').format('DD.MM.YYYY');
const _statusPercentPeriod = moment().subtract(1, 'day').format('MM/YYYY');


export default function ProductionPerformancePart(props: {onDone: () => void}) {
    const [loading, setLoading] = useState(false);
    useInterval(() => props.onDone(), STAY_DURATION);
    const [percent, setPercent] = useState("");

    const loadData = () => {
        setLoading(true);
        API.doGetRequest(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    let total = 0;
                    if (apiCall.data.data.reports) {
                        apiCall.data.data.reports.map(
                            (r: any) => {
                                return {
                                    ...r
                                } as Report
                            }
                        ).forEach(
                            (r: Report) => total += r.report.current.bonus * 1
                        );
                        setPercent(total > 0 ? (Math.round(total / apiCall.data.data.reports.length)) + "%" : "0%");
                    } else setPercent("0%");
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Възникна грешка', translateError)
                }
            }, API_PATH_CURRENT + '/' + _statusPercentPeriod, {}
        );
    }

    useEffect(() => loadData(), []);


    return <Row className={"align-content-center justify-content-center border"} style={{ height: "90vh"}}>
        <Col xs={9} style={{height: "65px", marginBottom: "10px"}} className={"border-bottom border-light text-center"}>
            <span style={titleStyle}>ПРОИЗВОДСТВО ПО ОТДЕЛИ</span>
        </Col>
        <Col xs={3} style={{ textAlign: "center", height: "65px", marginBottom: "10px"}} className={"border-bottom border-light"}>
            <span style={titleStyle}>{ _statusPeriodFrom }</span>
        </Col>
        <Col className={"text-center "}>
            <Row>
                <Col xs={"1"}>
                    <div className={"text-light d-inline-block"}>
                        <span style={{ fontSize: "32px", writingMode: "vertical-rl", rotate: "180deg", height: 430}}>ПЪРВА СМЯНА</span>
                    </div>
                </Col>
                <Col xs={"11"}>
                    <ProductionCard
                        title={null}
                        periodFrom={_statusPeriodFrom} periodTo={_statusPeriodTo} shift={0} labelStyles={'font-weight: bold; font-size: 14px;'}/>
                </Col>
            </Row>
            <Row>
                <Col xs={"1"}>
                    <div className={"text-light d-inline-block"}>
                        <span style={{ fontSize: "32px", writingMode: "vertical-rl", rotate: "180deg", height: 430}}>ВТОРА СМЯНА</span>
                    </div>
                </Col>
                <Col xs={"11"}>
                    <ProductionCard
                        title={null}
                        periodFrom={_statusPeriodFrom} periodTo={_statusPeriodTo} shift={1} labelStyles={'font-weight: bold; font-size: 14px;'}/>
                </Col>
            </Row>
        </Col>
        {/*<Col xs={3} className={"text-light text-center"} style={{ paddingTop: "10%"}} >*/}
        {/*    <div style={titleStyle}>*/}
        {/*        Обща<br/>ефективност:<br/>&nbsp;*/}
        {/*    </div>*/}
        {/*    <hr/>*/}
        {/*    <div style={{fontSize: "144px", fontWeight: "bold"}}>*/}
        {/*        { loading ? <Spinner animation={"border"} /> : percent }*/}
        {/*    </div>*/}
        {/*</Col>*/}
        <Col xs={1}/>
    </Row>;
}
