import {FC, useEffect, useState} from "react";
import {stringToCurrency, zeroToEmptyStringAsCurrency} from "../../common";
import {Form, Table} from "react-bootstrap";

interface DailyFullReportHristoProps {
    hristoBankOut: number;
    hristoBankIn: number;
    hristoCashOut: number;
    hristoCashIn: number;
    onChange: (bankOut: number, bankIn: number, cashOut: number, cashIn: number) => void;
}

const DailyFullReportHristo: FC<DailyFullReportHristoProps> = ({
                                             hristoBankOut,
                                             hristoBankIn,
                                             hristoCashOut,
                                             hristoCashIn,
                                             onChange
                                         }: DailyFullReportHristoProps) => {
    const [bankOut, setBankOut] = useState<string>(zeroToEmptyStringAsCurrency(hristoBankOut.toString()));
    const [bankIn, setBankIn] = useState<string>(zeroToEmptyStringAsCurrency(hristoBankIn.toString()));
    const [cashOut, setCashOut] = useState<string>(zeroToEmptyStringAsCurrency(hristoCashOut.toString()));
    const [cashIn, setCashIn] = useState<string>(zeroToEmptyStringAsCurrency(hristoCashIn.toString()));

    useEffect(() => {
        setBankOut(zeroToEmptyStringAsCurrency(hristoBankOut.toString()));
        setBankIn(zeroToEmptyStringAsCurrency(hristoBankIn.toString()));
        setCashOut(zeroToEmptyStringAsCurrency(hristoCashOut.toString()));
        setCashIn(zeroToEmptyStringAsCurrency(hristoCashIn.toString()));
    }, [hristoBankIn, hristoBankOut, hristoCashOut, hristoCashIn]);

    const updateParent = () => {
        onChange(
            stringToCurrency(bankOut) ?? 0,
            stringToCurrency(bankIn) ?? 0,
            stringToCurrency(cashOut) ?? 0,
            stringToCurrency(cashIn) ?? 0
        );
    };

    return (
        <table className="table table-sm table-bordered table-no-padding w-100" style={{fontSize: "0.75rem"}}>
            <thead className={"text-center"}>
            <tr>
                <th colSpan={2}>БАНКА</th>
                <th colSpan={2}>КАСА</th>
            </tr>
            <tr>
                <th>Банкови тегления</th>
                <th>Банкови внасяния</th>
                <th>Отчетени пари</th>
                <th>Върнати пари</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>
                    <Form.Control
                        size={"sm"}
                        className={"text-end border-0 p-0 m-0"}
                        type="number"
                        value={bankOut}
                        onChange={(e) => {
                            setBankOut(e.target.value);
                        }}
                        onBlur={updateParent}
                    />
                </td>
                <td>
                    <Form.Control
                        size={"sm"}
                        className={"text-end border-0 p-0 m-0"}
                        type="number"
                        value={bankIn}
                        onChange={(e) => {
                            setBankIn(e.target.value);
                        }}
                        onBlur={updateParent}
                    />
                </td>
                <td>
                    <Form.Control
                        size={"sm"}
                        className={"text-end border-0 p-0 m-0"}
                        type="number"
                        value={cashOut}
                        onChange={(e) => {
                            setCashOut(e.target.value);
                        }}
                        onBlur={updateParent}
                    />
                </td>
                <td>
                    <Form.Control
                        size={"sm"}
                        className={"text-end border-0 p-0 m-0"}
                        type="number"
                        value={cashIn}
                        onChange={(e) => {
                            setCashIn(e.target.value);
                        }}
                        onBlur={updateParent}
                    />
                </td>
            </tr>
            </tbody>
        </table>
    );
}

export default DailyFullReportHristo;
