import React, {useEffect, useState} from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {API_CALL, PropertyValue, REPAIR_TYPE, Sale} from "../../types";
import {getSalesTypeName, isLoading, isSuccess, numberWithCommas} from "../../common";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {API} from "../../api";
import moment, {Moment} from "moment";
import {Card, Col, Row} from "react-bootstrap";


export function SalesCard() {
    const [loading, setLoading] = useState(true);
    const [sales, setSales] = useState<Array<Sale>>([]);
    const [total, setTotal] = useState(0);

    const forDate = moment().format('DD.MM.YYYY');

    const loadData = function () {
        if(!API.user?.is_admin) return null;

        API.getExpeditionNote(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    setSales(apiCall.data.data.sales);
                }
            }, {sales_date: forDate}, null,0, 9999, false
        )
    }

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        let _total = 0;
        sales.forEach(
            s => _total += parseFloat((s.total_price || '0').toString())
        )
        setTotal(_total);

    }, [sales])

    if(!API.user?.is_admin) return null;

    return (
        <Card>
            <Card.Header className={"home-card-header main-modules-header main-modules-header-primary-text"}>
                <Row className={"fw-bold"}>
                    <Col><h2>Продажби</h2></Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <div className="row" >
                    <div className="col">
                        {
                            loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                        {
                            !loading &&
                            <table className={"table small"}>
                                <thead>
                                    <tr>
                                        <th className={"th-dark"}>No:</th>
                                        <th className={"th-light"}>Клиент</th>
                                        <th className={"th-dark text-center"}>Тип</th>
                                        <th className={"th-light"}>Фактура</th>
                                        <th className={"th-dark text-end"}>Стойност</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    sales.length === 0 &&
                                    <tr><th colSpan={5}>Няма регистрирани продажби</th> </tr>
                                }
                                {
                                    sales.map(
                                        s => <tr key={s.id}>
                                            <td>{ s.sales_number }</td>
                                            <td>{ s.client }</td>
                                            <td className={"text-center"}>{ getSalesTypeName(s.sale_type)}</td>
                                            <td>{ s.inv_number }</td>
                                            <td className={"text-end"}>{ numberWithCommas(s.total_price) } лв</td>
                                        </tr>
                                    )
                                }
                                {
                                    total > 0 &&
                                    <tr className={"tr-total"}>
                                        <th colSpan={4}>Общо за деня</th>
                                        <th className={"text-end"}>{numberWithCommas(total.toFixed(2))} лв</th>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}
